import React, { Fragment, useState, Component, useEffect } from "react";
import { Link } from "react-router-dom";
import Header from "./../../Layout/Header1";
import Footer from "./../../Layout/Footer1";

// AboutNode Array Images
import NominalReward from "../../../images/blockchain_logos/NominalReward.svg";
import StakingComission from "../../../images/blockchain_logos/StakingComission.svg";
import ProtocolCategory from "../../../images/blockchain_logos/ProtocolCategory.svg";
import StakingType from "../../../images/blockchain_logos/StakingType.svg";
import Governance from "../../../images/blockchain_logos/Governance.svg";

import NodePageBanner from "../../../images/blockchain_logos/NodePageBanner.png";
// import Ethereum_logo from "../../../images/blockchain_logos/ethereum.png";
import Ethereum_logo from "../../../images/blockchain_logos/ethLogo.svg";
// import CoindeltaLogo from '../../../images/blockchain_logos/CoindeltaLogo.svg';
import StakingLogo from "../../../images/blockchain_logos/StakingLogo.svg";
import AddressCopied from "../../../images/blockchain_logos/AddressCopied.png";
import AvaxStaking from "../../../images/blockchain_logos/ethLogo.svg";
import NodeDetails from "../../../images/blockchain_logos/Key.svg";
import StakeAvax from "../../../images/blockchain_logos/StakeAvax1.svg";
// import AccordionBlog from './../../Element/AccordionBlog';
// import VideoPopup from './../../Element/VideoPopup';
// import pic10 from './../../../images/about/pic10.jpg';

import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Switch from "@mui/material/Switch";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import copy from "copy-to-clipboard";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@material-ui/core/Slide";
import Paper from "@mui/material/Paper";
import Avalanche from "./Avalanche";
import StakingDetails from "../../Component/StakingDetails";
const label = { inputProps: { "aria-label": "Switch demo" } };

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  // console.log(onClose);

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {/* {onClose ? ( */}
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      {/* ) : null} */}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const CustomizedDialogs = (props) => {
  const { isDialogOpen } = props;
  console.log("CustomizedDialogs value is..." + isDialogOpen);
  const [open, setOpen] = React.useState(isDialogOpen);

  React.useEffect(() => {
    const { isDialogOpen } = props;
    setOpen(isDialogOpen);
    console.log("CustomizedDialogs value is..." + open);
  }, [props]);

  const handleClose = () => {
    setOpen(false);
    props.onCloseModel(false);
  };

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
          style={{ textAlign: "center" }}
        >
          <b>Address copied to clipboard</b>
        </BootstrapDialogTitle>
        <DialogContent dividers style={{ textAlign: "center" }}>
          <Typography gutterBottom>Welcome, to the future of money</Typography>
          <Typography gutterBottom>
            <img src={AddressCopied} width={"40%"} />
          </Typography>
          <Typography gutterBottom>
            <p>You can now paste the address in your staking wallet</p>
            <p>Need help? Check out our tutorials</p>
          </Typography>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
};

class ShortTabs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "1",
      amount: 0,
      currencyType: "ETH",
      dailyEarningsDollar: 0.0,
      dailyEarningsDot: 0.0,
      monthlyEarningsDollar: 0.0,
      monthlyEarningsDot: 0.0,
      yearlyEarningsDollar: 0.0,
      yearlyEarningsDot: 0.0,
      compound: false,
      totalAmount: 0.0,
      configCompoundTrue: {
        dailyDoller: 0.25911,
        dailyDot: 0.00012,
        monthlyDoller: 7.88115,
        monthlyDot: 0.00367,
        yearlyDoller: 94.57384,
        yearlyDot: 0.04403,
      },
      configCompoundfalse: {
        dailyDoller: 0.23867,
        dailyDot: 0.00012,
        monthlyDoller: 7.69442,
        monthlyDot: 0.00358,
        yearlyDoller: 92.33309,
        yearlyDot: 0.04299,
      },
      dotPrice: 2147.78,
      configUsdCompTrue: {
        dailyDoller: 0.00012,
        dailyDot: 0.00000006,
        monthlyDoller: 0.0036694,
        monthlyDot: 0.0000018,
        yearlyDoller: 0.0440333,
        yearlyDot: 0.0000217,
      },
      configUsdCompFalse: {
        dailyDoller: 0.0001177,
        dailyDot: 0.000000058,
        monthlyDoller: 0.0035825,
        monthlyDot: 0.000001768,
        yearlyDoller: 0.04299,
        yearlyDot: 0.000021215,
      },
      modalOpen: false,
      AboutNode: [
        { NodeImg: NominalReward, titles: "4.78", descriptions: "%", content: "Nominal Reward APY" },
        { NodeImg: StakingComission, titles: "10.0", descriptions: "%", content: "Staking Comission" },
        {  NodeImg: ProtocolCategory,titles: "Layer 1", content: "Protocol Category" },
        { NodeImg: StakingType, titles: "NPoS", content: "Staking Type" },
        { NodeImg: Governance, titles: "off-Chain", content: "Governance" },
      ],
      Tutorial: [
        { Tuto_title: "Coindelta Staking Tutorial" },
        {
          Tuto_image: AvaxStaking,
          Tuto_title: "How to Stake ETH?",
          Tuto_description: "via Coindelta",
          Tuto_StakeAvax: "/PreferredMethod",
        },
        {
          Tuto_image: NodeDetails,
          Tuto_title: "ValidatorID-0xb56b86813e92e7ebf296249dc516...",
          Tuto_description: "Validator",
          Tuto_CopyId:
            "0xb56b86813e92e7ebf296249dc5162370d0609b847184f0da8ce09c8a2f9de5f2a166716be6ae11e30b60a0a81a0d4d71",
          // Tuto_Details: "/node-info",
        },
        {
          Tuto_image: StakeAvax,
          Tuto_title: "Stake ETH via Coindelta",
          Tuto_description: "services via Coindelta",
          Tuto_StakeNow: "/PreferredMethod",
        },
      ],
      NodeInfo: [
        {
          Node_Title: "Validations",NodeID:"TX ID",StartDate:"START DATE",EndDate:"END DATE",Duration:"DURATION",ValidatorStake:"VALIDATOR STAKE"
        }
      ],
    };
  }

  handleAmountChange(amountValue, currencyType, compound) {
    // const amountValue = event.target.value;
    console.log("event from handleAmountChange..." + amountValue);

    if (this.state.currencyType == "ETH") {
      this.setState({
        currencyType: currencyType,
        compound: compound,
        amount: amountValue,
        totalAmount: amountValue * this.state.dotPrice,
      });
      if (this.state.compound) {
        const configCompound = this.state.configCompoundTrue;

        this.setState({
          dailyEarningsDollar: amountValue * configCompound.dailyDoller,
          dailyEarningsDot: amountValue * configCompound.dailyDot,
          monthlyEarningsDollar: amountValue * configCompound.monthlyDoller,
          monthlyEarningsDot: amountValue * configCompound.monthlyDot,
          yearlyEarningsDollar: amountValue * configCompound.yearlyDoller,
          yearlyEarningsDot: amountValue * configCompound.yearlyDot,
        });
      } else {
        const configCompound = this.state.configCompoundfalse;

        this.setState({
          dailyEarningsDollar: amountValue * configCompound.dailyDoller,
          dailyEarningsDot: amountValue * configCompound.dailyDot,
          monthlyEarningsDollar: amountValue * configCompound.monthlyDoller,
          monthlyEarningsDot: amountValue * configCompound.monthlyDot,
          yearlyEarningsDollar: amountValue * configCompound.yearlyDoller,
          yearlyEarningsDot: amountValue * configCompound.yearlyDot,
        });
      }
    } else if (this.state.currencyType == "USD") {
      this.setState({
        currencyType: currencyType,
        compound: compound,
        amount: amountValue,
        totalAmount: amountValue / this.state.dotPrice,
      });
      if (this.state.compound) {
        const configCompound = this.state.configUsdCompTrue;

        this.setState({
          dailyEarningsDollar: amountValue * configCompound.dailyDoller,
          dailyEarningsDot: amountValue * configCompound.dailyDot,
          monthlyEarningsDollar: amountValue * configCompound.monthlyDoller,
          monthlyEarningsDot: amountValue * configCompound.monthlyDot,
          yearlyEarningsDollar: amountValue * configCompound.yearlyDoller,
          yearlyEarningsDot: amountValue * configCompound.yearlyDot,
        });
      } else {
        const configCompound = this.state.configUsdCompFalse;

        this.setState({
          dailyEarningsDollar: amountValue * configCompound.dailyDoller,
          dailyEarningsDot: amountValue * configCompound.dailyDot,
          monthlyEarningsDollar: amountValue * configCompound.monthlyDoller,
          monthlyEarningsDot: amountValue * configCompound.monthlyDot,
          yearlyEarningsDollar: amountValue * configCompound.yearlyDoller,
          yearlyEarningsDot: amountValue * configCompound.yearlyDot,
        });
      }
    }
  }

  handleCopy(value) {
    copy(value);
  }

  handleClose() {
    this.setState({
      modalOpen: false,
    });
  }

  render() {
    return (
      <>
        <Header />
        <StakingDetails
          title="E T H E R E U M"
          image={Ethereum_logo}
          currency="ETH"
          description=" Our Total Stake: "
          description_val="32 ETH "
          description_Deli="Rewards: "
          description_Deli_val="0"
          AboutNode={this.state.AboutNode}
          Tutorial={this.state.Tutorial}
          NodeInfo={this.state.NodeInfo}
          AvalanchDetails="Ethereum Protocol Details"
          AvalancheProtoDescription="While Ethereum 2.0 is an upgraded version of Ethereum 1.0 that introduces Proof-of-Stake and Sharding, it is not a hardfork and has to be considered a separate, independent blockchain. Ethereum 2.0 is deployed in a three phase roll-out in the course of the next years. Ethereum 1.0 will eventually become a Ethreum 2.0 shard. For further information, please refer to our 'Intro to Ethereum 2.0' post."
        />
        <Footer />
      </>
    );
  }
}
export default ShortTabs;
