import React from "react";
import config from "../config";

export default class AWSService extends React.Component {
  getUserInfo = async () => {
      
    return fetch(
      config.awsConfig.LoginURL + "/oauth2/userInfo",
      {
        method: "POST",
        headers: {
            'Authorization': 'Bearer '+sessionStorage.getItem('access_token'),
        }
      }
    )
      .then((res) => (res.ok ? res : Promise.reject(res)))
      .then((res) => res.json());
  };
}
