import React, { Fragment, useState, Component, useEffect } from "react";
import NodePageBanner from "../../images/blockchain_logos/NodePageBanner.png";
import AddressCopied from "../../images/blockchain_logos/AddressCopied.png";
import Copy from "../../images/blockchain_logos/copy.svg";
import './StakingDetails.css';
import { Link } from "react-router-dom";
import Typography from "@mui/material/Typography";

import moment from "moment";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import MUIDataTable from "mui-datatables";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";

import Button from "@mui/material/Button";
import copy from "copy-to-clipboard";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@material-ui/core/Slide";
import NodeInfoService from "../../Service/NodeInfoService";
const label = { inputProps: { "aria-label": "Switch demo" } };

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  // console.log(onClose);

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {/* {onClose ? ( */}
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      {/* ) : null} */}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const CustomizedDialogs = (props) => {
  const { isDialogOpen } = props;
  console.log("CustomizedDialogs value is..." + isDialogOpen);
  const [open, setOpen] = React.useState(isDialogOpen);

  React.useEffect(() => {
    const { isDialogOpen } = props;
    setOpen(isDialogOpen);
    console.log("CustomizedDialogs value is..." + open);
  }, [props]);

  const handleClose = () => {
    setOpen(false);
    props.onCloseModel(false);
  };

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
          style={{ textAlign: "center" }}
        >
          <b>Address copied to clipboard</b>
        </BootstrapDialogTitle>
        <DialogContent dividers style={{ textAlign: "center" }}>
          <Typography gutterBottom>Welcome, to the future of money</Typography>
          <Typography gutterBottom>
            <img src={AddressCopied} width={"40%"} />
          </Typography>
          <Typography gutterBottom>
            <p>You can now paste the address in your staking wallet</p>
            <p>Need help? Check out our tutorials</p>
          </Typography>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
};

class StakingDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // const AvaxName : props.AvaxName,
      activeTab: "1",
      amount: 0,
      isAvax: "false",
      currencyType: "AVAX",
      dailyEarningsDollar: 0.0,
      dailyEarningsDot: 0.0,
      monthlyEarningsDollar: 0.0,
      monthlyEarningsDot: 0.0,
      yearlyEarningsDollar: 0.0,
      yearlyEarningsDot: 0.0,
      compound: false,
      totalAmount: 0.0,
      configCompoundTrue: {
        dailyDoller: 0.25911,
        dailyDot: 0.00012,
        monthlyDoller: 7.88115,
        monthlyDot: 0.00367,
        yearlyDoller: 94.57384,
        yearlyDot: 0.04403,
      },
      configCompoundfalse: {
        dailyDoller: 0.23867,
        dailyDot: 0.00012,
        monthlyDoller: 7.69442,
        monthlyDot: 0.00358,
        yearlyDoller: 92.33309,
        yearlyDot: 0.04299,
      },
      dotPrice: 2147.78,
      configUsdCompTrue: {
        dailyDoller: 0.00012,
        dailyDot: 0.00000006,
        monthlyDoller: 0.0036694,
        monthlyDot: 0.0000018,
        yearlyDoller: 0.0440333,
        yearlyDot: 0.0000217,
      },
      configUsdCompFalse: {
        dailyDoller: 0.0001177,
        dailyDot: 0.000000058,
        monthlyDoller: 0.0035825,
        monthlyDot: 0.000001768,
        yearlyDoller: 0.04299,
        yearlyDot: 0.000021215,
      },
      nodeInfoService: new NodeInfoService(),
      modalOpen: false,
      validators: [],
      validator: [],
      setRows:[],
      setValidator:[],
    };
  }

  handleAmountChange(amountValue, currencyType, compound) {
    // const amountValue = event.target.value;
    console.log("event from handleAmountChange..." + amountValue);

    if (this.state.currencyType == "AVAX") {
      this.setState({
        currencyType: currencyType,
        compound: compound,
        amount: amountValue,
        totalAmount: amountValue * this.state.dotPrice,
      });
      if (this.state.compound) {
        const configCompound = this.state.configCompoundTrue;

        this.setState({
          dailyEarningsDollar: amountValue * configCompound.dailyDoller,
          dailyEarningsDot: amountValue * configCompound.dailyDot,
          monthlyEarningsDollar: amountValue * configCompound.monthlyDoller,
          monthlyEarningsDot: amountValue * configCompound.monthlyDot,
          yearlyEarningsDollar: amountValue * configCompound.yearlyDoller,
          yearlyEarningsDot: amountValue * configCompound.yearlyDot,
        });
      } else {
        const configCompound = this.state.configCompoundfalse;

        this.setState({
          dailyEarningsDollar: amountValue * configCompound.dailyDoller,
          dailyEarningsDot: amountValue * configCompound.dailyDot,
          monthlyEarningsDollar: amountValue * configCompound.monthlyDoller,
          monthlyEarningsDot: amountValue * configCompound.monthlyDot,
          yearlyEarningsDollar: amountValue * configCompound.yearlyDoller,
          yearlyEarningsDot: amountValue * configCompound.yearlyDot,
        });
      }
    } else if (this.state.currencyType == "USD") {
      this.setState({
        currencyType: currencyType,
        compound: compound,
        amount: amountValue,
        totalAmount: amountValue / this.state.dotPrice,
      });
      if (this.state.compound) {
        const configCompound = this.state.configUsdCompTrue;

        this.setState({
          dailyEarningsDollar: amountValue * configCompound.dailyDoller,
          dailyEarningsDot: amountValue * configCompound.dailyDot,
          monthlyEarningsDollar: amountValue * configCompound.monthlyDoller,
          monthlyEarningsDot: amountValue * configCompound.monthlyDot,
          yearlyEarningsDollar: amountValue * configCompound.yearlyDoller,
          yearlyEarningsDot: amountValue * configCompound.yearlyDot,
        });
      } else {
        const configCompound = this.state.configUsdCompFalse;

        this.setState({
          dailyEarningsDollar: amountValue * configCompound.dailyDoller,
          dailyEarningsDot: amountValue * configCompound.dailyDot,
          monthlyEarningsDollar: amountValue * configCompound.monthlyDoller,
          monthlyEarningsDot: amountValue * configCompound.monthlyDot,
          yearlyEarningsDollar: amountValue * configCompound.yearlyDoller,
          yearlyEarningsDot: amountValue * configCompound.yearlyDot,
        });
      }
    }
  }

  handleCopy(value) {
    copy(value);
  }

  handleClose() {
    this.setState({
      modalOpen: false,
    });
  }

  // componentDidMount() {
  //   this.state.nodeInfoService.getNodeInfo().then((res) => {
  //     this.setState({ data: res });
  //     if (res) {
  //       console.log("json", res.body.result.validators[0]);
  //       const row = [];
  //       this.setState({ validators: res.body.result.validators });
  //       res.body.result.validators[0].delegators.map((del) => {
  //         console.log("ddddd", del);
  //         return null;
  //       });
  //       console.log("row", row);
  //       this.setState({ row });
  //       this.setState({ load: false });
  //     } else {
  //       this.setState({ load: false });
  //     }
  //   }, []);
  // }

  componentDidMount() {
    this.state.nodeInfoService.getNodeInfo().then((res) => {
     /* eslint-disable react/prop-types */
     /* eslint-disable react/prop-types */
     console.log("props props useEffect", res.res_object);
     console.log(this.state.validator);
    //  this.state.setRows(validator);
     this.state = {setRows:[]}; 

     const valArr = [];
     valArr.push({
       txId: res.res_object.txId,
       startTime: res.res_object.startTime,
       endTime: res.res_object.endTime,
       stakeAmount: res.res_object.stakeAmount,
       // timeleft: 1,
     });
     this.setState({ setValidator:valArr });
     // console.log("aaaaaa2", validator);
     //       this.setState({ validators: res.body.result.validators });
  },[]);
}



  render() {
    const { datas, validators } = this.state;
    const { AboutNode } = this.props
    if (validators) {
      console.log("validators", validators[0]?.nodeID);
    }
    console.log(this.props);
    return (
      <>
        {/* {console.log("value of modalOpen is..."+this.state)} */}
        <CustomizedDialogs
          isDialogOpen={this.state.modalOpen}
          onCloseModel={this.handleClose}
        />

        <div className="page-content">
          <div className="content-block">
            <div className="section-full content-inner">
              <div className="dlab-bnr-inr" style={{ backgroundImage: "url(" + NodePageBanner + ")", }}>
                <div className="NodeBanner">
                  <img className="StakingLogo" src={this.props.image} />
                  <span className="StakingTitle"> {this.props.title}</span>
                  <div className="StakingCurrency">  {this.props.currency}</div>
                  <div className="dlab-separator StakingUnderline"></div>
                  <div className="Description">{this.props.description}<span className="DescriptionVal">{this.props.description_val}</span></div>
                  <div className="Description">{this.props.description_Deli}<span className="DescriptionVal">{this.props.description_Deli_val}</span></div>
                </div>
              </div>
              <br />
              <br />
              <div className="container">
                <div className="node-info">
                  <ul>
                    {
                      AboutNode.map((data, index) => {
                        return (
                          <li key={index}>
                            <div className="AboutNode">
                              <img className="AboutNodeImg" src={data.NodeImg} />
                              <h6 className="AboutNodeTitle">{data.titles}{data.descriptions}</h6>
                              <p className="AboutNodeContent">{data.content}</p>
                            </div>
                          </li>
                        )
                      })
                    }
                  </ul>
                </div>
                <div className="StakeAvax">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="row How-to-stake-avax">
                        <div className="col-lg-3">
                          <img className="AvaxImg" src={
                            this.props.Tutorial && this.props.Tutorial[1]
                              ? this.props.Tutorial[1].Tuto_image
                              : this.state.Tutorial[1].Tuto_image
                          }
                          />
                        </div>
                        <div className="col-lg-9 How-to-stake-avax-title">
                          <p>
                            {this.props.Tutorial && this.props.Tutorial[1]
                              ? this.props.Tutorial[1].Tuto_title
                              : this.state.Tutorial[1].Tuto_title}{" "}
                          </p>
                          <Button className="ReadNow"
                            variant="outlined"
                            size="small"
                          >
                            {" "}
                            <a className="ReadNow-Font"
                              href={
                                this.props.Tutorial && this.props.Tutorial[1]
                                  ? this.props.Tutorial[1].Tuto_StakeAvax
                                  : this.state.Tutorial[1].Tuto_StakeAvax
                              }
                              target="_blank"
                            >
                              Read Now
                            </a>
                          </Button>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="row Stake-Avax-via-Coindelta">
                        <div className="col-lg-3">
                          <img className="AvaxImg" src={
                            this.props.Tutorial && this.props.Tutorial[3]
                              ? this.props.Tutorial[3].Tuto_image
                              : this.state.Tutorial[3].Tuto_image
                          }
                          />
                        </div>
                        <div className="col-lg-9 How-to-stake-avax-title">
                          <p>
                            {this.props.Tutorial && this.props.Tutorial[3]
                              ? this.props.Tutorial[3].Tuto_title
                              : this.state.Tutorial[3].Tuto_title}{" "}
                          </p>
                          <Button className="ReadNow"
                            variant="outlined"
                            size="small"
                          >
                            {" "}
                            <a className="ReadNow-Font"
                              href={
                                this.props.Tutorial && this.props.Tutorial[3]
                                  ? this.props.Tutorial[3].Tuto_StakeNow
                                  : this.state.Tutorial[3].Tuto_StakeNow
                              }
                              target="_blank"
                            >
                              Stake Now
                            </a>
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <br />

                <div className="StakeAvax">
                  <div className="row" style={{ backgroundColor: "#F2F2FA", padding: "1rem", fontFamily: "Gilroy-Bold", color: "black" }}>
                    <div className="col-lg-1">
                      <img
                        src={
                          this.props.Tutorial && this.props.Tutorial[2]
                            ? this.props.Tutorial[2].Tuto_image
                            : this.state.Tutorial[2].Tuto_image
                        }
                      />
                    </div>
                    <div className="col-lg-5">
                      <p style={{ fontSize: "20px", }}>
                        {this.props.Tutorial && this.props.Tutorial[2]
                          ? this.props.Tutorial[2].Tuto_description
                          : this.state.Tutorial[2].Tuto_description}{" "}
                      </p>
                      <p style={{ marginTop: "-30px", }}>
                        {this.props.Tutorial && this.props.Tutorial[2]
                          ? this.props.Tutorial[2].Tuto_title
                          : this.state.Tutorial[2].Tuto_title}{" "}
                      </p>
                    </div>
                    <div className="col-lg-6">
                      <Button
                        onClick={() => {
                          console.log("copy Called");
                          copy(
                            this.props.Tutorial &&
                              this.props.Tutorial[2]
                              ? this.props.Tutorial[2].Tuto_CopyId
                              : this.state.Tutorial[2].Tuto_CopyId
                          );
                          this.setState({
                            modalOpen: true,
                          });
                        }}
                        variant="outlined"
                        size="small"
                        style={{
                          borderColor: "#F2F2FA",
                          borderRadius: "5px",
                          color: "black",
                          marginTop: "10px",
                          fontSize: "18px",
                          backgroundColor: "#d2d2e4",
                        }}
                      >
                        Copy ID
                        <img style={{ marginLeft: "8px" }} src={Copy} />
                      </Button>
                    </div>
                  </div>

                  <div
                    style={{
                      width: "1110px",
                      backgroundColor:"#F2F2FA",
                      marginLeft:"-15px"
                    }}
                  >
                    <Box
                    >
                      <Typography variant="h6" style={{marginLeft:"15px"}}>
                        {this.props.NodeInfo && this.props.NodeInfo[0]
                          ? this.props.NodeInfo[0].Node_Title
                          : this.state.NodeInfo[0].Node_Title}{" "}
                      </Typography>
                    </Box>

                    <TableContainer style={{ borderBottom: "3px solid black" }}>
                      <Table style={{ width: "100%" }} aria-label="simple table">
                        <TableHead style={{backgroundColor:"white"}}>
                          <TableRow style={{ borderBottom: "2px solid black" }}>
                            <TableCell>
                              {this.props.NodeInfo && this.props.NodeInfo[0]
                                ? this.props.NodeInfo[0].NodeID
                                : this.state.NodeInfo[0].NodeID}{" "}
                            </TableCell>
                            <TableCell>
                            {this.props.NodeInfo && this.props.NodeInfo[0]
                                ? this.props.NodeInfo[0].StartDate
                                : this.state.NodeInfo[0].StartDate}{" "}
                            </TableCell>
                            <TableCell>
                            {this.props.NodeInfo && this.props.NodeInfo[0]
                                ? this.props.NodeInfo[0].EndDate
                                : this.state.NodeInfo[0].EndDate}{" "}
                            </TableCell>
                            {/* <TableCell align="left">
                            {this.props.NodeInfo && this.props.NodeInfo[0]
                                ? this.props.NodeInfo[0].Duration
                                : this.state.NodeInfo[0].Duration}{" "}
                            </TableCell> */}
                            <TableCell>
                            {this.props.NodeInfo && this.props.NodeInfo[0]
                                ? this.props.NodeInfo[0].ValidatorStake
                                : this.state.NodeInfo[0].ValidatorStake}{" "}
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody style={{ marginBottom: "1px solid black" }}>
                          {this.state.setValidator &&
                            this.state.setValidator.map((row) => (
                              <TableRow
                                key={row.txId}
                                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                              >
                                <TableCell component="th" scope="row">
                                  {row.txId}
                                </TableCell>

                                <TableCell align="left">
                                  {" "}
                                  {moment(new Date(row.startTime * 1000)).format(
                                    "MMMM  DD, YYYY  hh : mm"
                                  )}
                                  <span>GMT</span>
                                  <br />
                                </TableCell>

                                <TableCell align="left">
                                  {moment(new Date(row.endTime * 1000)).format(
                                    "MMMM  DD, YYYY  hh : mm"
                                  )}
                                  <span>GMT</span>
                                  <br />
                                </TableCell>
                                <TableCell align="left">
                                  {row.stakeAmount / 1000000000} <span>AVAX</span>
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>

                </div>
                <br />

                <div className="AvaxDetails">
                  <div className="text-center section-head">
                    <h1 className="AvaxProtocolDetails">
                      {this.props.AvalanchDetails}
                    </h1>
                    <div className="dlab-separator StakingUnderline"></div>
                    <p className="AvaxProtocolDescription">
                      {this.props.AvalancheProtoDescription}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default StakingDetails;
