import React, { Component } from "react";
// import HeaderCompany from "../../Layout/HeaderCompany";
import Header from "../../Layout/Header1";
import Footer2 from "../../Layout/footer2";
import Footer1 from "../../Layout/Footer1";

class NewsAndPress extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: "1",
    };
  }

  render() {
    return (
      <>
        <Header />
        <div style={{ fontFamily: "Sarala, sans-serif" }}>
          <div className="row" style={{ backgroundColor: "#092d55" }}>
            <div className="col-lg-12">
              <div
                style={{
                  height: "300px",
                  fontFamily: "Sarala, sans-serif",
                  color: "white",
                }}
              >
                <div style={{ position: "absolute", top: "40%", left: "20%" }}>
                  <p
                    style={{
                      marginBottom: "20px",
                      fontSize: "50px",
                      lineHeight: "55px",
                    }}
                  >
                    <b>News & Press</b>
                  </p>
                  <p>Overview of our latest news and articles</p>
                </div>
              </div>
            </div>
          </div>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
        </div>
        <Footer1 />
      </>
    );
  }
}
export default NewsAndPress;
