import React, { Component } from "react";
import Header from "../../Layout/Header1";
// import HeaderCompany from "../../Layout/HeaderCompany";
import Footer1 from "../../Layout/Footer1";

class Careers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: "1",
    };
  }

  render() {
    return (
      <>
        <Header />
        <div style={{ fontFamily: "Sarala, sans-serif" }}>
          <div className="row" style={{ backgroundColor: "#092d55" }}>
            <div className="col-lg-12">
              <div
                style={{
                  height: "300px",
                  fontFamily: "Sarala, sans-serif",
                  color: "white",
                }}
              >
                <div style={{ position: "absolute", top: "40%", left: "20%" }}>
                  <p
                    style={{
                      marginBottom: "20px",
                      fontSize: "50px",
                      lineHeight: "55px",
                    }}
                  >
                    <b>Join our team</b>
                  </p>
                  <p>and help us in making Web 3.0 a reality!</p>
                </div>
              </div>
            </div>
          </div>

          <div className="row" style={{ marginTop: "40px" }}>
            <div className="col-lg-2" />
            <div
              className="col-lg-8"
              style={{
                textAlign: "center",
                padding: "20px",
                marginTop: "35px",
                marginBottom: "40px",
              }}
            >
              <h1 style={{ fontSize: "30px" }}>
                <b>Who we are</b>
              </h1>
              <p>
                We are an international team comprised of highly-motivated,
                independent individuals. While most of us are based in Munich,
                working at Staking Facilities is not city bound. Each of us
                brings something unique to the table, but we all share the
                vision of an economic system that is natively digital, inclusive
                and secure - powered by Web 3.0 technologies.
              </p>
            </div>

            <div className="col-lg-2" />
          </div>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
        </div>
        <Footer1 />
      </>
    );
  }
}
export default Careers;
