import React, { Component } from "react";
import { Link } from "react-router-dom";
import Header from "./../../Layout/Header1";
import Footer from "./../../Layout/Footer1";

class Terms extends Component {
  render() {
    return (
      <>
        <Header />

        <div className="page-content bg-white">
          {/* <!-- contact area --> */}
          <div className="content-block">
            {/* <!-- Current Networks --> */}
            <div
              className="section-full bg-blue-light content-inner about-us-box"
              style={{ backgroundSize: "cover" }}
            >
              <div
                className="container"
                style={{ marginBottom: "0px", color: "black" }}
              >
                <h1 style={{ textAlign: "center" }}>TERMS OF SERVICE</h1>
                <p>
                  Coindelta, LLC (“Coindelta”, the “Company,” “Us,” “Our” or
                  “We”), by and through https://coindelta.io (the “Site”),
                  operates, hosts and monitors validation nodes for a variety of
                  cryptocurrencies (as defined below, the “Services”). Coindelta
                  provides Services pursuant to, and in accordance with, the
                  following terms and conditions (this “Agreement” or “Terms of
                  Use”). Accessing, browsing or viewing the Site, including, but
                  not limited to, registering for an account, submitting any
                  information to Us, or utilizing any of the Services as
                  provided and offered by Coindelta, you hereby consent and
                  agree to be bound by these Terms of Use, as contained herein.
                  You (“Client”) agree to be bound by these Terms of Use and all
                  other operating rules, policies and procedures that may be
                  published by Us from time to time on the Site, each of which
                  is incorporated by reference and each of which may be updated
                  by Us from time to time without notice to you. Your use of the
                  Site is governed by the version of these Terms of Use in
                  effect on the date of use.
                </p>

                <p>
                  <b>1. Definitions</b>
                </p>
                <p style={{ marginBottom: "0px" }}>
                  <b>“Blockchain”</b> means a digital ledger in which
                  transactions made with a Cryptocurrency are recorded
                  chronologically.
                </p>
                <p style={{ marginBottom: "0px" }}>
                  <b>“Cryptocurrency”</b> means a digital currency, operating
                  independently of a central bank, in which encryption
                  techniques are used to regulate the generation of units of
                  currency and verify the transfer of funds.
                </p>
                <p style={{ marginBottom: "0px" }}>
                  <b>“Client Data”</b> means all data, files and information
                  that Client provides to Coindelta in the course of using the
                  Services.
                </p>
                <p style={{ marginBottom: "0px" }}>
                  <b>“Client Funds”</b> means the Cryptocurrency funds held by
                  Client.
                </p>
                <p style={{ marginBottom: "0px" }}>
                  <b>“Net Revenue”</b> means Staking Rewards minus any Slashing
                  Penalties assessed, if applicable.
                </p>
                <p style={{ marginBottom: "0px" }}>
                  <b>“Pooled Staking Service”</b> means the Coindelta Service
                  that runs Staking Nodes for Supported Protocols and allows
                  investors to Stake their Cryptocurrency holdings in exchange
                  for a percentage of the Net Revenue generated from Staking.
                </p>
                <p style={{ marginBottom: "0px" }}>
                  <b>“Slashing Penalty”</b> means any penalty assessed by the
                  Cryptocurrency for unavailability or slow, incorrect or
                  malicious performance.
                </p>
                <p style={{ marginBottom: "0px" }}>
                  <b>“Staking” or “to Stake”</b> means committing Cryptocurrency
                  holdings as a monetary guarantee of the correct and performant
                  operation of a designated Staking Node.
                </p>
                <p style={{ marginBottom: "0px" }}>
                  <b>“Staking Nodes”</b> means the collection of server hardware
                  and software required to maintain a current copy of the
                  Blockchain for a Cryptocurrency and to produce or validate new
                  blocks.
                </p>
                <p style={{ marginBottom: "0px" }}>
                  <b>“Staking Node Services”</b> or the “Services” means the
                  Pooled Staking Service.
                </p>
                <p style={{ marginBottom: "0px" }}>
                  <b>“Staking Rewards”</b> means all Cryptocurrency generated by
                  operating Staking Nodes, including, but not limited to, block
                  rewards, endorser rewards, transaction fees and any other
                  direct payments as a result of operating a Staking Node.
                </p>
                <p style={{ marginBottom: "0px" }}>
                  <b>“Supported Protocol”</b> means a Cryptocurrency for which
                  Coindelta operates Staking Nodes.
                </p>
                <p style={{ marginBottom: "0px" }}>
                  <b>“Revenue Share Payments”</b> means the percentage of Net
                  Revenue paid to Client as set forth in Section 2(c).
                </p>
                <p>
                  <b>“Unbonding Period”</b> means the Cryptocurrency imposed
                  waiting period, during which the Client Funds and End Users
                  Funds may not be withdrawn or sold.
                </p>

                <p>
                  <b>2. Coindelta RESPONSIBILITIES</b>
                </p>
                <p style={{ marginBottom: "0px" }}>
                  a. <b>Staking Node Services.</b> During the Term, Coindelta
                  will provide the Staking Node Services to Client in accordance
                  with the service level terms set forth below.
                </p>
                <p style={{ marginBottom: "0px" }}>
                  b. <b>Server Procurement, Setup & Maintenance.</b> Coindelta
                  will determine, in its sole discretion, the network and server
                  requirements to reliably operate Staking Nodes. Coindelta is
                  responsible for all costs associated with procuring,
                  installing and operating the Staking Nodes.
                </p>
                <p style={{ marginBottom: "0px" }}>
                  c. <b>Revenue Share Payments:</b> Coindelta will pay Client a
                  percentage of Net Revenue directly attributable to Client
                  Funds. Pooled Staking Services will pay a percentage of Net
                  Revenue that will vary by Cryptocurrency, and will be posted
                  on the https://coindelta.io/yields/ page or in your account.
                  All Revenue Share Payments will be made in the same
                  Cryptocurrency in which the Client Funds or End Users Funds
                  are denominated, as applicable.
                </p>
                <p>
                  d. <b>Reporting:</b> Coindelta will provide regular reports
                  through our dashboard detailing the performance of the
                  Services to Client. Such reports will provide mutually
                  agreeable detail to enable the accurate calculation of Net
                  Revenue.
                </p>

                <p>
                  <b>3. CLIENT RESPONSIBILITIES</b>
                </p>
                <p style={{ marginBottom: "0px" }}>
                  a. <b>Stake Delegation.</b> Client will have the sole right to
                  determine the amount of Client Funds and End Users Funds which
                  will be Coindelta pursuant to this Agreement.
                </p>
                <p style={{ marginBottom: "0px" }}>
                  b. <b>Withdrawals.</b> Client acknowledges and agrees that
                  Client Funds and End Users Funds which have been Coindelta
                  pursuant hereto may not be withdrawn prior to the conclusion
                  of the Unbonding Period.
                </p>
                <p>
                  c. <b>Taxes:</b> Client shall be responsible for payment of
                  all applicable taxes, if any, to which the Revenue Share
                  Payments might be subject and any and all other taxes which
                  may apply to Client; Coindelta makes no representations in
                  this regard. Client agrees to indemnify and hold Coindelta
                  harmless against any taxes, including penalties, duties and
                  interest levied by any government on the Revenue Share
                  Payments.
                </p>

                <p>
                  <b>4. LICENSE GRANT; RESTRICTIONS</b>
                </p>
                <p style={{ marginBottom: "0px" }}>
                  a. <b>Proprietary Rights.</b> Client acknowledges and agrees
                  that this license conveys no title or ownership rights to the
                  Coindelta Services. Client does not acquire any rights in the
                  Services, express or implied, other than those expressly
                  granted in this Agreement and all rights not expressly granted
                  to Client are reserved by Coindelta. Coindelta retains all
                  right, title and interest in and to the Coindelta Services,
                  including without limitation, all unpatented inventions,
                  patent applications, patents, design rights, copyrights,
                  trademarks, service marks, trade names, know-how and other
                  trade secret rights, and all other intellectual property
                  rights, derivatives or improvements thereof.
                </p>
                <p>
                  b. <b>Restrictions.</b> Client will not, and will not permit
                  any other third party to modify, reproduce, copy, reverse
                  engineer, decompile, reverse assemble or otherwise attempt to
                  discover the source code or algorithms for the Coindelta
                  Services, or access the Services in order to build a similar
                  or competitive product or service. Client will not, and will
                  not permit any of its End Users or other third party to use
                  any device, software or routine to interfere with the proper
                  function of the Services. The Coindelta Services may not be
                  used in any jurisdiction for unlawful, obscene, offensive or
                  fraudulent content or activity, such as advocating or causing
                  harm, interfering with or violating the integrity or security
                  of a network or system, evading filters, sending unsolicited,
                  abusive, or deceptive messages, viruses or harmful code, or
                  violating third party rights. If there is a complaint or
                  notice of violation, use may be suspended until resolved, and
                  terminated if not resolved promptly.
                </p>

                <p>
                  <b>5. TERM & TERMINATION</b>
                </p>
                <p style={{ marginBottom: "0px" }}>
                  a. <b>Term.</b> Agreement will commence upon the Effective
                  Date and, unless earlier terminated as set forth below,
                  continue for a period of twelve months (the “Initial Term”).
                  Thereafter, the Agreement will automatically renew for
                  successive twelve-month periods (each, a “Renewal Term”),
                  unless either party provides written notice to the other of
                  its desire to terminate no later than sixty (60) days prior to
                  the expiration of the then-current term. As used herein,
                  “Term” means the Initial Term plus any Renewal Term(s).
                </p>
                <p style={{ marginBottom: "0px" }}>
                  b. <b>Termination.</b> Either party may terminate this
                  Agreement if the other party fails to cure any material breach
                  of this Agreement within thirty (30) days after receipt of
                  written notice describing the breach in reasonable detail. In
                  addition, Coindelta may terminate this Agreement on seven (7)
                  days written notice for any reason or no reason whatsoever.
                </p>
                <p>
                  c. <b>Effect of Termination.</b> Upon termination or
                  expiration of this Agreement: (i) all rights and obligations
                  of both parties, including all licenses granted hereunder,
                  shall immediately terminate, (ii) ii) each party will destroy
                  all Confidential Information and other materials of the other
                  party in its possession and (iii) Sections 4(b), 5(c), 6,
                  7(c), 8, 9 and 14, together with any payment obligations
                  existing as of the effective date of termination, will
                  survive.
                </p>

                <p>
                  <b>6. CONFIDENTIALITY</b>
                </p>
                <p>
                  The parties acknowledge that the terms of this Agreement will
                  be confidential. The parties further acknowledge that, in the
                  course of performing duties under this Agreement, each party
                  may obtain from the other party data or information of a
                  confidential or proprietary nature, including know-how and
                  trade secrets, relating to the business, the affairs, the
                  development projects, or current or future products or
                  services of such party (“Confidential Information”). Data or
                  information will be considered Confidential Information if:
                  (a) a party has marked it as such; (b) a party, orally or in
                  writing, has advised the other party of its confidential or
                  proprietary nature, or (c) due to its character and nature, a
                  reasonable person under like circumstances would treat it as
                  confidential. Neither party will either (i) publish, disclose
                  or otherwise divulge any of the other party’s Confidential
                  Information to any person, except its officers, employees or
                  agents with a need to know who are under a contractual or
                  professional duty to maintain the confidentiality of such
                  information consistent with the obligations imposed hereunder;
                  or (ii) permit its officers, employees or agents to divulge
                  any of the other party’s Confidential Information without the
                  express prior written consent of the other party. The
                  receiving party will protect the disclosing party’s
                  Confidential Information with the same degree of care that the
                  receiving party protects its own information of a similar
                  nature, but in no event less than reasonable care. Neither
                  party will use the other party’s Confidential Information
                  except in the course of performing its duties under this
                  Agreement. The foregoing obligations will not apply to any
                  Confidential Information that (1) is already known to the
                  receiving party; (2) is or becomes publicly known through no
                  wrongful act of the receiving party; (3) is independently
                  developed by the receiving party without benefit of the
                  disclosing party’s Confidential Information; (4) is received
                  from a third party without similar restriction and without
                  breach of any obligation of confidentiality; or (5) is
                  required or reasonably necessary to be disclosed to comply
                  with laws, statutes, regulations, orders, and other
                  governmental rules. Additionally, neither party will be
                  prohibited from disclosing the terms and conditions of this
                  Agreement to financial institutions when required to obtain
                  financing or to a third party involved with a potential merger
                  or acquisition (either as target or acquirer). The obligations
                  of confidentiality described above will expire three years
                  after the expiration or termination of this Agreement.
                </p>

                <p>
                  <b>7. REPRESENTATIONS & WARRANTIES</b>
                </p>
                <p style={{ marginBottom: "0px" }}>
                  a. <b>Representations and Warranties by Coindelta.</b>{" "}
                  Coindelta represents and warrants to Client that: (i) it has
                  the power and authority to enter into this Agreement, (ii)
                  entering into this Agreement will not be a breach or violation
                  of any other contract or agreement to which Coindelta is
                  bound, (iii) it provides the Services using commercially
                  reasonable care and skill in accordance with the service
                  levels outlined
                </p>
                <p style={{ marginBottom: "0px" }}>
                  b. <b>Representations and Warranties by Client.</b> Client
                  represents and warrants to Coindelta that: (i) it has the
                  power and authority to enter into this Agreement, and (ii)
                  entering into this Agreement will not be a breach or violation
                  of any other contract or agreement to which Client is bound.
                </p>
                <p>
                  c. <b>Disclaimer.</b> EXCEPT FOR THE EXPRESS WARRANTIES STATED
                  IN THIS SECTION 7, NEITHER PARTY MAKES, AND EACH PARTY
                  EXPRESSLY DISCLAIMS, ALL WARRANTIES, EXPRESS, IMPLIED,
                  STATUTORY OR OTHERWISE, WITH RESPECT TO THE SERVICES AND THE
                  SUBJECT MATTER OF THIS AGREEMENT, INCLUDING WITHOUT LIMITATION
                  IMPLIED WARRANTIES OF ACCESS, MERCHANTABILITY, FITNESS FOR A
                  PARTICULAR PURPOSE, AND IMPLIED WARRANTIES ARISING FROM COURSE
                  OF DEALING OR COURSE OF PERFORMANCE. EXCEPT AS STATED IN THIS
                  SECTION 7(a), THE SERVICES ARE PROVIDED TO CLIENT ON AN “AS
                  IS” AND “AS AVAILABLE” BASIS, AND ARE FOR COMMERCIAL USE ONLY.
                  Client understands that while Coindelta employs measures to
                  ensure that the Services are accessible 24 hours a day/7 days
                  a week, Coindelta cannot guarantee the uninterrupted or
                  error-free operation performance of the Services or that
                  Coindelta will correct all defects or prevent third party
                  disruptions or unauthorized third party access. In the event
                  of any inaccessibility of the Coindelta Services, Coindelta’s
                  sole liability and obligation will as described in Exhibit A,
                  which is hereby incorporated by reference. Coindelta
                  warranties will not apply if there has been misuse,
                  modification, damage not caused by Coindelta, failure to
                  comply with instructions provided by Coindelta, or if
                  otherwise stated in in Exhibit A. Non-Coindelta services are
                  sold under the Agreement as-is, without warranties of any
                  kind. Third parties may provide their own warranties to
                  Client.
                </p>

                <p>
                  <b>8. LIMITATION OF LIABILITY</b>
                </p>
                <p>
                  Coindelta’S ENTIRE LIABILITY FOR ALL CLAIMS RELATED TO THE
                  AGREEMENT WILL NOT EXCEED THE AMOUNT OF ANY ACTUAL DIRECT
                  DAMAGES INCURRED BY CLIENT, UP TO THE NET REVENUES ACTUALLY
                  RECEIVED BY Coindelta IN THE PRIOR 12 MONTHS WITH RESPECT TO
                  THE SERVICES THAT ARE THE SUBJECT OF THE CLAIM, REGARDLESS OF
                  THE BASIS OF THE CLAIM. Coindelta WILL NOT BE LIABLE FOR
                  SPECIAL, INCIDENTAL, EXEMPLARY, INDIRECT, OR ECONOMIC
                  CONSEQUENTIAL DAMAGES, OR LOST PROFITS, BUSINESS, VALUE,
                  REVENUE, GOODWILL, OR ANTICIPATED SAVINGS. THESE LIMITATIONS
                  APPLY COLLECTIVELY TO Coindelta, ITS AFFILIATES, CONTRACTORS
                  AND END USERS. NOTWITHSTANDING THE FOREGOING, THE ABOVE
                  LIMITATIONS ON LIABILITY SHALL NOT APPLY TO: (A) A BREACH OF
                  SECTION 2(C) OR A BREACH OF SECTION 6, OR (B) A CLAIM FOR
                  INDEMNIFICATION UNDER SECTION 9.
                </p>

                <p>
                  <b>9. INDEMNIFICATION</b>
                </p>
                <p>
                  Client agrees to indemnify, defend and hold harmless
                  Coindelta, its partners, agents, officers, directors and
                  employees, from and against any loss, cost, expense, claim,
                  injury or damage (including, without limitation, reasonable
                  attorneys’ fees and expenses) (collectively, “Losses”)
                  incurred due to third party claims arising from any breach by
                  Client of any representation, warranty, or provision in this
                  Agreement. Coindelta shall indemnify, defend and hold Client
                  harmless from and against any Losses incurred due to third
                  party claims arising from any breach by Coindelta of any
                  representation, warranty, or provision contained in this
                  Agreement.
                </p>

                <p>
                  <b>10. RELATIONSHIP OF PARTIES</b>
                </p>
                <p>
                  Neither this Agreement, the relationship created between the
                  parties hereto pursuant to this Agreement, nor any course of
                  dealing between the parties hereto is intended to create, or
                  shall create, an employment relationship, a joint venture,
                  partnership or any similar relationship. Each party is solely
                  responsible for the payroll taxes, workman’s compensation
                  insurance, and any other benefits owed to their own employees.
                </p>

                <p>
                  <b>11. PUBLICITY</b>
                </p>
                <p>
                  Neither party will issue any press releases or make any other
                  public disclosures regarding this Agreement or its terms or
                  the nature of the parties’ relationship without the other
                  party’s prior written consent. Notwithstanding the foregoing,
                  Coindelta may list Client as a customer on its website and in
                  marketing materials.
                </p>

                <p>
                  <b>12. ASSIGNMENT</b>
                </p>
                <p>
                  This Agreement and the rights hereunder are not transferable
                  or assignable without prior written consent of the
                  non-assigning party; provided, however, that this Agreement
                  may be assigned by a party (a) to a person or entity who
                  acquires substantially all of such party’s assets, stock or
                  business by sale, merger or otherwise and (b) to an affiliate
                  of such party.
                </p>

                <p>
                  <b>13. COMPLIANCE WITH LAWS</b>
                </p>
                <p>
                  Each party shall comply, at its own expense, with all
                  statutes, regulations, rules, ordinances, and orders of any
                  governmental body, department or agency that apply to or
                  result from its obligations under this Agreement. If Client
                  receives any notice or becomes aware of any violation of any
                  law, statute, rule, regulation or ordinance with respect to
                  the White-Labeled Services hereof, Client shall promptly
                  notify Coindelta of such notice or violation.
                </p>

                <p>
                  <b>14. GENERAL TERMS</b>
                </p>
                <p style={{ marginBottom: "0px" }}>
                  a. This Agreement (and any Exhibits hereto) constitutes the
                  entire agreement between the parties with respect to the
                  subject matter hereof, and may not be modified without the
                  prior written consent of both parties.
                </p>
                <p style={{ marginBottom: "0px" }}>
                  b. This Agreement will be governed and interpreted in
                  accordance with the laws of the State of Massachusetts without
                  reference to conflicts of laws principles.
                </p>
                <p style={{ marginBottom: "0px" }}>
                  c. Coindelta will not be liable for any delay or failure to
                  perform any of its obligations set forth in this Agreement due
                  to causes beyond its reasonable control.
                </p>
                <p style={{ marginBottom: "0px" }}>
                  d. No failure of either party to enforce any of its rights
                  under this Agreement will act as a waiver of such rights.
                </p>
                <p style={{ marginBottom: "0px" }}>
                  e. Neither party will bring a legal action arising out of or
                  related to the Agreement more than two years after the cause
                  of action arose.
                </p>
                <p style={{ marginBottom: "0px" }}>
                  f. If one or more provisions of this Agreement are held to be
                  unenforceable under applicable law, then such provision(s)
                  shall be excluded from this Agreement, and the balance of the
                  Agreement shall be enforceable in accordance with its terms.
                </p>
                <p style={{ marginBottom: "0px" }}>
                  g. Any notice, demand or request required or permitted to be
                  given under this Agreement shall be in writing and shall be
                  deemed sufficient when sent by email to legal@Coindelta.us.
                  Notice will be effective on receipt.
                </p>
                <p>
                  h. Headings and captions are for convenience only and are not
                  to be used in the interpretation of the Agreement.
                </p>

                <p>
                  <b>SERVICE LEVEL TERMS</b>
                </p>
                <p style={{ marginBottom: "0px" }}>
                  <b>1. Definitions</b>
                </p>
                <p style={{ marginBottom: "0px" }}>
                  “Staking Node Downtime” means the number of minutes that
                  Coindelta Staking Nodes are unable to produce blocks for a
                  Supported Cryptocurrency, but does not include any downtime
                  otherwise excluded under section 4 below.
                </p>
                <p style={{ marginBottom: "0px" }}>
                  “Force Majeure Events” means any downtime minutes that are the
                  result of events or conditions beyond Coindelta’s reasonable
                  control. Such events might include but are not limited to any
                  acts of common enemy, the elements, earthquakes, floods,
                  fires, epidemics, and inability to secure products or services
                  from other persons or entities.
                </p>

                <p style={{ marginBottom: "0px" }}>
                  <b>2. Monitoring</b>
                </p>
                <p style={{ marginBottom: "0px" }}>
                  All Staking Nodes are monitored by Coindelta 24 hours a day, 7
                  days per week. Coindelta will inform Client of all scheduled
                  maintenance which could reasonably be expected to cause
                  significant degradation in block production or subject the
                  Staking Nodes to downtime at least seven (7) days prior to the
                  planned maintenance.
                </p>

                <p style={{ marginBottom: "0px" }}>
                  <b>3. 100% Uptime</b>
                </p>
                <p style={{ marginBottom: "0px" }}>
                  In the event Staking Nodes are not continuously available for
                  block production (100% up time) as measured on a monthly
                  basis, Client becomes eligible for a credit as set forth
                  below. For each one (1) hour of Staking Node Downtime, or
                  fraction thereof, Client shall be entitled to a credit in the
                  amount equal to the pro-rated Net Revenue for one (1) hour of
                  the Revenue Share that would ordinarily be payable plus a
                  credit for any Slashing Penalties, if applicable, incurred as
                  a result of such Staking Node Downtime.
                </p>

                <p style={{ marginBottom: "0px" }}>
                  <b>4. Exclusions</b>
                </p>
                <p style={{ marginBottom: "0px" }}>
                  Notwithstanding anything to the contrary herein, Client shall
                  not be entitled to any credits hereunder if Staking Node
                  Downtime or issues of any kind are caused by (i) acts or
                  omissions of Client, (ii) scheduled Coindelta network
                  maintenance, (iii) acts by a hacker or other malicious actor,
                  or (iv) Force Majeure events.
                </p>

                <p style={{ marginBottom: "0px" }}>
                  <b>5. Required Notification & Timing of Credits</b>
                </p>
                <p style={{ marginBottom: "0px" }}>
                  No credits shall accrue under this Agreement until Coindelta
                  has actual notice of, or Client delivers to Coindelta written
                  notice of, the Staking Node Downtime. Within thirty (30) days
                  following the reporting on any Staking Node Downtime which
                  results in Client being entitled to credits, Coindelta shall
                  deliver to Client detailed reporting specifying the
                  approximate duration of the applicable failure and certifying
                  to the best of Coindelta’s knowledge the total amount of
                  credits to which Client is entitled. In no event shall the
                  total of any credits established under this Agreement for any
                  month exceed the Net Revenue retained by Coindelta during the
                  applicable month. Credits will not carry over to future
                  months. The credits provided shall be the sole remedies to
                  Client in the event of the Staking Node Downtime.
                </p>
              </div>
            </div>
            {/* <!-- About Quotes End --> */}
          </div>
          {/* <!-- contact area END --> */}
        </div>

        <Footer />
      </>
    );
  }
}
export default Terms;
