import React,{Fragment,  useState} from 'react';
import {Link} from 'react-router-dom';
import { TabContent, TabPane, } from 'reactstrap';
import classnames from 'classnames';

import test1 from './../../../images/testimonials/pic1.jpg';
import test2 from './../../../images/testimonials/pic2.jpg';
import test3 from './../../../images/testimonials/pic3.jpg';

import AccordionBlog from './../../Element/AccordionBlog';
import VideoPopup from './../../Element/VideoPopup';
import pic10 from './../../../images/about/pic10.jpg';

const commentBlog = [
	{image: test1, }, {image: test2, }, {image: test3, } 
];          
            
const TabStyle1 = (props) => {
	const [activeTab, setActiveTab] = useState('1');

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }
	  
	return(
		<Fragment>			
			
                <div className="row">
					<div className="col-md-12">
						<div className="dlab-tabs product-description tabs-site-button">
							<ul className="nav nav-tabs">
								<li>
									<Link to={"#"} className= {classnames({ active : activeTab === '1'}) + ''} onClick={() => { toggle('1'); }}><i className="fa fa-globe"></i> Description</Link>
								</li>
								<li>
									<Link to={"#"}	className={classnames({active:activeTab === '2'}) + ''} onClick={()=>{ toggle('2');}}><i className="fa fa-photo"></i> Rewards Calculator</Link>
								</li>
								<li>
									<Link to={"#"}	className={classnames({active:activeTab === '3'}) + ''} onClick={()=>{ toggle('3');}}><i className="fa fa-cog"></i> Video: How to stake?</Link>
								</li>
								<li>
									<Link to={"#"}	className={classnames({active:activeTab === '4'}) + ''} onClick={()=>{ toggle('4');}}><i className="fa fa-cog"></i> FAQs</Link>
								</li>
								
							</ul>
						
							<TabContent activeTab={activeTab}>


								<TabPane tabId="1">
									<p className="m-b10">Suspendisse et justo. Praesent mattis commyolk augue Aliquam ornare hendrerit augue Cras tellus In pulvinar lectus a est Curabitur eget orci Cras laoreet. Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Suspendisse et justo. Praesent mattis  commyolk augue aliquam ornare augue.</p>
									<p>"But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound the actual teachings of the great explorer of the truth, the master-builder of human happiness. No one rejects, dislikes, or avoids pleasure itself, because it is pleasure, but because those who do not know how to pursue pleasure rationally encounter consequences</p>
									<ul className="list-check primary">
										<li>"But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and </li>
										<li>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. </li>
									</ul>
								</TabPane>


								<TabPane tabId="2">
									<table className="table table-bordered" >
										<tr>
											<td>Size</td>
											<td>Small, Medium & Large</td>
										</tr>
										<tr>
											<td>Color</td>
											<td>Pink & White</td>
										</tr>
										<tr>
											<td>Rating</td>
											<td><span className="rating-bx"> <i className="fa fa-star"></i> <i className="fa fa-star"></i> <i className="fa fa-star"></i> <i className="fa fa-star-o"></i> <i className="fa fa-star-o"></i> </span> </td>
										</tr>
										<tr>
											<td>Waist</td>
											<td>26 cm</td>
										</tr>
										<tr>
											<td>Length</td>
											<td>40 cm</td>
										</tr>
										<tr>
											<td>Chest</td>
											<td>33 inches</td>
										</tr>
										<tr>
											<td>Fabric</td>
											<td>Cotton, Silk & Synthetic</td>
										</tr>
										<tr>
											<td>Warranty</td>
											<td>3 Months</td>
										</tr>
										<tr>
											<td>Chest</td>
											<td>33 inches</td>
										</tr>
									</table>
								</TabPane>


								<TabPane tabId="3">
									<div className="col-lg-6 col-md-12 m-b30">
                                        <div className="faq-video">
											<VideoPopup />
											{ /* <a className="play-btn popup-youtube" href="https://www.youtube.com/embed/6lt2JfJdGSY">
                                                <i className="flaticon-play-button text-white"></i></a> */}
                                            <img src={pic10} alt="" className="img-cover radius-sm" />
                                        </div>
                                    </div>
								

									
								</TabPane>	


								<TabPane tabId="4">
								<div className="col-lg-12 col-md-12">
                                    <AccordionBlog /> 
                                </div>
								</TabPane>	


							</TabContent>
						</div>	
					</div>
				</div>		
			
		</Fragment>
	)
	
}	
export default TabStyle1;