import React,{Fragment,  useState, Component} from 'react';
import {Link} from 'react-router-dom';
import Header from './../../Layout/Header1';
import Footer from './../../Layout/Footer1';
import PageTitle from './../../Layout/PageTitle';
// import HomeTab from './../../Element/HomeTab';

// import bnr1 from './../../../images/background/bg1.jpg';
import bnr2 from './../../../images/banner/bnr2.jpg';

import { TabContent, TabPane, } from 'reactstrap';
import classnames from 'classnames';

import test1 from './../../../images/testimonials/pic1.jpg';
import test2 from './../../../images/testimonials/pic2.jpg';
import test3 from './../../../images/testimonials/pic3.jpg';

// import AccordionBlog from './../../Element/AccordionBlog';
// import VideoPopup from './../../Element/VideoPopup';
// import pic10 from './../../../images/about/pic10.jpg';

import PolygonAccordionBlog from './AccordionBlogs/Polygon_AccordionBlog';

const commentBlog = [
	{image: test1, }, {image: test2, }, {image: test3, } 
];  



class ShortTabs extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activeTab: "1"
        };
      }

    render() { 
        return (
            <>
                <Header />

                <div className="page-content bg-white">
                    {/* <!-- inner page banner --> */}
                    <div className="dlab-bnr-inr overlay-primary" style={{ backgroundImage: "url(" + bnr2 + ")" }}>
						<PageTitle motherMenu='Polygon' activeMenu='Polygon' />
                    </div>
                    {/* <!-- inner page banner END --> */}
                    <div className="content-block">
                        {/* <!-- Testimonials --> */}
                        <div className="section-full content-inner">
                            <div className="sort-title-bx" >Polygon Validator <i className="fa fa-angle-double-right"></i></div>
							<div className="container">	
								{/* <TabStyle1 /> */}
                                <Fragment>			
			
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="dlab-tabs product-description tabs-site-button">
                                                    <ul className="nav nav-tabs">
                                                        <li>
                                                            <Link to={"#"} className= {classnames({ active : this.state.activeTab === '1'}) + ''} onClick={() => this.setState({ activeTab: '1' })}><i className="fa fa-globe"></i> Description</Link>
                                                        </li>
                                                        <li>
                                                            <Link to={"#"}	className={classnames({active:this.state.activeTab === '2'}) + ''} onClick={() => this.setState({ activeTab: '2' })}><i className="fa fa-photo"></i> Rewards Calculator</Link>
                                                        </li>
                                                        <li>
                                                            <Link to={"#"}	className={classnames({active:this.state.activeTab === '3'}) + ''} onClick={() => this.setState({ activeTab: '3' })}><i className="fa fa-cog"></i> Video: How to stake?</Link>
                                                        </li>
                                                        <li>
                                                            <Link to={"#"}	className={classnames({active:this.state.activeTab === '4'}) + ''} onClick={() => this.setState({ activeTab: '4' })}><i className="fa fa-cog"></i> FAQs</Link>
                                                        </li>
                                                        
                                                    </ul>
                                                
                                                    <TabContent activeTab={this.state.activeTab}>


                                                        <TabPane tabId="1">
                                                            <p className="m-b10">Suspendisse et justo. Praesent mattis commyolk augue Aliquam ornare hendrerit augue Cras tellus In pulvinar lectus a est Curabitur eget orci Cras laoreet. Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Suspendisse et justo. Praesent mattis  commyolk augue aliquam ornare augue.</p>
                                                            <p>"But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound the actual teachings of the great explorer of the truth, the master-builder of human happiness. No one rejects, dislikes, or avoids pleasure itself, because it is pleasure, but because those who do not know how to pursue pleasure rationally encounter consequences</p>
                                                            <ul className="list-check primary">
                                                                <li>"But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and </li>
                                                                <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. </li>
                                                            </ul>
                                                        </TabPane>


                                                        <TabPane tabId="2">
                                                            <table className="table table-bordered" >
                                                                <tr>
                                                                    <td>Size</td>
                                                                    <td>Small, Medium & Large</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Color</td>
                                                                    <td>Pink & White</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Rating</td>
                                                                    <td><span className="rating-bx"> <i className="fa fa-star"></i> <i className="fa fa-star"></i> <i className="fa fa-star"></i> <i className="fa fa-star-o"></i> <i className="fa fa-star-o"></i> </span> </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Waist</td>
                                                                    <td>26 cm</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Length</td>
                                                                    <td>40 cm</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Chest</td>
                                                                    <td>33 inches</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Fabric</td>
                                                                    <td>Cotton, Silk & Synthetic</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Warranty</td>
                                                                    <td>3 Months</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Chest</td>
                                                                    <td>33 inches</td>
                                                                </tr>
                                                            </table>
                                                        </TabPane>


                                                        <TabPane tabId="3">
                                                            <div className="col-lg-12 col-md-12 m-b30">
                                                                <div className="text-center">
                                                                    {/* <VideoPopup /> */}
                                                                    { /* <a className="play-btn popup-youtube" href="https://www.youtube.com/embed/6lt2JfJdGSY">
                                                                        <i className="flaticon-play-button text-white"></i></a> */}
                                                                    {/* <img src={pic10} alt="" className="img-cover radius-sm" /> */}
                                                                    <iframe 
                                                                        width="800" 
                                                                        height="500" 
                                                                        src="https://www.youtube.com/embed/6JP21I4d6AQ" 
                                                                        title="YouTube video player" 
                                                                        frameborder="0" 
                                                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                                                                        allowfullscreen> 
                                                                    </iframe>
                                                                </div>
                                                            </div>
                                                        

                                                            
                                                        </TabPane>	


                                                        <TabPane tabId="4">
                                                        <div className="col-lg-12 col-md-12">
                                                            <PolygonAccordionBlog /> 
                                                        </div>
                                                        </TabPane>	


                                                    </TabContent>
                                                </div>	
                                            </div>
                                        </div>		
                                    
                                </Fragment>

							</div>	
                        </div>

                    </div>
                    {/* <!-- contact area END --> */}
                </div>

                <Footer />
            </>
        )
    }
}
export default ShortTabs;