import React, { Component } from "react";
import HeaderCompany from "../../Layout/HeaderCompany";
import Footer2 from "../../Layout/footer2";
import { TabContent, TabPane } from "reactstrap";
import { Link } from "react-router-dom";

import InstitutionalsLogo from "../../../images/company/InstitutionalsLogo.png";
import bg11 from "../../../images/background/bg11.jpg";

const whyStakedWithUs = [
  {
    icon: <i className="flaticon-money" />,
    title: "Custody Solutions",
    details: "Partnerships with Professional Custody Providers.",
  },
  {
    icon: <i className="flaticon-bar-chart" />,
    title: "SLA Agreements",
    details: "Fee reduction schedule if SLAs are not met.",
  },
  {
    icon: <i className="flaticon-notebook" />,
    title: "Reseller Staking",
    details: "Reward kickback through your customers. ",
  },
  {
    icon: <i className="flaticon-notebook" />,
    title: "Reward Reportings ",
    details: "Tax relevant documentation of your staking rewards.",
  },
  {
    icon: <i className="flaticon-notebook" />,
    title: "Institutional-grade Infrastrucutre",
    details: "Optimized for high security, redundancy, & availability.",
  },
  {
    icon: <i className="flaticon-notebook" />,
    title: "Whitelabel Validator Solutions",
    details: "Branded Infrastructure & Validation Services.",
  },
  // { icon: <i className="flaticon-notebook" />, title: 'State-of-the-Art Legal Framework', details: 'Individually adjustable and catered to your needs.' },
  // { icon: <i className="flaticon-notebook" />, title: 'Stable & Safe Jurisdiction', details: 'We are registered and based in Munich, Germany.' },
];

class Institutionals extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: "1",
    };
  }

  render() {
    return (
      <>
        <HeaderCompany />
        <div style={{ fontFamily: "Sarala, sans-serif" }}>
          <div className="row" style={{ backgroundColor: "#092d55" }}>
            <div className="col-lg-5">
              <div
                style={{
                  position: "absolute",
                  top: "40%",
                  left: "20%",
                  fontFamily: "Sarala, sans-serif",
                  color: "white",
                }}
              >
                <p
                  style={{
                    marginBottom: "20px",
                    fontSize: "50px",
                    lineHeight: "55px",
                  }}
                >
                  <b>Institutionals</b>
                </p>
                <p style={{ fontSize: "18px" }}>
                  To best cater to the needs of our institutional clients, we
                  offer custom reward reportings, a state of the art,
                  individually adjustable staking agreement as well as a
                  tailored fee structure.
                </p>
                <p>
                  We have been running highly secure, available, and performant
                  staking infrastructure ever since 2017.
                </p>
              </div>
            </div>
            <div className="col-lg-7">
              <img
                src={InstitutionalsLogo}
                className="w-100"
                alt=""
                style={{ height: "550px", marginTop: "100px" }}
              />
            </div>
          </div>

          <div
            className="section-full content-inner bg-img-fix"
            style={{ backgroundImage: "url(" + bg11 + ")" }}
          >
            <div className="container">
              <div className="row">
                <div className="col-md-12 col-lg-12 text-center section-head">
                  <h1 className="font-weight-700 max-w700 m-auto">
                    Why stake with us?
                  </h1>
                </div>
              </div>
              <div className="row">
                {whyStakedWithUs.map((item, index) => (
                  <div
                    className="col-md-3 col-lg-4 col-sm-6 m-b30"
                    key={index}
                    style={{ height: "300px" }}
                  >
                    <div className="dlab-box" style={{ height: "300px" }}>
                      <div
                        className="dlab-info p-a30 border-1 bg-white"
                        style={{ height: "300px", textAlign: "center" }}
                      >
                        <div className="icon-md text-primary m-b20">
                          {" "}
                          <Link
                            to={"#"}
                            className="icon-cell text-primary"
                            style={{ color: "#f29f05" }}
                          >
                            {item.icon}
                          </Link>{" "}
                          <div></div>
                        </div>
                        <h5 className="dlab-title m-t0">
                          <Link to={"#"}>{item.title}</Link>
                        </h5>
                        <p className="m-b15">{item.details}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <Footer2 />
      </>
    );
  }
}
export default Institutionals;
