import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import { Grid, Typography } from "@mui/material";
import moment from "moment";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { useParams } from "react-router";
import Paper from "@mui/material/Paper";

function TransactionInfo(props) {
  const [rows, setRows] = useState([]);
  const [validator, setValidator] = useState([]);
  const [nodeID, setNodeId] = useState("");
  const columns = [
    { field: "Beneficiary", accessor: "benificiary" },
    { field: "Delegated", accessor: "delegated" },
    { field: "PotentialReward", accessor: "potentialreward" },
    { field: "StartedOn", accessor: "startedon" },
    { field: "TimeLeft", accessor: "timeleft" },
  ];

  useEffect(() => {}, [rows.nodeId]);

  useEffect(() => {
    /* eslint-disable react/prop-types */
    const { validator } = props; /* eslint-disable react/prop-types */
    console.log("props props useEffect");
    console.log(validator);
    setRows(validator);

    const valArr = [];
    valArr.push({
      txId: validator.txId,
      startTime: validator.startTime,
      endTime: validator.endTime,
      stakeAmount: validator.stakeAmount,
      // timeleft: 1,
    });
    setValidator(valArr);

    // console.log("aaaaaa2", validator);
  }, [props]);
  return (
    // <DashboardLayout>
    // <React.Fragment>
    <div>
      <div
        style={{
          width: "1460px",
          marginLeft: "-140px",
          marginTop: "20px",
        }}
      >
        <Box
        // mx={2}
        // mt={-2}
        // py={3}
        // px={1}
        // variant="gradient"
        // bgColor="info"
        // borderRadius="lg"
        // coloredShadow="info"
        >
          <Typography variant="h6">Delegations</Typography>
        </Box>

        <TableContainer style={{ borderBottom: "3px solid black" }}>
          <Table style={{ width: "100%" }} aria-label="simple table">
            <TableHead>
              <TableRow
                style={{
                  borderBottom: "2px solid black",
                }}
              >
                <TableCell>BENEFICIARY</TableCell>
                <TableCell sortDirection={"true"}>DELEGATED</TableCell>
                <TableCell>POTENTIAL REWARDS</TableCell>
                <TableCell>STARTED ON</TableCell>
                <TableCell>TIME LEFT</TableCell>
              </TableRow>
            </TableHead>
            <TableBody style={{ textAlign: "center" }}>
              {rows &&
                rows.delegators &&
                rows.delegators.map((row) => (
                  // <a href="/delegation-info"></a>
                  <TableRow
                    // style={{ borderBottom: "1px solid black" }}
                    key={row.benificiary}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {console.log("rows rows rows rows rows from return")}
                      {console.log(rows)}
                      <a
                        href={
                          "/delegation-info?validator=AVAX&nodeId=" +
                          rows.nodeID
                        }
                      >
                        {row.rewardOwner.addresses}
                      </a>
                    </TableCell>
                    <TableCell align="center">
                      <a
                        href={
                          "/delegation-info?validator=AVAX&nodeId=" +
                          rows.nodeID
                        }
                      >
                        {row.stakeAmount / 1000000000}{" "}
                        <span style={{ color: "blue" }}>AVAX</span>
                      </a>
                    </TableCell>
                    <TableCell align="center">
                      <a
                        href={
                          "/delegation-info?validator=AVAX&nodeId=" +
                          rows.nodeID
                        }
                      >
                        {row.potentialReward / 1000000000}{" "}
                        <span style={{ color: "blue" }}>AVAX</span>
                      </a>
                    </TableCell>
                    <TableCell align="center">
                      <a
                        href={
                          "/delegation-info?validator=AVAX&nodeId=" +
                          rows.nodeID
                        }
                      >
                        {" "}
                        {moment(new Date(row.startTime * 1000)).format(
                          "MM/DD/YYYY"
                        )}
                        <br />
                        {moment(new Date(row.startTime * 1000)).format(
                          "hh:mm:ss"
                        )}
                      </a>
                    </TableCell>
                    <TableCell align="center">
                      <a
                        href={
                          "/delegation-info?validator=AVAX&nodeId=" +
                          rows.nodeID
                        }
                      >
                        {" "}
                        {moment(new Date(row.endTime * 1000)).format(
                          "MM/DD/YYYY"
                        )}
                        <br />
                        {moment(new Date(row.endTime * 1000)).format(
                          "hh:mm:ss"
                        )}
                      </a>
                    </TableCell>
                  </TableRow>
                ))}

              {/* <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row" colSpan={5}>
                  <Grid container style={{ marginTop: "15px" }}>
                    <Grid xs={5} />
                    <Grid xs={6} style={{ textAlign: "center" }}>
                      <div style={{ float: "left" }}>
                        {rows && rows.delegators ? null : (
                          <ReportGmailerrorredOutlinedIcon />
                        )}
                      </div>
                      <div
                        style={{
                          float: "left",
                          marginTop: "2px",
                          marginLeft: "5px",
                        }}
                      >
                        {rows && rows.delegators ? null : "Nothing to see here"}
                      </div>
                    </Grid>
                  </Grid>
                </TableCell>
              </TableRow> */}

              {/* </TableCell>
              </TableRow> */}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <br />
      <br />

      <div
        style={{
          width: "1460px",
          marginLeft: "-140px",
          marginTop: "20px",
        }}
      >
        <Box
        // mx={2}
        // mt={-2}
        // py={3}
        // px={1}
        // variant="gradient"
        // bgColor="info"
        // borderRadius="lg"
        // coloredShadow="info"
        >
          <Typography variant="h6">Validations</Typography>
        </Box>

        <TableContainer style={{ borderBottom: "3px solid black" }}>
          <Table style={{ width: "100%" }} aria-label="simple table">
            <TableHead>
              <TableRow style={{ borderBottom: "2px solid black" }}>
                <TableCell>TX ID</TableCell>
                <TableCell>START DATE</TableCell>
                <TableCell>END DATE</TableCell>
                {/* <TableCell align="left">DURATION</TableCell> */}
                <TableCell>VALIDATOR STAKE</TableCell>
              </TableRow>
            </TableHead>
            <TableBody style={{ marginBottom: "1px solid black" }}>
              {validator &&
                validator.map((row) => (
                  <TableRow
                    key={row.txId}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.txId}
                    </TableCell>

                    <TableCell align="left">
                      {" "}
                      {moment(new Date(row.startTime * 1000)).format(
                        "MMMM  DD, YYYY  hh : mm"
                      )}
                      <span>GMT</span>
                      <br />
                      {/* {moment(new Date(row.endTime * 1000)).format("hh:mm:ss")} */}
                    </TableCell>
                    {/* <TableCell align="center">{row.timeLeft}</TableCell> */}

                    <TableCell align="left">
                      {moment(new Date(row.endTime * 1000)).format(
                        "MMMM  DD, YYYY  hh : mm"
                      )}
                      <span>GMT</span>
                      <br />
                      {/* {moment(new Date(row.endTime * 1000)).format("hh:mm:ss")} */}
                    </TableCell>
                    <TableCell align="left">
                      {row.stakeAmount / 1000000000} <span>AVAX</span>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
    // </React.Fragment>
    // </DashboardLayout>
  );
}

export default TransactionInfo;
