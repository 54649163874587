import React, { Component } from "react";
import { Link } from "react-router-dom";
import Avalanche_logo from "../../images/blockchain_logos/MaskAvax.png";
import Ethereum_logo from "../../images/blockchain_logos/Maskgroup.png";
import Polkadot_logo from "../../images/blockchain_logos/MaskPolkadot.png";
import Grid from "@mui/material/Grid";
import logos1 from "../../images/logos1.png";
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";
import mp1 from "./../../images/map1.png";
import blog1 from "./../../images/blog/recent-blog/pic1.jpg";
import blog2 from "./../../images/blog/recent-blog/pic2.jpg";
import blog3 from "./../../images/blog/recent-blog/pic3.jpg";
import gallery1 from "./../../images/gallery/pic1.jpg";
import gallery2 from "./../../images/gallery/pic2.jpg";
import gallery3 from "./../../images/gallery/pic3.jpg";
import gallery4 from "./../../images/gallery/pic4.jpg";
import gallery5 from "./../../images/gallery/pic5.jpg";
import gallery6 from "./../../images/gallery/pic6.jpg";
import gallery7 from "./../../images/gallery/pic7.jpg";
import gallery8 from "./../../images/gallery/pic8.jpg";
import gallery9 from "./../../images/gallery/pic9.jpg";

class Footer2 extends Component {
  render() {
    return (
      <>
        <footer className="site-footer text-white">
          <div
            className="footer-top bg-primary"
            style={{
              backgroundColor: "#01093A",
              fontFamily: "Gilroy-Medium",
              // height: "300px",
              // width: "1738px",
            }}
          >
            <div className="container">
              <div className="row">
                {/* <div className="col-lg-2" /> */}
                <div className="col-lg-4">
                  <div
                    className="logo"
                    style={{ maxWidth: "200px", width: "150px" }}
                  >
                    <img src={logos1} alt="" />
                  </div>
                  {/* <div className="row">
                    <div className="col-lg-12"> */}
                  <p style={{ marginTop: "20px" }}>
                    The leading investors in crypto trust Staked to deliver
                    <br /> the optimal staking rewards reliably and securely{" "}
                    <br /> across the broadest range of assets.
                  </p>
                  {/* </div>
                  </div> */}
                </div>
                <div className="col-lg-4">
                  <h4
                    style={{
                      color: "#0CF0B4",
                      fontFamily: "Gilroy-SemiBold",
                      fontSize: "18px",
                      marginLeft: "130px",
                      lineHeight: "26px",
                    }}
                  >
                    STAKE NETWORKS
                  </h4>
                  <div className="row">
                    <div className="col-lg-2" style={{ marginTop: "10px" }}>
                      <img
                        style={{
                          height: "20px",
                          weight: "20px",
                          marginTop: "-20px",
                          marginLeft: "130px",
                          // marginLeft: "40px",
                        }}
                        src={Ethereum_logo}
                        alt=""
                      />
                    </div>
                    <div className="col-lg-10">
                      <Link style={{ marginLeft: "110px" }} to={"/Ethereum"}>
                        Stake Ethereum 2.0 (ETH)
                      </Link>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-2" style={{ marginTop: "10px" }}>
                      <img
                        style={{
                          height: "20px",
                          weight: "20px",
                          marginLeft: "130px",
                          marginTop: "-20px",
                        }}
                        src={Polkadot_logo}
                        alt=""
                      />
                    </div>
                    <div className="col-lg-10">
                      <Link style={{ marginLeft: "110px" }} to={"/Polkadot"}>
                        Stake Polkadot (DOT)
                      </Link>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-2" style={{ marginTop: "10px" }}>
                      <img
                        style={{
                          height: "20px",
                          weight: "20px",
                          marginTop: "-20px",
                          marginLeft: "130px",
                        }}
                        src={Avalanche_logo}
                        alt=""
                      />
                    </div>
                    <div className="col-lg-10">
                      <Link style={{ marginLeft: "110px" }} to={"/Avalanche"}>
                        Stake Avalanche (AVAX)
                      </Link>
                    </div>
                  </div>
                </div>
                {/* <div className="col-lg-1" /> */}
                <div className="col-lg-4">
                  <h4
                    style={{
                      color: "#0CF0B4",
                      fontFamily: "Gilroy-SemiBold",
                      fontSize: "18px",
                      lineHeight: "26px",
                      marginLeft: "150px",
                    }}
                  >
                    COMPANY
                  </h4>
                  <div className="row">
                    <div className="col-lg-12">
                      <Link
                        style={{ marginTop: "10px", marginLeft: "150px" }}
                        to={"/about-us"}
                      >
                        About
                      </Link>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12" style={{ marginLeft: "150px" }}>
                      <Link to={"/news"}>News & Press</Link>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12" style={{ marginLeft: "150px" }}>
                      <Link to={"/careers"}>Careers</Link>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <Link
                        style={{ marginTop: "10px", marginLeft: "150px" }}
                        to={"/about-us"}
                      >
                        Mission
                      </Link>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <Link style={{ marginLeft: "150px" }} to={"/about-us"}>
                        Values
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br />
            <br />
            <div
              className="footer-bottom bg-primary"
              style={{ backgroundColor: "#01093A", borderTop: "1px solid" }}
            >
              <div className="container">
                <div className="row">
                  <div
                    className="col-lg-6 col-md-6 col-sm-6 text-left "
                    style={{ marginLeft: "30px" }}
                  >
                    {" "}
                    <span>Copyright © 2022 Coindelta</span>{" "}
                  </div>
                  <div
                    className="col-lg-6 col-md-6 col-sm-6 text-right"
                    style={{ marginLeft: "-35px" }}
                  >
                    <div className="widget-link ">
                      <ul>
                        <li>
                          <Link to={"/terms"}>Terms of Service</Link>
                        </li>
                        <li>
                          <Link to={"/privacy"}>Privacy Policy</Link>
                        </li>
                        <li>
                          <Link to={"/governance"}>Governance</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </>
    );
  }
}

export default Footer2;
