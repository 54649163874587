import React, { Component } from "react";
// import HeaderCompany from "../../Layout/HeaderCompany";
import Header from "../../Layout/Header1";
import Footer2 from "../../Layout/footer2";
import Footer1 from "../../Layout/Footer1";
import { TabContent, TabPane } from "reactstrap";
import { Link } from "react-router-dom";
import howitWorkLogo from "../../../images/company/howitWorkLogo.png";

//images
import ravi_880_880 from "../../../images/company/ravi_880_880.png";
import darshil_880_880 from "../../../images/company/darshil_880_880.png";
import gaurangsir_880_880 from "../../../images/company/gaurangsir_880_880.png";
import milind_880_880 from "../../../images/company/milind_880_880.png";
import prem_880_880 from "../../../images/company/prem_880_880.png";
import rajesh_880_880 from "../../../images/company/rajesh_880_880.png";
import Shivam_880_880 from "../../../images/company/Shivam_880_880.png";
import shubham_880_880 from "../../../images/company/shubham_880_880.png";
import smit_880_880 from "../../../images/company/smit_880_880.png";
import sweeta from "../../../images/company/sweeta3.png";
import komal from "../../../images/company/komal1.png";
import Aniruddha1 from "../../../images/company/aniruddha2.png";

import OurSustainabilityGoals from "../../../images/company/OurSustainabilityGoals.png";
import OurValidatorInfrastructure from "../../../images/company/OurValidatorInfrastructure.png";
import RaviPandit from "../../../images/company/RaviPandit.jpg";
import darshil_final from "../../../images/company/darshil_final.jpg";
import Gaurang from "../../../images/company/Gaurang.jpg";
import Milind from "../../../images/company/Milind.jpg";
import prem_final from "../../../images/company/prem_final.jpg";
import Ravi_final from "../../../images/company/Ravi_final.jpg";
import ravi_437_437 from "../../../images/company/ravi_437_437.png";

import shubham_final from "../../../images/company/shubham_final.jpg";
import smit_final from "../../../images/company/smit_final.jpg";
import shivam from "../../../images/company/shivam.jpg";

// { image: RaviPandit, name: "Ravi Pandit", desc: "Full Stack Developer", linkedin: "", twitter: "" },
const team = [];

const Team = [
  {
    image: gaurangsir_880_880,
    name: "Gaurang Patel",
    desc: "Director of Engineering  ",
    Bio: "Gaurang Patel is the Director of Engineering for CoinDelta and has more than fifteen years of experience in fintech and other domains. He has deep expertise in various technologies such as JAVA, Blockchain, React, React Native, MongoDB etc. Gaurang has spent the last few years building CoinDelta and prior to that he was building Qualtab and other startups in India. Gaurang started writing software at an early age while his primary focus today is on technical management and talent development, he still writes code."
  },
  {
    image: darshil_880_880,
    name: "Darshil Darji",
    desc: "Blockchain Developer",
    Bio: "Darshil Darji is a Blockchain Developer for CoinDelta. He has experience in various technologies such as Blockchain, Staking, React, React Native, Solidity etc. Darshil likes to play cricket in his spare time and enjoys traveling."
  },
  {
    image: Shivam_880_880,
    name: "Shivam Sharma",
    desc: "Blockchain Developer",
    Bio: "Shivam Sharma is a Blockchain Developer for CoinDelta. He has experience in various technologies such as XRP Ledger, AngularJS, Solidity, Staking, NodeJS, React, etc. Shivam likes to read about new technologies and geo-politics and also loves to ride bikes."
  },
  {
    image: komal,
    name: "Komal Trivedi",
    desc: "Blockchain Developer",
    Bio: "Komal Trivedi is a Senior Software Developer for CoinDelta and has more than twelve years of experience in the Financial Services industry. She has experience in JAVA, Spring, Spring Boot, JPA and JAVA technologies. Komal likes to read books and listen to music in her spare time."
  },
  {
    image: sweeta,
    name: "Sweta Kumari",
    desc: "Blockchain Developer",
    Bio: "Sweta Kumari is a Senior Blockchain Developer for CoinDelta and has more than seven years of experience in the blockchain industry. She has experience in various blockchain technologies like Bitcoin, Ethereum, Ripple, Tron, Cosmos etc. In the past she has worked with a cryptocurrency exchange for multi-crypto wallet development. Sweta likes to read books in spare time and enjoys hiking."
  },
  {
    image: ravi_880_880,
    name: "Ravi Pandit",
    desc: "Full Stack Developer",
    Bio: "Ravi Pandit is a Full Stack Developer for Coindelta and has more than five years of experience in the startup space. He has experience in various technologies such as JAVA, React, React Native, MongoDB, Postgresql etc. Ravi likes to play games in spare time and enjoys hiking."
  },
  {
    image: smit_880_880,
    name: "Smit Patel",
    desc: "Software Engineer",
    Bio: "Smit Patel is a Software Developer for Coindelta and has a few years of experience in helping build products for startups. He has experience in various technologies such as JAVA, Node, React, React Native, AWS, Flutter, IOS, etc. Smit likes to play bowling in his spare time and enjoys trekking."
  },
  {
    image: shubham_880_880,
    name: "Shubham Godbole",
    desc: "Software Engineer",
    Bio: "Shubham Godbole is a Software Developer for Coindelta and has a few years of experience in helping build products for startups. He has experience in various technologies such as JAVA, Node, React, React Native, AWS, Flutter, IOS, etc. Shubham likes to learn new technologies and attend technical seminars etc."
  },
  {
    image: prem_880_880,
    name: "Prem Mithawala",
    desc: "Software Engineer",
    Bio: "Prem Mithawala is a Software Developer for Coindelta and has a few years of experience in helping build products for startups. He has experience in various technologies such as PHP, Laravel, Node, React, React Native, AWS, Flutter, IOS, etc. Prem likes to read books in spare time and enjoys playing games."
  },
  {
    image: milind_880_880,
    name: "Milind Barot",
    desc: "Frontend Developer",
    Bio: "Milind Barot is a Frontend Developer for CoinDelta. He has experience in various technologies such as JavaScript, HTML, CSS, NodeJS, ReactJS, .Net, PHP. Milind likes to learn new things in his spare time and enjoys traveling."
  },
  {
    image: rajesh_880_880,
    name: "Rajesh Badak",
    desc: "Software Engineer ",
    Bio: "Rajesh Badak is an AWS/DevOps Engineer for CoinDelta. He has experience in various tools such as Git, Terraform, Jenkins, Shell scripting. Rajesh likes to read books in his spare time and plays chess."
  },
  {
    image: Aniruddha1,
    name: "Aniruddha Kulkarni",
    desc: "AWS/DevOps Engineer",
    Bio: "Aniruddha Kulkarni is an AWS/DevOps Engineer for CoinDelta. He has experience in various tools such as Terraform, GIT, Docker, Kubernetes, Shell scripting. Aniruddha likes to learn new things in his spare time and enjoys traveling, trekking and camping."
  },
];

class AboutUs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: "1",
    };
  }

  render() {
    return (
      <>
        {/* <HeaderCompany /> */}
        <Header />
        <div style={{ fontFamily: "Sarala, sans-serif" }}>
          <div className="row" style={{ backgroundColor: "#092d55" }}>
            <div className="col-lg-5">
              <div
                style={{
                  position: "absolute",
                  top: "40%",
                  left: "20%",
                  fontFamily: "Sarala, sans-serif",
                  color: "white",
                }}
              >
                <p
                  style={{
                    marginBottom: "20px",
                    fontSize: "50px",
                    lineHeight: "55px",
                    fontFamily: "Helvetica Neue"
                  }}
                >
                  <b>About Us</b>
                </p>
                <p style={{ fontSize: "18px",fontFamily: "Helvetica Neue" }}>
                  Get to know our team, our story, and our mission
                </p>
              </div>
            </div>
            <div className="col-lg-7">
              <img
                src={howitWorkLogo}
                className="w-100"
                alt=""
                style={{ height: "400px", marginTop: "100px" }}
              />
            </div>
          </div>
          <div className="row" style={{ marginTop: "40px" }}>
            <div className="col-lg-2" />
            <div
              className="col-lg-8"
              style={{
                textAlign: "center",
                color: "black",
                fontFamily: "Helvetica Neue",
              }}
            >
              <h1 style={{ color: "black", fontFamily: "Helvetica Neue" }}>
                Our Mission
              </h1>
              <p style={{ fontSize: "22px" }}>
                We strive to develop industry standards for convenient staking
                with compelling project selection while retaining outstanding
                security standards
              </p>
            </div>
          </div>
          {/* <div className="row" style={{ marginTop: "40px" }}>
                        <div className="col-lg-2" />
                        <div className="col-lg-4" style={{ textAlign: "center", paddingRight: "0px" }}>
                            <img src={OurValidatorInfrastructure} className="w-100" alt="" style={{ borderRadius: "10px" }} />
                        </div>
                        <div className="col-lg-4" style={{ textAlign: "center", backgroundColor: "white", paddingTop: "20px", borderRadius: "10px", marginTop: "35px", border: "1px solid #f29f05", marginBottom: "20px" }}>
                            <p style={{ fontSize: "30px" }}><b>Web 3.0, the next Evolution of the Internet</b></p>
                            <p style={{ marginBottom: "5px" }}>We envision the transition to an economic system that is natively digital, inclusive and secure. We believe Web 3.0 technology is the cornerstone of such a transition and that staking will secure the worlds most precious digital public infrastructures.</p>
                            <a href="/security">Security</a>
                        </div>
                        <div className="col-lg-2" />
                    </div> */}

          {/* <div className="row" style={{ marginTop: "40px" }}>
            <div className="col-lg-2" />
            <div
              className="col-lg-4"
              style={{
                textAlign: "center",
                backgroundColor: "white",
                padding: "20px",
                borderRadius: "10px",
                marginTop: "35px",
                border: "1px solid #f29f05",
                marginBottom: "40px",
              }}
            >
              <p style={{ fontSize: "30px" }}>
                <b>Sustainable Execution of Web 3.0</b>
              </p>
              <p style={{ marginBottom: "5px" }}>
                We work every day to make Web 3.0 a reality. Based in Munich,
                Germany, we run enterprise-grade validation services and operate
                nodes for public Proof-of-Stake blockchain networks. We are
                committing ourselves to running this new generation of
                infrastructure as sustainable as possible.
              </p>
              <a href="/sustainability">Sustainability</a>
            </div>
            <div
              className="col-lg-4"
              style={{ textAlign: "center", paddingLeft: "0px" }}
            >
              <img
                src={OurSustainabilityGoals}
                className="w-100"
                alt=""
                style={{ borderRadius: "10px" }}
              />
            </div>
            <div className="col-lg-2" />
          </div> */}
          <div className="row" style={{ marginTop: "40px" }}>
            <div className="col-lg-2" />
            <div
              className="col-lg-8"
              style={{
                textAlign: "center",
                marginTop: "30px",
                // backgroundColor: "white",
                padding: "20px",
                borderRadius: "10px",
              }}
            >
              <h1
                style={{
                  marginTop: "30px",
                  color: "black",
                  fontFamily: "Helvetica Neue",
                }}
              >
                Our Team
              </h1>
              <p
                style={{
                  fontSize: "22px",
                  color: "black",
                  fontFamily: "Helvetica Neue",
                }}
              >
                We are a team of doers, tinkerers, and technology enthusiasts.
                We have a wide variety of backgrounds such as political science,
                software development, UX design, business and system
                engineering. We love building and operating at the cutting edge
                of technology. In this blog post, we introduce each of our
                awesome team members in more detail - come check it out!
              </p>

              {/* <div className="row" style={{ marginTop: "40px" }}>
                {team &&
                  team.map((user) => {
                    return (
                      <div className="col-lg-4">
                        <img
                          src={user.image}
                          className="w-100"
                          alt=""
                          style={{ borderRadius: "10px" }}
                        />
                        <p
                          style={{
                            marginBottom: "1px",
                            fontSize: "22px",
                            color: "black",
                            marginTop: "5px",
                          }}
                        >
                          <b>{user.name}</b>
                        </p>
                        <p style={{ marginBottom: "1px", fontSize: "18px" }}>
                          {" "}
                          {user.desc}
                        </p>
                        <ul className="list-inline m-a0">
                          <li>
                            <Link
                              to={user.linkedin ? user.linkedin : "#"}
                              className="site-button linkedin circle mr-1"
                            >
                              <i className="fa fa-linkedin"></i>
                            </Link>
                          </li>
                          <li>
                            <Link
                              to={user.twitter ? user.twitter : "#"}
                              className="site-button twitter circle mr-1"
                            >
                              <i className="fa fa-twitter"></i>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    );
                  })} */}
              {/* <div className="col-lg-4" >
                                    <img src={RaviPandit} className="w-100" alt="" style={{ borderRadius: "10px" }} />
                                    <p style={{ marginBottom: "1px", fontSize: "22px", color: "black", marginTop: "5px" }}><b>Ravi Pandit</b></p>
                                    <p style={{ marginBottom: "1px", fontSize: "18px" }}> Full Stack Developer</p>
                                    <ul className="list-inline m-a0">
                                        <li><Link to={"#"} className="site-button linkedin circle mr-1"><i className="fa fa-linkedin"></i></Link></li>
                                        <li><Link to={"#"} className="site-button twitter circle mr-1"><i className="fa fa-twitter"></i></Link></li>
                                    </ul>
                                </div> */}
              {/* </div> */}
              <br />
              <br />
            </div>
            <div className="col-lg-2" />
          </div>

          <div className="container">
            <div
              className="row"
              style={{ marginLeft: "10px", marginRight: "10px" }}
            >
              {Team.map((item, index) => (
                // <div>
                <div className="col-lg-4" key={index}>
                  <div className="dlab-box">
                    <div className="dlab-info p-a5">
                      <div className="text-primary m-b30">
                        <img
                          src={item.image}
                          // style={{ height: "437px", width: "880px" }}
                        />
                        <div></div>
                      </div>
                      <h1
                        className="dlab-title m-t0"
                        style={{ fontFamily: "Helvetica Neue" }}
                      >
                        <p style={{ fontSize: "33px" }} className="m-b15">
                          {item.name}
                        </p>
                      </h1>
                      <h5
                        className="dlab-title m-t0"
                        style={{ fontFamily: "Helvetica Neue" }}
                      >
                        <p className="m-b15">{item.desc}</p>
                      </h5>
                      <p
                        className="m-b15"
                        style={{ fontFamily: "Helvetica Neue",textAlign:"justify" }}
                      >
                        {item.Bio}
                      </p>
                    </div>
                  </div>
                  <br />
                  <br />
                  <br />
                </div>
                // </div>
              ))}
            </div>
          </div>
        </div>
        {/* <br />
        <br />
        <br /> */}
        <Footer1 />
      </>
    );
  }
}
export default AboutUs;
