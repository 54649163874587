import React, { Component } from "react";
import { Link } from "react-router-dom";
import logo from "./../../images/logo.png";
import logos from "./../../images/logos.png";
import Logoo from "./../../images/Logoo.png";
import logos1 from "./../../images/logos1.png";
import logo2 from "./../../images/logo-black.png";
import HeaderMenu from "./HeaderMenu";

class Header1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: 0,
    };
    this.handleScroll = this.handleScroll.bind(this);
  }

  toggleFunc() {
    var sidebarmenu = document.querySelector(".myNavbar ");
    sidebarmenu.classList.toggle("show");
    //   Navicon.classList.toggle('open');
  }
  componentDidMount() {
    // sidebar open/close
    window.addEventListener("scroll", this.handleScroll);
    var Navicon = document.querySelector(".navicon");

    Navicon.addEventListener("click", this.toggleFunc);

    // Sidenav li open close
    var navUl = [].slice.call(
      document.querySelectorAll(".navbar-nav > li > a, .sub-menu > li > a")
    );
    for (var y = 0; y < navUl.length; y++) {
      navUl[y].addEventListener("click", function () {
        checkLi(this);
      });
    }

    function checkLi(current) {
      current.parentElement.parentElement
        .querySelectorAll("li")
        .forEach((el) =>
          current.parentElement !== el ? el.classList.remove("open") : ""
        );

      setTimeout(() => {
        current.parentElement.classList.toggle("open");
      }, 100);
    }
  }

  handleScroll() {
    const { pageYOffset } = window;
    const { active } = this.state;
    if (pageYOffset >= 10 && active === 0) {
      this.setState({ active: 1 });
    } else if (pageYOffset < 50 && active === 1) {
      this.setState({ active: 0 });
    }
  }
  render() {
    console.log("this.state.active", this.state.active);
    return (
      <>
        <header
          className="site-header header-transparent mo-left"
          id="fix-header"
          style={{ backgroundColor: "white" }}
        >
          {/* <div className="top-bar">
                        <div className="container">
                            <div className="row d-flex justify-content-between">
                                
                                <div className="dlab-topbar-right">
                                    <ul>
                                        <li><i className="ti-skype m-r5"></i> IvanStake</li>
                                        <li><i className="ti-email m-r5"></i> contact@ivan-stake.com</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div> */}

          <div className="sticky-header main-bar-wraper navbar-expand-lg">
            <div
              className="main-bar clearfix "
              style={{ boxShadow: "0 10px 20px 0 rgb(0 0 0 / 20%)" }}
            >
              <div className="container clearfix">
                <div
                  className="logo-header mostion"
                  style={{ maxWidth: "200px", width: "150px" }}
                >
                  <Link
                    to={"./"}
                    className="dez-page"
                    style={{ fontSize: "20px", color: "white" }}
                  >
                    <img
                      src={this.state.active === 0 ? Logoo : logos1}
                      alt=""
                      style={{ marginLeft: "40px" }}
                    />
                  </Link>
                </div>

                <button
                  className="navbar-toggler collapsed navicon justify-content-end"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarNavDropdown"
                  aria-controls="navbarNavDropdown"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span></span>
                  <span></span>
                  <span></span>
                </button>

                {/* <div className="extra-nav">
                                    <div className="extra-cell">
                                        <Link to={'./contact'} className="dez-page site-button primary">Inquire Now </Link>
                                    </div>
                                </div> */}

                <div
                  className="header-nav navbar-collapse collapse myNavbar justify-content-end"
                  id="navbarNavDropdown"
                  style={{
                    backgroundColor: this.state.active === 0 ? "" : "#01093A",
                  }}
                >
                  <div className="logo-header mostion d-md-block d-lg-none">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      {/* <Link to={"./"} className="dez-page">
                        <p style={{ color: "black", fontSize: "22px" }}>
                          Coindelta
                        </p>
                      </Link> */}
                      <Link to={"./"} className="dez-page">
                        <img
                          src={this.state.active === 0 ? Logoo : logos1}
                          alt=""
                        />
                      </Link>
                      <p
                        onClick={() => {
                          this.toggleFunc();
                        }}
                        style={{
                          color: this.state.active === 0 ? "#01093A" : "#FFF",
                        }}
                      >
                        X
                      </p>
                    </div>
                  </div>
                  {/*  Header Menu Contents  */}
                  <HeaderMenu props={this.props} />
                  {/*  Header Menu Contents End */}
                </div>
              </div>
            </div>
          </div>
        </header>
      </>
    );
  }
}

export default Header1;
