import React, { Component } from "react";
import { Link } from "react-router-dom";

class HeaderMenu extends Component {
  constructor(props) {
    super(props);
  }

  handleLogout() {
    // console.log(this.props);
    sessionStorage.removeItem("access_token");
    sessionStorage.removeItem("refresh_token");
    sessionStorage.removeItem("email");
    sessionStorage.removeItem("subId");
    // this.props.history.push("/");
    window.location.reload();
  }

  render() {
    return (
      <>
        <ul className="nav navbar-nav">
          <li>
            {/* <Link to={"#"}>
              Lern More <i className="fa fa-chevron-down"></i>{" "}
            </Link> */}
            {/* <ul className="sub-menu left"> */}
            {/* <li>
              <Link to={"./how-it-works"}>How it Works</Link>
            </li> */}
            {/* <li>
              <Link to={"./how-it-works"}>How it Works</Link>
            </li> */}
            {/* <li>
              <Link to={"./about-us"}>About us</Link>
            </li> */}
            {/* </ul> */}
          </li>
          {/* {/* <li><Link to={'./security'}>Security</Link></li> */}
          <li>
            <Link to={"./about-us"}>About us</Link>
          </li>
          <li>
            <Link to={"./how-it-works"}>How it Works</Link>
          </li>

          {/* {sessionStorage.getItem("access_token") ?
						""
						: <li><a href='/login'>Login</a></li>}
					{sessionStorage.getItem("access_token") ?
						""
						: <li><a href='/register'>Sign Up</a></li>}
					{sessionStorage.getItem("access_token") ?
						<li><a href='#'>{sessionStorage.getItem("email")}</a></li>
						: ""}
					{sessionStorage.getItem("access_token") ?
						<li><a href='#' onClick={() => this.handleLogout()}>Logout</a></li>
						: ""} */}
          {/* <li><Link to={''}>Our Portfolio <i className="fa fa-chevron-down"></i></Link>
						<ul className="sub-menu left">
							<li><Link to={'./portfolio-full-width'} className="dez-page">Portfolio Full Width  </Link></li>
							<li><Link to={'./portfolio-grid-2'} className="dez-page">Portfolio Grid 2 </Link></li>
							<li><Link to={'./portfolio-grid-3'} className="dez-page">Portfolio Grid 3 </Link></li>
							<li><Link to={'./portfolio-grid-4'} className="dez-page">Portfolio Grid 4 </Link></li>
						</ul>
					</li> */}
          {/* <li><Link to={'#'}>Shop <i className="fa fa-chevron-down"></i></Link>
						<ul className="sub-menu left">
							<li><Link to={'./shop-columns'} className="dez-page">Shop Columns</Link></li>
							<li><Link to={'./shop-columns-sidebar'} className="dez-page">Shop Columns Sidebar</Link></li>
							<li><Link to={'./shop-product-details'} className="dez-page">Product Details</Link></li>
							<li><Link to={'./shop-cart'} className="dez-page">Cart</Link></li>
							<li><Link to={'./shop-checkout'} className="dez-page">Checkout</Link></li>
							<li><Link to={'./shop-wishlist'} className="dez-page">Wishlist</Link></li>
							<li><Link to={'./shop-login'} className="dez-page">Shop Login</Link></li>
							<li><Link to={'./shop-register'} className="dez-page">Shop Register</Link></li>
						</ul>
					</li> */}
        </ul>
      </>
    );
  }
}
export default HeaderMenu;
