import React, { useState, useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import moment from "moment";
import questionmark from "../../../images/questionmark.svg";
import Badge from "@mui/material/Badge";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import Header from "../../Layout/Header1";
import Footer from "../../Layout/Footer1";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import NodeInfoService from "../../../Service/NodeInfoService";
///delegation-info
function DelegationInfo(props) {
  const [rows, setRows] = useState([]);
  const [validator, setValidator] = useState([]);
  const nodeInfoService = new NodeInfoService();
  const columns = [
    { field: "DELEGATED NODE", accessor: "delegatednode" },
    { field: "ADD DELEGATOR TX", accessor: "AddDelegatorTx" },
    { field: "ADDRESS", accessor: "Address" },
    { field: "DELEGATED", accessor: "Delegated" },
    { field: "START TIME", accessor: "StartTime" },
    { field: "END TIME", accessor: "EndTime" },
    { field: "GROSS REWARDS", accessor: "GrossRewards" },
    { field: "DELEGATION FEES", accessor: "DeletaionFees" },
  ];

  useEffect(() => {
    /* eslint-disable react/prop-types */
    // const { validator } = props; /* eslint-disable react/prop-types */
    // console.log("props props");
    // console.log(validator);
    // setRows(validator);

    // const valArr = [];
    // valArr.push({
    //   txID: validator.txID,
    //   startTime: validator.startTime,
    //   endTime: validator.endTime,
    //   stakeAmount: validator.stakeAmount,
    //   // timeleft: 1,
    // });
    // setValidator(valArr);
    const info = nodeInfoService.getNodeInfo().then((res) => {
      console.log("res res res", res);
      console.log("json value of getNodeInfo");
      console.log(res);
      // this.setState({ data: res });
      setRows(res.body.result.validators[0]);

      //   const json = Promise.json();
      // if (res) {
      //   console.log("json", res.body.result.validators[0]);
      //   const row = [];
      //   this.setState({
      //     validator: res.body.result.validators[0],
      //     load: false,
      //   });
      // } else {
      //   this.setState({ load: false });
      // }
    }, []);
    console.log("aaaaaa2", validator);
  }, [props]);
  return (
    <>
      {/* <Header /> */}
      <div style={{ backgroundColor: "white" }}>
        <h1 style={{ marginTop: "100px", marginLeft: "40px" }}>Delegation</h1>
        {rows &&
          rows.delegators &&
          rows.delegators.map((row) => (
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Grid container spacing={2}>
                  <Grid item xs={1} />
                  <Grid item xs={3}>
                    <p>DELEGATED NODE</p>
                  </Grid>
                  <Grid item xs={7} style={{ color: "blue", marginTop: "5px" }}>
                    {row.nodeID}
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={1} />
                  <Grid item xs={3}>
                    <p>ADD DELEGATOR TX</p>
                  </Grid>
                  <Grid item xs={7} style={{ color: "blue", marginTop: "5px" }}>
                    {row.txID}
                  </Grid>
                </Grid>
                <div
                  style={{
                    border: "1px solid black",
                    borderRadius: "8px",
                    padding: "1rem",
                    position: "relative",
                    backgroundColor: "#F2F2FA",
                    marginLeft: "50px",
                    marginTop: "50px",
                    height: "100px",
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={7}>
                      <h5 style={{ color: "blue" }}>BENEFICIARY</h5>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={1} />
                    <Grid item xs={2}>
                      <p>ADDRESS</p>
                    </Grid>
                    <Grid
                      item
                      xs={9}
                      style={{ color: "blue", marginTop: "5px" }}
                    >
                      {row.rewardOwner.addresses}
                    </Grid>
                  </Grid>
                </div>
                <div
                  style={{
                    border: "1px solid black",
                    borderRadius: "8px",
                    padding: "1rem",
                    position: "relative",
                    backgroundColor: "#F2F2FA",
                    marginLeft: "50px",
                    marginTop: "10px",
                    height: "100px",
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={7}>
                      <h5 style={{ color: "blue" }}>STAKE</h5>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={1} />
                    <Grid item xs={2}>
                      <p>DELEGATED</p>
                    </Grid>
                    <Grid
                      item
                      xs={9}
                      style={{
                        marginTop: "-5px",
                        fontSize: "25px",
                      }}
                    >
                      {row.stakeAmount / 1000000000}
                      <Badge
                        style={{ marginLeft: "40px" }}
                        color="primary"
                        badgeContent={"AVAX"}
                      />
                    </Grid>
                  </Grid>
                </div>
              </Grid>
              <Grid item xs={6}>
                <Grid container spacing={2}>
                  <Grid item xs={2} />
                  <Grid item xs={2}>
                    <p>STATUS</p>
                  </Grid>
                  <Grid item xs={2} style={{ marginLeft: "-60px" }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 48 48"
                      width="30px"
                      height="30px"
                    >
                      <path
                        fill="#c8e6c9"
                        d="M44,24c0,11.045-8.955,20-20,20S4,35.045,4,24S12.955,4,24,4S44,12.955,44,24z"
                      />
                      <path
                        fill="#4caf50"
                        d="M34.586,14.586l-13.57,13.586l-5.602-5.586l-2.828,2.828l8.434,8.414l16.395-16.414L34.586,14.586z"
                      />
                    </svg>
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    style={{ color: "green", marginLeft: "-90px" }}
                  >
                    <p>ACTIVE</p>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={1} />
                  <Grid item xs={2} style={{ marginLeft: "20px" }}>
                    <p>TIME LEFT</p>
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    style={{ marginTop: "5px", marginLeft: "-30px" }}
                  >
                    {/* {13} */}
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    style={{ marginLeft: "-100px", marginTop: "2px" }}
                  >
                    <p>days</p>
                  </Grid>
                </Grid>
                <div
                  style={{
                    backgroundColor: "#F2F2FA",
                    border: "1px solid black",
                    height: "30px",
                    borderRadius: "8px",
                    position: "relative",
                    marginLeft: "120px",
                    marginRight: "10px",
                    marginTop: "-15px",
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid
                      item
                      xs={3}
                      style={{
                        marginLeft: "15px",
                        // backgroundColor: "green",
                      }}
                    >
                      <p>
                        {moment(new Date(row.startTime * 1000)).format("LL")}
                      </p>
                    </Grid>
                    <Grid item xs={1}>
                      <p style={{ marginLeft: "-40px" }}>
                        {moment(new Date(row.startTime * 1000)).format("hh:mm")}
                      </p>
                    </Grid>
                    <Grid item xs={3} />
                    <Grid item xs={3}>
                      <p style={{ marginLeft: "40px" }}>
                        {moment(new Date(row.endTime * 1000)).format("LL")}
                      </p>
                    </Grid>
                    <Grid item xs={1}>
                      <p>
                        {moment(new Date(row.endTime * 1000)).format("hh:mm")}
                      </p>
                    </Grid>
                  </Grid>
                </div>
                <Grid container spacing={2}>
                  <Grid item xs={2} />
                  <Grid item xs={2} style={{ color: "green" }}>
                    <p>START TIME</p>
                  </Grid>
                  <Grid item xs={6} />
                  <Grid item xs={2}>
                    <p>END TIME</p>
                  </Grid>
                </Grid>
                <div
                  style={{
                    backgroundColor: "#F2F2FA",
                    border: "1px solid black",
                    borderRadius: "8px",
                    position: "relative",
                    padding: "1rem",
                    marginRight: "10px",
                    marginTop: "-18px",
                    height: "240px",
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={3}>
                      <p style={{ color: "blue" }}>POTENTIAL REWARDS</p>
                    </Grid>
                    <Grid item xs={1} style={{ height: "45px", width: "45px" }}>
                      <img
                        style={{ height: "20px", marginLeft: "-10px" }}
                        src={questionmark}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={3} style={{ marginTop: "-15px" }}>
                      <p>GROSS REWARDS</p>
                    </Grid>
                    <Grid item xs={1} style={{ height: "45px", width: "45px" }}>
                      <img
                        style={{
                          height: "20px",
                          marginLeft: "-40px",
                          marginTop: "-30px",
                        }}
                        src={questionmark}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      style={{ marginTop: "-12px", marginLeft: "-70px" }}
                    >
                      {row.potentialReward / 1000000000}
                    </Grid>
                    <Grid item xs={3}>
                      {" "}
                      <Badge
                        style={{ marginLeft: "-50px", marginTop: "-25px" }}
                        color="primary"
                        badgeContent={"AVAX"}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={3} style={{ marginTop: "-15px" }}>
                      <p>DELEGATION FEES</p>
                    </Grid>
                    <Grid item xs={1} style={{ height: "45px", width: "45px" }}>
                      <img
                        style={{
                          height: "20px",
                          marginLeft: "-40px",
                          marginTop: "-30px",
                        }}
                        src={questionmark}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      style={{ marginTop: "-12px", marginLeft: "-70px" }}
                    >
                      {/* {row.delegationFee} */}
                      {"-0.001640946"}
                    </Grid>

                    <Grid item xs={3}>
                      <Badge
                        style={{ marginLeft: "-50px", marginTop: "-25px" }}
                        color="primary"
                        badgeContent={"AVAX"}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid
                      item
                      xs={3}
                      style={{ marginTop: "-10px", marginLeft: "170px" }}
                    >
                      {"2 %"}
                    </Grid>
                  </Grid>
                  <br />
                  <Grid container spacing={2}>
                    <Grid item xs={3} style={{ marginTop: "-15px" }}>
                      <p style={{ marginLeft: "25px" }}>NET REWARDS</p>
                    </Grid>
                    <Grid item xs={1} style={{ height: "45px", width: "45px" }}>
                      <img
                        style={{
                          height: "20px",
                          marginLeft: "-40px",
                          marginTop: "-30px",
                        }}
                        src={questionmark}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      style={{ marginTop: "-12px", marginLeft: "-70px" }}
                    >
                      {"0"}
                    </Grid>
                    <Grid item xs={3}>
                      <Badge
                        style={{ marginLeft: "-50px", marginTop: "-25px" }}
                        color="primary"
                        badgeContent={"AVAX"}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={3} style={{ marginTop: "-15px" }}>
                      <p style={{ marginLeft: "55px" }}>NET YIELD</p>
                    </Grid>
                    <Grid item xs={1} style={{ height: "45px", width: "45px" }}>
                      <img
                        style={{
                          height: "20px",
                          marginLeft: "-40px",
                          marginTop: "-30px",
                        }}
                        src={questionmark}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      style={{ marginTop: "-12px", marginLeft: "-70px" }}
                    >
                      {"0"}
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      style={{
                        marginTop: "-13px",
                        marginLeft: "-70px",
                        color: "blue",
                      }}
                    >
                      <p>%</p>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Grid>
          ))}
        {/* <Footer /> */}
      </div>
    </>
  );
}

export default DelegationInfo;
