import React, { Component } from 'react';
import Header from '../../Layout/Header1';
import Footer from '../../Layout/Footer1';


class Confirmation extends Component {
    render() {
        return (
            <>
                <Header />
                {/* <div className="page-content bg-white">
                    
                </div> */}

                <div className="page-content bg-white">
                    {/* <!-- inner page banner --> */}
                    {/* <div className="dlab-bnr-inr overlay-primary bg-pt" style={{ backgroundImage: "url(" + bnr + ")" }}>
                        <PageTitle motherMenu='Login' activeMenu='Login' />
                    </div> */}
                    {/* <!-- inner page banner END --> */}
                    {/* <!-- contact area --> */}
                    <div className="section-full content-inner shop-account">
                        {/* <!-- Product --> */}
                        <div className="container">
                            <div style={{ width: "100%", textAlign: "center", fontSize: "20px", marginTop: "15%" }}>
                                <p>Please confirm Your mail and then <a href="/login" style={{ color: "blue" }}>Login Here</a></p>
                            </div>
                        </div>
                        {/* <!-- Product END --> */}
                    </div>
                    { /* <-- Icon Blog and Order detail--> */}
                    {/* <OrderDetail /> */}
                    {/* <-- Icon Blog and Order detail End--> */}

                    {/* <!-- contact area  END --> */}
                </div>
            </>
        );
    }
}

export default Confirmation;
