import React, { Fragment, useState, Component, useEffect } from "react";
import { Link } from "react-router-dom";
import Header from "./../../Layout/Header1";
import Footer from "./../../Layout/Footer1";

// AboutNode Array Images
import NominalReward from "../../../images/blockchain_logos/NominalReward.svg";
import StakingComission from "../../../images/blockchain_logos/StakingComission.svg";
import ProtocolCategory from "../../../images/blockchain_logos/ProtocolCategory.svg";
import StakingType from "../../../images/blockchain_logos/StakingType.svg";
import Governance from "../../../images/blockchain_logos/Governance.svg";

import NodePageBanner from "../../../images/blockchain_logos/NodePageBanner.png";
// import Polkadot_logo from "../../../images/blockchain_logos/polkadot.png";
import Polkadot_logo from "../../../images/blockchain_logos/PolkadotLogo.png";
import StakingLogo from "../../../images/blockchain_logos/StakingLogo.svg";
import AddressCopied from "../../../images/blockchain_logos/AddressCopied.png";
import AvaxStaking from "../../../images/blockchain_logos/PolkadotLogo.png";
import NodeDetails from "../../../images/blockchain_logos/Key.svg";
import StakeAvax from "../../../images/blockchain_logos/StakeAvax1.svg";
// import AccordionBlog from './../../Element/AccordionBlog';
// import VideoPopup from './../../Element/VideoPopup';
// import pic10 from './../../../images/about/pic10.jpg';

import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Switch from "@mui/material/Switch";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import copy from "copy-to-clipboard";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@material-ui/core/Slide";
import Paper from "@mui/material/Paper";
import StakingDetails from "../../Component/StakingDetails";
const label = { inputProps: { "aria-label": "Switch demo" } };

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  // console.log(onClose);

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {/* {onClose ? ( */}
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      {/* ) : null} */}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const CustomizedDialogs = (props) => {
  const { isDialogOpen } = props;
  console.log("CustomizedDialogs value is..." + isDialogOpen);
  const [open, setOpen] = React.useState(isDialogOpen);

  React.useEffect(() => {
    const { isDialogOpen } = props;
    setOpen(isDialogOpen);
    console.log("CustomizedDialogs value is..." + open);
  }, [props]);

  const handleClose = () => {
    setOpen(false);
    props.onCloseModel(false);
  };

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
          style={{ textAlign: "center" }}
        >
          <b>Address copied to clipboard</b>
        </BootstrapDialogTitle>
        <DialogContent dividers style={{ textAlign: "center" }}>
          <Typography gutterBottom>Welcome, to the future of money</Typography>
          <Typography gutterBottom>
            <img src={AddressCopied} width={"40%"} />
          </Typography>
          <Typography gutterBottom>
            <p>You can now paste the address in your staking wallet</p>
            <p>Need help? Check out our tutorials</p>
          </Typography>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
};

class ShortTabs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "1",
      amount: 0,
      currencyType: "DOT",
      dailyEarningsDollar: 0.0,
      dailyEarningsDot: 0.0,
      monthlyEarningsDollar: 0.0,
      monthlyEarningsDot: 0.0,
      yearlyEarningsDollar: 0.0,
      yearlyEarningsDot: 0.0,
      compound: false,
      totalAmount: 0.0,
      configCompoundTrue: {
        dailyDoller: 0.0043,
        dailyDot: 0.0004,
        monthlyDoller: 0.13066,
        monthlyDot: 0.01211,
        yearlyDoller: 1.56794,
        yearlyDot: 0.14531,
      },
      configCompoundfalse: {
        dailyDoller: 0.00398,
        dailyDot: 0.00036,
        monthlyDoller: 0.12132,
        monthlyDot: 0.01124,
        yearlyDoller: 1.45578,
        yearlyDot: 0.13492,
      },
      dotPrice: 10.79,
      configUsdCompTrue: {
        dailyDoller: 0.000398,
        dailyDot: 0.000037,
        monthlyDoller: 0.012109,
        monthlyDot: 0.001122,
        yearlyDoller: 0.145314,
        yearlyDot: 0.013467,
      },
      configUsdCompFalse: {
        dailyDoller: 0.00037,
        dailyDot: 0.00003,
        monthlyDoller: 0.01124,
        monthlyDot: 0.00104,
        yearlyDoller: 0.13492,
        yearlyDot: 0.0125,
      },
      modalOpen: false,
      AboutNode: [
        { NodeImg: NominalReward, titles: "14.7", descriptions: "%", content: "Nominal Reward APY" },
        { NodeImg: StakingComission, titles: "8.0", descriptions: "%", content: "Staking Comission" },
        { NodeImg: ProtocolCategory, titles: "Layer 1", content: "Protocol Category" },
        { NodeImg: StakingType,titles: "NPoS", content: "Staking Type" },
        { NodeImg: Governance, titles: "Council Vote", content: "Governance" },
      ],
      Tutorial: [
        { Tuto_title: "Coindelta Staking Tutorial" },
        {
          Tuto_image: AvaxStaking,
          Tuto_title: "How to Stake DOT?",
          Tuto_description: "via Coindelta",
          Tuto_StakeAvax:
            "https://wiki.polkadot.network/docs/maintain-guides-how-to-nominate-polkadot",
        },
        {
          Tuto_image: NodeDetails,
          Tuto_title: "NodeID-16QH9tYa8Som1eHmyiHCnM5MHGV7...",
          Tuto_description: "Validator",
          Tuto_CopyId:
            "NodeID-16QH9tYa8Som1eHmyiHCnM5MHGV7mCRcEuV8nzcgLbG4B2UT",
          // Tuto_Details: "/node-info",
        },
        {
          Tuto_image: StakeAvax,
          Tuto_title: "Stake DOT via Coindelta",
          Tuto_description: "services via Coindelta",
          Tuto_StakeNow:
            "https://wiki.polkadot.network/docs/maintain-guides-how-to-nominate-polkadot",
        },
      ],
      NodeInfo: [
        {
          Node_Title: "Validations",NodeID:"TX ID",StartDate:"START DATE",EndDate:"END DATE",Duration:"DURATION",ValidatorStake:"VALIDATOR STAKE"
        }
      ],
    };
  }

  handleAmountChange(amountValue, currencyType, compound) {
    // const amountValue = event.target.value;
    console.log("event from handleAmountChange..." + amountValue);

    if (this.state.currencyType == "DOT") {
      this.setState({
        currencyType: currencyType,
        compound: compound,
        amount: amountValue,
        totalAmount: amountValue * this.state.dotPrice,
      });
      if (this.state.compound) {
        const configCompound = this.state.configCompoundTrue;

        this.setState({
          dailyEarningsDollar: amountValue * configCompound.dailyDoller,
          dailyEarningsDot: amountValue * configCompound.dailyDot,
          monthlyEarningsDollar: amountValue * configCompound.monthlyDoller,
          monthlyEarningsDot: amountValue * configCompound.monthlyDot,
          yearlyEarningsDollar: amountValue * configCompound.yearlyDoller,
          yearlyEarningsDot: amountValue * configCompound.yearlyDot,
        });
      } else {
        const configCompound = this.state.configCompoundfalse;

        this.setState({
          dailyEarningsDollar: amountValue * configCompound.dailyDoller,
          dailyEarningsDot: amountValue * configCompound.dailyDot,
          monthlyEarningsDollar: amountValue * configCompound.monthlyDoller,
          monthlyEarningsDot: amountValue * configCompound.monthlyDot,
          yearlyEarningsDollar: amountValue * configCompound.yearlyDoller,
          yearlyEarningsDot: amountValue * configCompound.yearlyDot,
        });
      }
    } else if (this.state.currencyType == "USD") {
      this.setState({
        currencyType: currencyType,
        compound: compound,
        amount: amountValue,
        totalAmount: amountValue / this.state.dotPrice,
      });
      if (this.state.compound) {
        const configCompound = this.state.configUsdCompTrue;

        this.setState({
          dailyEarningsDollar: amountValue * configCompound.dailyDoller,
          dailyEarningsDot: amountValue * configCompound.dailyDot,
          monthlyEarningsDollar: amountValue * configCompound.monthlyDoller,
          monthlyEarningsDot: amountValue * configCompound.monthlyDot,
          yearlyEarningsDollar: amountValue * configCompound.yearlyDoller,
          yearlyEarningsDot: amountValue * configCompound.yearlyDot,
        });
      } else {
        const configCompound = this.state.configUsdCompFalse;

        this.setState({
          dailyEarningsDollar: amountValue * configCompound.dailyDoller,
          dailyEarningsDot: amountValue * configCompound.dailyDot,
          monthlyEarningsDollar: amountValue * configCompound.monthlyDoller,
          monthlyEarningsDot: amountValue * configCompound.monthlyDot,
          yearlyEarningsDollar: amountValue * configCompound.yearlyDoller,
          yearlyEarningsDot: amountValue * configCompound.yearlyDot,
        });
      }
    }
  }

  handleCopy(value) {
    copy(value);
  }

  handleClose() {
    this.setState({
      modalOpen: false,
    });
  }

  render() {
    return (
      <>
        <Header />
        <StakingDetails
          title="P O L K A D O T"
          image={Polkadot_logo}
          currency="DOT"
          description="Our Total Stake: "
          description_val=" NaN DOT "
          description_Deli="Nominators: "
          description_Deli_val="NaN"
          AboutNode={this.state.AboutNode}
          Tutorial={this.state.Tutorial}
          NodeInfo={this.state.NodeInfo}
          AvalanchDetails="Polkadot Protocol Details"
          AvalancheProtoDescription="Polkadot is an open-source protocol that focuses on interoperability, security, and, scalability. Its main objective is to provide a platform for independent, unique and heterogeneous blockchains (i.e. Parachains) to connect and share information while providing common security through the Relay Chain - the main chain of the Polkadot network."
        />
        <Footer />
      </>
    );
  }
}
export default ShortTabs;
