import React, { Component } from "react";
import HeaderCompany from "../../Layout/HeaderCompany";
import Footer2 from "../../Layout/footer2";
import { TabContent, TabPane } from "reactstrap";
import { Link } from "react-router-dom";
import howitWorkLogo from "../../../images/company/howitWorkLogo.png";
import ValidatorsRunTheInfrastructure from "../../../images/company/ValidatorsRunTheInfrastructure.png";
import ValidatorsEarnStaking from "../../../images/company/ValidatorsEarnStaking.png";
import DelegatorsContribute from "../../../images/company/DelegatorsContribute.png";
import DelegatorsEarnAReturnonTheirAssets from "../../../images/company/DelegatorsEarnAReturnonTheirAssets.png";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";

import stakingOperations from "../../../images/company/stakingOperations.png";
import StakeYourTokens from "../../../images/company/StakeYourTokens.png";
import ManageYourStaking from "../../../images/company/ManageYourStaking.png";
import StayUpToDate from "../../../images/company/StayUpToDate.png";

class HowItWorks extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: "1",
    };
  }

  render() {
    return (
      <>
        <HeaderCompany />
        <div style={{ fontFamily: "Sarala, sans-serif" }}>
          <div className="row" style={{ backgroundColor: "#092d55" }}>
            <div className="col-lg-5">
              <div
                style={{
                  position: "absolute",
                  top: "40%",
                  left: "20%",
                  fontFamily: "Sarala, sans-serif",
                  color: "white",
                }}
              >
                <p
                  style={{
                    marginBottom: "20px",
                    fontSize: "50px",
                    lineHeight: "55px",
                  }}
                >
                  <b>How it works</b>
                </p>
                <p style={{ fontSize: "18px" }}>
                  In a Proof-of-Stake network, just as in a Proof-of-Work
                  network, the blockchain is maintained over a diverse and
                  geographically distributed network of different computers or
                  servers called “nodes”. The networks allow for a diverse set
                  of entities to participate in their operation and earn rewards
                  for doing so
                </p>
              </div>
            </div>
            <div className="col-lg-7">
              <img
                src={howitWorkLogo}
                className="w-100"
                alt=""
                style={{ height: "550px", marginTop: "100px" }}
              />
            </div>
          </div>
          <div className="row" style={{ marginTop: "40px" }}>
            <div className="col-lg-2" />
            <div className="col-lg-8">
              <h1>What does staking mean?</h1>
              <div className="row" style={{ marginTop: "40px" }}>
                <div className="col-lg-6">
                  <img
                    src={ValidatorsRunTheInfrastructure}
                    className="w-100"
                    alt=""
                  />
                </div>
                <div className="col-lg-6">
                  <div style={{ marginTop: "15%" }}>
                    <p>
                      <b>Validators run the infrastructure</b>
                    </p>
                    <p>
                      Validators, like Staking Facilities, are special nodes
                      responsible for the provision of infrastructure as well as
                      proposing and validating new blocks and appending them to
                      the blockchain. Hence, together with other validators,
                      they ensure the blockchains' security by monitoring its
                      accuracy, establishing validity, guaranteeing
                      availability, and provisioning the infrastructure for it
                      to run on.
                    </p>
                  </div>
                </div>

                <div className="col-lg-6" style={{ marginTop: "15%" }}>
                  <div style={{ marginTop: "15%" }}>
                    <p>
                      <b>Validators earn staking rewards & fees</b>
                    </p>
                    <p>
                      For their work, validators are rewarded in the form of
                      block rewards & transaction fees. In order to participate
                      in securing the network and to be paid for this service,
                      validators are required to lock up collateral “stake”
                      which can be forfeited (i.e. “slashed”) programmatically
                      if their actions break the programmatic rules that define
                      the blockchain protocol which they secure.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6" style={{ marginTop: "15%" }}>
                  <img src={ValidatorsEarnStaking} className="w-100" alt="" />
                </div>

                <div className="col-lg-6" style={{ marginTop: "15%" }}>
                  <img src={DelegatorsContribute} className="w-100" alt="" />
                </div>
                <div className="col-lg-6" style={{ marginTop: "15%" }}>
                  <div style={{ marginTop: "15%" }}>
                    <p>
                      <b>Delegators contribute to the security</b>
                    </p>
                    <p>
                      The right to validate and add blocks, hence, to secure the
                      validity of the blockchain, is attached to every PoS
                      token. Token holders who do not want to act as a
                      validator, but still want to contribute to the
                      blockchain’s security and earn rewards, can delegate the
                      rights contained in their tokens to a validator of their
                      choice. These token holders are called delegators and can
                      be considered as a validators' customer.
                    </p>
                  </div>
                </div>

                <div className="col-lg-6" style={{ marginTop: "15%" }}>
                  <div style={{ marginTop: "15%" }}>
                    <p>
                      <b>Delegators earn a return on their assets</b>
                    </p>
                    <p>
                      The amount of rewards a delegator can earn through the
                      services of a validator is a function of the validators'
                      total rewards and the ratio between the size of the
                      holder’s own stake and the total stake of the validator.
                      For their services, the validators charge a fee on the
                      token holder’s rewards.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6" style={{ marginTop: "15%" }}>
                  <img
                    src={DelegatorsEarnAReturnonTheirAssets}
                    className="w-100"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              backgroundColor: "#092d55",
              textAlign: "center",
              color: "White",
              padding: "30px",
              marginTop: "7%",
            }}
          >
            <p style={{ marginBottom: "10px", fontSize: "24px" }}>
              Earn rewards by securing the digital infrastructure of Web 3.0
              through staking
            </p>
            <p>
              We offer non-custodial, highly secure staking services for a
              variety of next-generation Proof-of-Stake blockchains so that you{" "}
              <br /> can participate in the next evolution of the internet and
              earn rewards on your digital assets.
            </p>
            {/* <a href="/stake/PreferredMethod"> Stake ETH NOW </a> */}
            <a href="/" style={{ color: "#fff" }}>
              Stack Now <span> {">"} </span>
            </a>
          </div>
          <div className="row" style={{ marginTop: "40px" }}>
            <div className="col-lg-2" />
            <div className="col-lg-8">
              <h1> How to participate</h1>
              <Timeline position="alternate">
                <TimelineItem>
                  <TimelineOppositeContent color="text.secondary">
                    <div style={{ color: "black", marginTop: "30%" }}>
                      <p style={{ fontSize: "24px", marginBottom: "5px" }}>
                        1. Set up your staking operations
                      </p>
                      <p>
                        If you own tokens from one of our supported networks,
                        visit the respective subpage for more information about
                        the project, a reward calculator, and step-by-step
                        instructions.
                      </p>
                    </div>
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineConnector
                      style={{ width: "7px", backgroundColor: "#092d55" }}
                    />
                    <TimelineDot variant="outlined" color="primary" />
                    <TimelineConnector
                      style={{ width: "7px", backgroundColor: "#092d55" }}
                    />
                  </TimelineSeparator>
                  <TimelineContent>
                    <img src={stakingOperations} className="w-100" alt="" />
                  </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                  <TimelineOppositeContent color="text.secondary">
                    <div>
                      <div style={{ color: "black", marginTop: "30%" }}>
                        <p style={{ fontSize: "24px", marginBottom: "5px" }}>
                          2. Stake your tokens with one of our validators
                        </p>
                        <p>
                          Stake your tokens via our validator in order to
                          contribute to the networks' security and start earning
                          rewards. We help you along the way with tools,
                          tutorials, and personal customer support.
                        </p>
                      </div>
                    </div>
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineConnector
                      style={{ width: "7px", backgroundColor: "#092d55" }}
                    />
                    <TimelineDot variant="outlined" color="primary" />
                    <TimelineConnector
                      style={{ width: "7px", backgroundColor: "#092d55" }}
                    />
                  </TimelineSeparator>
                  <TimelineContent>
                    <img src={StakeYourTokens} className="w-100" alt="" />
                  </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                  <TimelineOppositeContent color="text.secondary">
                    <div>
                      <div style={{ color: "black", marginTop: "30%" }}>
                        <p style={{ fontSize: "24px", marginBottom: "5px" }}>
                          3. Manage your staking operations
                        </p>
                        <p>
                          Track and analyze your portfolio via different tools.
                          Choose different strategies like compounding your
                          rewards or receiving periodic payouts. We are always
                          available for advice and support.
                        </p>
                      </div>
                    </div>
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineConnector
                      style={{ width: "7px", backgroundColor: "#092d55" }}
                    />
                    <TimelineDot variant="outlined" color="primary" />
                    <TimelineConnector
                      style={{ width: "7px", backgroundColor: "#092d55" }}
                    />
                  </TimelineSeparator>
                  <TimelineContent>
                    <img src={ManageYourStaking} className="w-100" alt="" />
                  </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                  <TimelineOppositeContent color="text.secondary">
                    <div>
                      <div style={{ color: "black", marginTop: "30%" }}>
                        <p style={{ fontSize: "24px", marginBottom: "5px" }}>
                          4. Stay up to date with the projects that you help
                          secure
                        </p>
                        <p>
                          We provide curated content, news, and updates so that
                          you can stay in full sync without having to filter
                          through all the noise.
                        </p>
                      </div>
                    </div>
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineConnector
                      style={{ width: "7px", backgroundColor: "#092d55" }}
                    />
                    <TimelineDot variant="outlined" color="primary" />
                    <TimelineConnector
                      style={{ width: "7px", backgroundColor: "#092d55" }}
                    />
                  </TimelineSeparator>
                  <TimelineContent>
                    <img src={StayUpToDate} className="w-100" alt="" />
                  </TimelineContent>
                </TimelineItem>
              </Timeline>
            </div>
          </div>
          <div
            style={{
              backgroundColor: "#092d55",
              textAlign: "center",
              color: "white",
              padding: "30px",
              marginTop: "7%",
            }}
          >
            <h1
              style={{ color: "white", fontSize: "50px", marginBottom: "30px" }}
            >
              Let's connect
            </h1>
            <div
              className="row"
              style={{ marginTop: "40px", textAlign: "left" }}
            >
              <div className="col-lg-2" />
              <div className="col-lg-4">
                <p style={{ fontSize: "22px" }}> Staking Users </p>
                <p>
                  If you have any questions about the process, our service or
                  about us and our company, always feel free to reach out.
                </p>
                <button
                  className="site-button radius-no"
                  style={{
                    backgroundColor: "#f29f05",
                    fontSize: "18px",
                    color: "White",
                    borderRadius: "8px",
                  }}
                >
                  <a
                    href="https://t.me/coindelta_io"
                    style={{ color: "#fff" }}
                    target="_blank"
                  >
                    {" "}
                    Join Telegram{" "}
                  </a>
                </button>
                <button
                  className="site-button radius-no"
                  style={{
                    backgroundColor: "#f29f05",
                    fontSize: "18px",
                    color: "White",
                    borderRadius: "8px",
                    marginLeft: "15px",
                  }}
                >
                  Via Email
                </button>
              </div>
              <div className="col-lg-4">
                <p style={{ fontSize: "22px" }}>Institutionals</p>
                <p style={{ marginBottom: "1px" }}>
                  For institutional investors and large stake token holders, we
                  offer special services.
                </p>
                <a
                  href="/institutional"
                  style={{ color: "#f29f05", fontSize: "18px" }}
                >
                  Institutionals{" "}
                  <span style={{ marginTop: "10px" }}>{">"}</span>
                </a>
              </div>
              <div className="col-lg-2" />
            </div>
          </div>
        </div>
        <Footer2 />
      </>
    );
  }
}
export default HowItWorks;
