import React, { Fragment, Component } from "react";
import Header1 from "../../../Layout/Header1";
import DialogTitle from "@mui/material/DialogTitle";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import { TabContent, TabPane } from "reactstrap";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";

class PrivateKey extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "1",
      open: false,
    };
  }

  render() {
    return (
      <>
        <Header1 />
        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId="1">
            <div className="content-block">
              <div className="page-content bg-white">
                <div className="section-full content-inner">
                  <div className="container">
                    <div className="row">
                      <div
                        className="dlab-box-bg m-b30"
                        style={{
                          width: "92%",
                          marginTop: "150px",
                          marginLeft: "40px",
                          textAlign: "center",
                          boxShadow: "1px 1px 1px 1px #968c8c",
                          borderRadius: "3px",
                        }}
                      >
                        <h2
                          style={{
                            marginTop: "35px",
                            color: "#333B6D",
                            marginLeft: "-50px",
                          }}
                        >
                          Enter your private key string
                        </h2>
                        <p
                          className="mb-5"
                          style={{ marginTop: "-15px", marginLeft: "-160px" }}
                        >
                          Please enter your private key string to continue
                        </p>
                        <form
                          style={{
                            width: "49%",
                            marginLeft: "280px",
                            marginTop: "-30px",
                          }}
                        >
                          <input
                            type="text"
                            placeholder="Private Key"
                            className="form-control w-100 mb-5"
                            // value="Private Key"
                          ></input>
                        </form>
                        <br />

                        {/* <Link
                                style={{
                                  width: "48%",
                                  textAlign: "center",
                                  backgroundColor: "#fff",
                                  marginTop: "-18px",
                                  border: "1px solid blue",
                                  color: "#23439E",
                                  borderColor: "#23439E",
                                }}
                                to={"./StakeNowCLI"}
                                className="site-button"
                              >
                                NO THANKS, I PREFER CLI
                              </Link> */}
                        <Button
                          className="site-button"
                          disabled={true}
                          style={{
                            // backgroundColor: "transparent",
                            color: "#fff",
                            marginTop: "-50px",
                            width: "13%",
                            textAlign: "center",
                            border: "1px solid blue",
                            borderColor: "#23439E",
                            backgroundColor: "#23439E",
                            marginLeft: "400px",
                          }}
                        >
                          NEXT
                        </Button>
                        <br />
                        <br />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </TabPane>
        </TabContent>
      </>
    );
  }
}
export default PrivateKey;
