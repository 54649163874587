const config = {
  site: {
    // BASE_URL: "http://dev.coindelta.io.s3-website.us-east-2.amazonaws.com",
    BASE_URL: "https://dev.coindelta.io",
  },
  api: {
    BASE_URL: "https://o1zz1kxa67.execute-api.us-east-2.amazonaws.com",
  },
  awsConfig: {
    UserPoolId: "ap-south-1_E9TPrtUiX",
    ClientId: "2fpeomh529ep92edbfuqa5jokj",
    LoginURL: "https://coindelta.auth.ap-south-1.amazoncognito.com",
  },
};

// const dockerdev = {
//   site: {
//     BASE_URL: "https://coindelta.io",
//   },
//   api: {
//     BASE_URL: "https://o1zz1kxa67.execute-api.us-east-2.amazonaws.com",
//   },
//   awsConfig: {
//     UserPoolId: "us-east-2_ip5FVJcVN",
//     ClientId: "4hp0md6dts2u9lbk6s6nj0ll61",
//     LoginURL: "https://coindelta.auth.us-east-2.amazoncognito.com",
//   },
// };

// const prod = {
//   site: {
//     BASE_URL: "https://coindelta.io",
//   },
//   api: {
//     BASE_URL: "https://o1zz1kxa67.execute-api.us-east-2.amazonaws.com",
//   },
//   awsConfig: {
//     UserPoolId: "us-east-2_ip5FVJcVN",
//     ClientId: "4hp0md6dts2u9lbk6s6nj0ll61",
//     LoginURL: "https://coindelta.auth.us-east-2.amazoncognito.com",
//   },
// };

// let config = dev;
// process.env.REACT_APP_STAGE = dev;
// console.log("Selected stage: " + process.env.REACT_APP_STAGE);
// if (process.env.REACT_APP_STAGE === "dockerdev") {
//   config = dockerdev;
// } else if (process.env.REACT_APP_STAGE === "production") {
//   config = prod;
// }

export default {
  // Add common config values here
  ...config,
};
