import React, { Component } from "react";
import { Link } from "react-router-dom";
import Header from "./../../Layout/Header1";
import Footer from "./../../Layout/Footer1";

class Governance extends Component {
  render() {
    return (
      <>
        <Header />

        <div className="page-content bg-white">
          {/* <!-- contact area --> */}
          <div className="content-block">
            {/* <!-- Current Networks --> */}
            <div
              className="section-full bg-blue-light content-inner about-us-box"
              style={{ backgroundSize: "cover" }}
            >
              <div
                className="container"
                style={{
                  marginBottom: "0px",
                  color: "black",
                  fontFamily: "Cardillac-Regular,serif",
                  fontSize: "22px",
                  textAlign: "center",
                }}
              >
                <h1 style={{ textAlign: "center", marginBottom: "40px" }}>
                  Governance
                </h1>
                <p style={{ marginBottom: "70px" }}>
                  Coindelta maximizes the value of your coins through informed
                  governance
                </p>

                <p style={{ fontSize: "25px", marginTop: "30px" }}>
                  <b>Aligned Interests</b>
                </p>
                <p style={{ marginBottom: "70px" }}>
                  We're owners just like you. All of our decisions are guided by
                  maximizing the long-term value of our collective holdings.
                  Every vote will focus on this objective.
                </p>

                <p style={{ fontSize: "25px", marginTop: "30px" }}>
                  <b>Informed Advocates</b>
                </p>
                <p style={{ marginBottom: "70px" }}>
                  We're active in the community, so we understand how various
                  proposals will impact the network value.
                </p>

                <p style={{ fontSize: "25px", marginTop: "30px" }}>
                  <b>Maintain Your Vote</b>
                </p>
                <p>
                  Our web app lets you see upcoming votes, see our
                  recommendations on various proposals, and register your vote
                  on issues you care about.
                </p>
              </div>
            </div>
            {/* <!-- About Quotes End --> */}
          </div>
          {/* <!-- contact area END --> */}
        </div>

        <Footer />
      </>
    );
  }
}
export default Governance;
