import React, { Component } from "react";
import HeaderCompany from "../../Layout/HeaderCompany";
import Footer2 from '../../Layout/footer2';
import { TabContent, TabPane } from "reactstrap";
import { Link } from "react-router-dom";

import SustainabilityLogo from '../../../images/company/SustainabilityLogo.png';

import EvolutiontoProofofStake from '../../../images/company/EvolutiontoProofofStake.png';
import LimitationsofProofofWork from '../../../images/company/LimitationsofProofofWork.png';


class Sustainability extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeTab: "1",
        };

    }

    render() {

        return (
            <>
                <HeaderCompany />
                <div style={{ fontFamily: "Sarala, sans-serif" }}>
                    <div className="row" style={{ backgroundColor: "#092d55" }}>
                        <div className="col-lg-5" >
                            <div style={{ position: "absolute", top: "40%", left: "20%", fontFamily: "Sarala, sans-serif", color: "white" }}>
                                <p style={{ marginBottom: "20px", fontSize: "50px", lineHeight: "55px" }}><b>Sustainability</b></p>
                                <p style={{ fontSize: "18px" }}>At Staking Facilities, we believe that the future of the internet, namely Web 3.0, must not be at the expense of the environment. This is why we set out to only support networks that utilize Proof-of-Stake or a variation thereof.</p>

                            </div>
                        </div>
                        <div className="col-lg-7" >
                            <img src={SustainabilityLogo} className="w-100" alt="" style={{ height: "550px", marginTop: "100px" }} />
                        </div>
                    </div>
                    <div className="row" style={{ marginTop: "40px" }}>
                        <div className="col-lg-2" />
                        <div className="col-lg-8" style={{ textAlign: "center" }}>
                            <h1>Our renewable energy commitment</h1>
                            <div className="row" style={{ marginTop: "40px" }}>
                                <div className="col-lg-4" style={{ backgroundColor: "white", padding: "20px", borderRadius: "8px", color: "black" }}>
                                    <p><b>Sustainable Consensus</b></p>
                                    <p>Proof-of-Stake consumes far less energy in order to maintain a ledger and reach consensus than Proof-of-Work does. We only validate in Proof-of-Stake protocols and thus have far lower overall power consumption than miners do.</p>
                                </div>
                                <div className="col-lg-4" style={{ backgroundColor: "white", padding: "20px", borderRadius: "8px", color: "black" }}>
                                    <p><b>Clean Energy Mix</b></p>
                                    <p>It is the responsibility of all of us to counteract climate change and we can all do our part. We strive for our validators to be exclusively powered through clean and renewable energy within the next decade.</p>
                                </div>
                                <div className="col-lg-4" style={{ backgroundColor: "white", padding: "20px", borderRadius: "8px", color: "black" }}>
                                    <p><b>Green Data Centers</b></p>
                                    <p>Furthermore, we seek to be as carbon efficient as possible in our daily operations. We exclusively work with certified datacenters who are committed to an environmentally conscious corporate strategy.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ textAlign: "center", color: "black" }}>
                        <p style={{ marginTop: "5%", fontSize: "18px" }}><b>Certifications</b></p>
                    </div>
                    <div style={{ backgroundColor: "#092d55", textAlign: "center", color: "White", padding: "30px", marginTop: "7%" }}>
                        <p style={{ marginBottom: "10px", fontSize: "24px" }}>Baselayer for Web 3.0</p>
                        <p>Proof-of-Stake networks facilitate the maintenance and provision of infrastructure that serve as the base-layer for Web 3.0 in a decentralized fashion. They enable token-holders to contribute to the network’s security and earn rewards for doing so.</p>
                        <a href="/how-it-works" style={{ fontSize: "22px" }}>How it Works</a>
                    </div>

                    <div className="row" style={{ marginTop: "40px" }}>
                        <div className="col-lg-2" />
                        <div className="col-lg-4" style={{ textAlign: "center", paddingRight: "0px" }}>
                            <img src={LimitationsofProofofWork} className="w-100" alt="" style={{ borderRadius: "10px" }} />
                        </div>
                        <div className="col-lg-4" style={{ textAlign: "center", backgroundColor: "white", paddingTop: "20px", borderRadius: "10px", marginTop: "35px", border: "1px solid #f29f05", marginBottom: "20px" }}>
                            <p style={{ fontSize: "30px" }}><b>Limitations of Proof-of-Work</b></p>
                            <p style={{ marginBottom: "5px" }}>Proof-of-Work is a revolutionary invention that helps with aligning incentives in a decentralized network composed of unknowing or even untrusting parties. It powers the Bitcoin network and kicked off a wave of innovations in the blockchain space.</p>
                            <p>However, it is not a very scalable nor sustainable mechanism and not suited to power networks set out for mass usage. Sustaining the Bitcoin network consumes as much energy annually as whole countries such as Switzerland.</p>
                        </div>
                        <div className="col-lg-2" />
                    </div>

                    <div className="row" style={{ marginTop: "40px" }}>
                        <div className="col-lg-2" />
                        <div className="col-lg-4" style={{ textAlign: "center", backgroundColor: "white", padding: "20px", borderRadius: "10px", marginTop: "35px", border: "1px solid #f29f05", marginBottom: "40px"}}>
                            <p style={{ fontSize: "30px" }}><b>Evolution to Proof-of-Stake</b></p>
                            <p style={{ marginBottom: "5px" }}>Proof-of-Stake constitutes a sustainable alternative to Proof-of-Work as it provides higher security, more throughput, less centralization, and faster finality all while consuming far less energy in order to maintain the blockchain network. Many next-generation blockchains utilize PoS and Ethereum, the second-largest Blockchain by market capitalization recently initiated its' switch to PoS by launching the Beacon Chain.</p>
                        </div>
                        <div className="col-lg-4" style={{ textAlign: "center", paddingLeft: "0px" }}>
                            <img src={EvolutiontoProofofStake} className="w-100" alt="" style={{ borderRadius: "10px" }} />
                        </div>
                        <div className="col-lg-2" />
                    </div>
                </div>
                <br />
                <br />
                <br />
                <Footer2 />
            </>

        );
    }
}
export default Sustainability;
