import React, { Fragment, Component } from "react";
import Header1 from "../../../Layout/Header1";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { TabContent, TabPane } from "reactstrap";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";

class PreferredMethod extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "1",
      open: false,
    };
  }

  render() {
    return (
      <>
        <Header1 />
        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId="1">
            <div className="content-block">
              <div className="page-content bg-white">
                <div className="section-full content-inner">
                  <div className="container">
                    <div className="row">
                      <div
                        className="dlab-box-bg m-b30"
                        style={{
                          width: "92%",
                          marginTop: "150px",
                          marginLeft: "40px",
                          textAlign: "center",
                          boxShadow: "1px 1px 1px 1px #968c8c",
                          borderRadius: "3px",
                        }}
                      >
                        <h2 style={{ marginTop: "60px", color: "#333B6D" }}>
                          Choose your preferred method
                        </h2>
                        <p
                          className="mb-5"
                          style={{ marginTop: "-15px", marginLeft: "12px" }}
                        >
                          Choose a prefered staking method. We recomend the web
                          interface -
                        </p>
                        <p style={{ marginTop: "-50px", marginRight: "372px" }}>
                          it's easier to use!
                        </p>
                        <Link
                          style={{
                            width: "48%",
                            textAlign: "center",
                            backgroundColor: "#fff",
                            border: "1px solid blue",
                            color: "#23439E",
                            borderColor: "#23439E",
                          }}
                          to={"./AuthMethod"}
                          className="site-button"
                        >
                          CONTINUE TO WEB INTERFACE
                        </Link>
                        {/* <Button
                          className="site-button"
                          // disable
                          style={{
                            // backgroundColor: "transparent",
                            color: "#333B6D",
                            width: "48%",
                            textAlign: "center",
                          }}
                        >
                          CONTINUE TO WEB INTERFACE
                        </Button> */}
                        <br />
                        <br />
                        <Link
                          style={{
                            width: "48%",
                            textAlign: "center",
                            backgroundColor: "#fff",
                            marginTop: "-18px",
                            border: "1px solid blue",
                            color: "#23439E",
                            borderColor: "#23439E",
                          }}
                          to={"./StakeNowCLI"}
                          className="site-button"
                        >
                          NO THANKS, I PREFER CLI
                        </Link>
                        {/* <Button
                          className="site-button"
                          // disable
                          style={{
                            // backgroundColor: "transparent",
                            color: "#333B6D",
                            marginTop: "-18px",
                            width: "48%",
                            textAlign: "center",
                          }}
                        >
                          NO THANKS, I PREFER CLI
                        </Button> */}
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </TabPane>
        </TabContent>
      </>

      // <TabContent activeTab={this.state.activeTab}>
      //   {/* <h5 style={{ marginLeft: "210px", marginTop: "20px" }}>Coindelta</h5> */}
      //   <TabPane tabId="1">
      //     <div className="content-block">
      //       <div className="page-content bg-white">
      //         <div
      //           className="dlab-box-bg m-b30 box-hover fly-box"
      //           style={{
      //             width: "70%",
      //             marginLeft: "230px",
      //             marginTop: "150px",
      //           }}
      //         >
      //           <h2
      //             style={{
      //               textAlign: "center",
      //               color: "#333B6D",
      //               marginTop: "20px",
      //             }}
      //           >
      //             Choose your preferred method
      //           </h2>
      //           <br />
      //           <h4 style={{ textAlign: "center", marginTop: "-40px" }}>
      //             Choose a prefered staking method. We recomend the web
      //             interface - it's easier to use!
      //           </h4>
      //           <br />
      //           <br />
      //           <Link
      //             style={{
      //               marginLeft: "400px",
      //               color: "#333B6D",
      //               width: "30%",
      //               textAlign: "left",
      //             }}
      //             to={"./StakeNow"}
      //             className="site-button"
      //           >
      //             CONTINUE TO WEB INTERFACE
      //           </Link>
      //           {/* <div
      //             className="site-button"
      //             style={{
      //               marginLeft: "550px",
      //               color: "#333B6D",
      //               width: "30%",
      //               textAlign: "left",
      //               //   backgroundColor: "tan",
      //             }}
      //           >
      //             CONTINUE TO WEB INTERFACE
      //           </div> */}
      //           <br />
      //           <br />
      //           <div
      //             className="site-button"
      //             style={{
      //               marginLeft: "400px",
      //               color: "#333B6D",
      //               width: "30%",
      //               textAlign: "left",
      //             }}
      //           >
      //             NO THANKS, I PREFER CLI
      //           </div>
      //           <br />
      //           <br />
      //           <br />
      //         </div>
      //       </div>
      //     </div>
      //   </TabPane>

      //   <TabPane tabId="2">
      //     <div className="content-block">
      //       <div className="page-content bg-white">
      //         <div className="dlab-box-bg m-b30 box-hover fly-box">
      //           <h2
      //             style={{
      //               textAlign: "center",
      //               color: "#333B6D",
      //               marginTop: "20px",
      //             }}
      //           >
      //             Choose your auth method
      //           </h2>
      //           <br />
      //           <h4 style={{ textAlign: "center", marginTop: "-40px" }}>
      //             We recommend that you use a ledger device, however, if you
      //             feel more comfortable using a mnemonic or a private key
      //             string, select that option!
      //           </h4>
      //           <br />
      //           <br />
      //           <div
      //             className="site-button"
      //             style={{
      //               marginLeft: "550px",
      //               color: "#333B6D",
      //               width: "30%",
      //               textAlign: "left",
      //               //   backgroundColor: "tan",
      //             }}
      //           >
      //             POLKADOT.JS EXTENSION
      //           </div>
      //           <div
      //             className="site-button"
      //             style={{
      //               marginLeft: "550px",
      //               color: "#333B6D",
      //               width: "30%",
      //               textAlign: "left",
      //               //   backgroundColor: "tan",
      //             }}
      //           >
      //             LEDGER DEVICE
      //           </div>
      //           <div
      //             className="site-button"
      //             style={{
      //               marginLeft: "550px",
      //               color: "#333B6D",
      //               width: "30%",
      //               textAlign: "left",
      //               //   backgroundColor: "tan",
      //             }}
      //           >
      //             MNEMONIC
      //           </div>
      //           <div
      //             className="site-button"
      //             style={{
      //               marginLeft: "550px",
      //               color: "#333B6D",
      //               width: "30%",
      //               textAlign: "left",
      //               //   backgroundColor: "tan",
      //             }}
      //           >
      //             PRIVATE KEY
      //           </div>
      //           <br />
      //           <br />
      //           <br />
      //         </div>
      //       </div>
      //     </div>
      //   </TabPane>

      //   <TabPane tabId="3">
      //     <div className="content-block">
      //       <div className="page-content bg-white">
      //         <div className="dlab-box-bg m-b30 box-hover fly-box">
      //           <h2
      //             style={{
      //               textAlign: "center",
      //               color: "#333B6D",
      //               marginTop: "20px",
      //             }}
      //           >
      //             Connect your wallet
      //           </h2>
      //           <br />
      //           <h4 style={{ textAlign: "center", marginTop: "-40px" }}>
      //             Open the Polkadot.js browser extension and allow access.
      //           </h4>
      //           <br />
      //           <br />
      //           <h5
      //             style={{
      //               textAlign: "center",
      //               marginTop: "-40px",
      //               color: "green",
      //             }}
      //           >
      //             Connected
      //           </h5>
      //           <div
      //             className="site-button"
      //             style={{
      //               marginLeft: "550px",
      //               color: "#333B6D",
      //               width: "10%",
      //               textAlign: "center",
      //               //   backgroundColor: "tan",
      //             }}
      //           >
      //             NEXT
      //           </div>
      //           <br />
      //           <br />
      //           <br />
      //         </div>
      //       </div>
      //     </div>
      //   </TabPane>

      //   <TabPane tabId="4">
      //     <div className="content-block">
      //       <div className="page-content bg-white">
      //         <div className="dlab-box-bg m-b30 box-hover fly-box">
      //           <h2
      //             style={{
      //               textAlign: "center",
      //               color: "#333B6D",
      //               marginTop: "20px",
      //             }}
      //           >
      //             Choose the staking pair
      //           </h2>
      //           <br />
      //           <h4 style={{ textAlign: "center", marginTop: "-40px" }}>
      //             Please note that the stash and the controller account can't be
      //             the same.
      //           </h4>
      //           <br />
      //           <h4 style={{ textAlign: "center", marginTop: "-40px" }}>
      //             No addresses detected on the Extension/Ledger. Please try
      //             again!
      //           </h4>
      //           <br />
      //           <br />
      //           <br />
      //         </div>
      //       </div>
      //     </div>
      //   </TabPane>

      //   {/* <TabPane tabId="5">
      //     <div className="content-block">
      //       <div className="page-content bg-white">
      //         <div className="dlab-box-bg m-b30 box-hover fly-box">
      //           <h2
      //             style={{
      //               textAlign: "center",
      //               color: "#333B6D",
      //               marginTop: "20px",
      //             }}
      //           >
      //             CLI Method
      //           </h2>
      //           <br />
      //           <h4 style={{ textAlign: "center", marginTop: "-40px" }}>
      //             Awesome, everything that you need is below! Once you’re done,
      //             hit the button to go to your dashboard.
      //           </h4>
      //           <br />
      //           <br />
      //           <p className="m-b10"></p>
      //           <br />
      //           <br />
      //           <br />
      //         </div>
      //       </div>
      //     </div>
      //   </TabPane> */}
      // </TabContent>
    );
  }
}
export default PreferredMethod;
