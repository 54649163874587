import React, { Component } from "react";
import HeaderCompany from "../../Layout/HeaderCompany";
import Footer2 from "../../Layout/footer2";
import { TabContent, TabPane } from "reactstrap";
import { Link } from "react-router-dom";

import SecurityLogo from "../../../images/company/SecurityLogo.png";
import Dollar from "../../../images/company/Dollar.png";

import OurSustainabilityGoals from "../../../images/company/OurSustainabilityGoals.png";
import OurValidatorInfrastructure from "../../../images/company/OurValidatorInfrastructure.png";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

const bull = (
  <Box
    component="span"
    sx={{ display: "inline-block", mx: "2px", transform: "scale(0.8)" }}
  >
    •
  </Box>
);
class Security extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: "1",
    };
  }

  render() {
    return (
      <>
        <HeaderCompany />
        <div style={{ fontFamily: "Sarala, sans-serif" }}>
          <div className="row" style={{ backgroundColor: "#092d55" }}>
            <div className="col-lg-5">
              <div
                style={{
                  position: "absolute",
                  top: "40%",
                  left: "20%",
                  fontFamily: "Sarala, sans-serif",
                  color: "white",
                }}
              >
                <p
                  style={{
                    marginBottom: "20px",
                    fontSize: "50px",
                    lineHeight: "55px",
                  }}
                >
                  <b>Security</b>
                </p>
                <p style={{ fontSize: "18px" }}>
                  Highly performant, secure infrastructure is the core of our
                  business and the security of our customers' stake is of utmost
                  importance to us.
                </p>
                <p style={{ fontSize: "18px" }}>
                  We combine different security measures, best-practices, and
                  enterprise-grade hardware to provide you with a highly secure
                  staking solution.
                </p>
              </div>
            </div>
            <div className="col-lg-7">
              <img
                src={SecurityLogo}
                className="w-100"
                alt=""
                style={{ height: "550px", marginTop: "100px" }}
              />
            </div>
          </div>

          {/* <div className="row" style={{ marginTop: "40px" }}>
            <div className="col-lg-2" />
            <div className="col-lg-8" style={{ textAlign: "center" }}>
              <h1>Our Security Setup</h1>
              <div className="row" style={{ marginTop: "40px" }}>
                <div
                  className="col-lg-4"
                  style={{
                    backgroundColor: "white",
                    padding: "20px",
                    borderRadius: "8px",
                    color: "black",
                  }}
                >
                  <p>
                    <b>Custom-built Hardware</b>
                  </p>
                  <p>
                    To best cater to the needs and specs of the different
                    networks, we deploy our bare-metal hardware specially
                    tailored to the respective network. We personally assemble
                    and stress-test each validator before putting it to action
                    to guarantee an outstanding performance.
                  </p>
                </div>
                <div
                  className="col-lg-4"
                  style={{
                    backgroundColor: "white",
                    padding: "20px",
                    borderRadius: "8px",
                    color: "black",
                  }}
                >
                  <p>
                    <b>Tier 3+ Datacenters</b>
                  </p>
                  <p>
                    Our hardware is co-located in top-notch, certified data
                    centers in Munich. Guaranteed uptime, biometric security,
                    and redundant power supply reinforce a continuous and secure
                    operation of our validators. Given the proximity to our
                    headquarter, we are always able to physically access our
                    servers.
                  </p>
                </div>
                <div
                  className="col-lg-4"
                  style={{
                    backgroundColor: "white",
                    padding: "20px",
                    borderRadius: "8px",
                    color: "black",
                  }}
                >
                  <p>
                    <b>State-of-the-Art Monitoring</b>
                  </p>
                  <p>
                    Every network is different. We make sure to find the best
                    monitoring solution for each individual situation. When
                    applicable, we use a state-of-the-art open-source stack
                    using Prometheus & Grafana. If needed, we create proprietary
                    monitoring software ourselves.
                  </p>
                </div>
              </div>
            </div>
          </div> */}
          {/* <div>
                        <img src={map} className="w-100" alt="" style={{ height: "550px", marginTop: "100px" }} />
                    </div> */}
          <div className="row" style={{ marginTop: "40px" }}>
            <div className="col-lg-2" />
            <div
              className="col-lg-4"
              style={{ textAlign: "center", paddingRight: "0px" }}
            >
              <img
                src={OurValidatorInfrastructure}
                className="w-100"
                alt=""
                style={{ borderRadius: "10px" }}
              />
            </div>
            <div
              className="col-lg-4"
              style={{
                textAlign: "center",
                backgroundColor: "white",
                paddingTop: "20px",
                borderRadius: "10px",
                marginTop: "35px",
                border: "1px solid #f29f05",
                marginBottom: "20px",
              }}
            >
              <p style={{ fontSize: "30px" }}>
                <b>Our Validator Infrastructure</b>
              </p>
              <p style={{ marginBottom: "5px" }}>
                For our servers, we follow a multi-site setup across multiple
                datacenters in the heart of Europe. At each level, we have
                redundant networking including multi-homed DC uplinks, and
                provide high-availability between VM hosts. Our public
                connections are secured via proxy connections, whereas our
                private connections are secured via VPNs and whitelisting. We
                leverage cloud instances only where it is beneficial to foster
                the decentralization of each network we support.
              </p>
            </div>
            <div className="col-lg-2" />
          </div>

          <div className="row" style={{ marginTop: "40px" }}>
            <div className="col-lg-2" />
            <div
              className="col-lg-4"
              style={{
                textAlign: "center",
                backgroundColor: "white",
                padding: "20px",
                borderRadius: "10px",
                marginTop: "35px",
                border: "1px solid #f29f05",
                marginBottom: "40px",
              }}
            >
              <p style={{ fontSize: "30px" }}>
                <b>Our Sustainability Goals</b>
              </p>
              <p style={{ marginBottom: "5px" }}>
                We strive to be as carbon efficient as possible in our daily
                operations. We exclusively work with certified data centers that
                provide a sustainable energy mix next to outstanding security
                standards.
              </p>
              <a href="/sustainability">Sustainability</a>
            </div>
            <div
              className="col-lg-4"
              style={{ textAlign: "center", paddingLeft: "0px" }}
            >
              <img
                src={OurSustainabilityGoals}
                className="w-100"
                alt=""
                style={{ borderRadius: "10px" }}
              />
            </div>
            <div className="col-lg-2" />
          </div>
          {/* <div className="row" style={{ marginTop: "40px" }}>
                        <div className="col-lg-2" />
                        <div className="col-lg-8" style={{ textAlign: "left ", padding: "20px", borderRadius: "10px", marginTop: "35px", marginBottom: "40px" }}>
                            <h1>Post Mortem Reports</h1>
                            <p>We are strong proponents of transparency and hence want to provide our stakeholders with a publicly available overview of post mortems that have happened ever since our founding in 2017.</p>
                            <Card style={{ minWidth: 30, padding: "0px" }}>
                                <CardContent style={{padding: "15px",paddingBottom: "0px" }}>
                                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                       Edgeware <img src={Dollar} style={{marginLeft: "15px",height: "30px"}}/> <span style={{marginLeft: "15px"}}> We are refunding slashed funds from Sentry Node Protocol Bug</span> <span style={{float: "right"}}>March 2, 2020</span>
                                    </Typography>
                                </CardContent>
                            </Card>

                            <Card style={{ minWidth: 30, padding: "0px",marginTop: "30px" }}>
                                <CardContent style={{padding: "15px",paddingBottom: "0px" }}>
                                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                       Edgeware <img src={Dollar} style={{marginLeft: "15px",height: "30px"}}/> <span style={{marginLeft: "15px"}}> Sentry Node Protocol Bug</span> <span style={{float: "right"}}>February 25, 2020</span>
                                    </Typography>
                                </CardContent>
                            </Card>
                        </div>
                        <div className="col-lg-2" />
                    </div> */}
        </div>
        <br />
        <br />
        <Footer2 />
      </>
    );
  }
}
export default Security;
