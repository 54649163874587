import React, { Fragment, Component } from "react";
import Header1 from "../../../Layout/Header1";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { TabContent, TabPane } from "reactstrap";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";

class AuthMethod extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "1",
      open: false,
    };
  }

  render() {
    return (
      <>
        <Header1 />
        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId="1">
            <div className="content-block">
              <div className="page-content bg-white">
                <div className="section-full content-inner">
                  <div className="container">
                    <div className="row">
                      <div
                        className="dlab-box-bg m-b30"
                        style={{
                          width: "92%",
                          marginTop: "130px",
                          marginLeft: "40px",
                          textAlign: "center",
                          boxShadow: "1px 1px 1px 1px #968c8c",
                          borderRadius: "3px",
                        }}
                      >
                        <h2
                          style={{
                            marginTop: "20px",
                            color: "#333B6D",
                            textAlign: "center",
                          }}
                        >
                          Choose your auth method
                        </h2>
                        <p
                          className="mb-5"
                          style={{ marginTop: "-15px", marginLeft: "60px" }}
                        >
                          We recommend that you use a ledger device, however, if
                          you feel
                        </p>
                        <p style={{ marginTop: "-55px", marginLeft: "69px" }}>
                          more comfortable using a mnemonic or a private key
                          string, select
                        </p>
                        <br />
                        <p style={{ marginTop: "-55px", marginRight: "339px" }}>
                          that option!{" "}
                        </p>
                        <br />
                        <Link
                          style={{
                            width: "50%",
                            textAlign: "center",
                            marginLeft: "110px",
                            backgroundColor: "#fff",
                            border: "1px solid blue",
                            color: "#23439E",
                            borderColor: "#23439E",
                          }}
                          to={"./Polkadot_Extension"}
                          className="site-button"
                        >
                          POLKADOT.JS EXTENSION
                        </Link>
                        {/* .site-button {(style = { backgroundColor: "blue" })} */}
                        {/* <Button
                          className="site-button"
                          // disable
                          style={{
                            // backgroundColor: "transparent",
                            color: "#333B6D",
                            width: "50%",
                            textAlign: "center",
                            marginLeft: "110px",
                            // marginRight: "10px",
                          }}
                        >
                          POLKADOT.JS EXTENSION
                        </Button> */}
                        <br />
                        <br />
                        <Link
                          style={{
                            width: "50%",
                            textAlign: "center",
                            marginLeft: "110px",
                            backgroundColor: "#fff",
                            border: "1px solid blue",
                            color: "#23439E",
                            borderColor: "#23439E",
                            marginTop: "-15px",
                          }}
                          to={"./ConnectWallet"}
                          className="site-button"
                        >
                          LEDGER DEVICE
                        </Link>
                        {/* <Button
                          className="site-button"
                          // disable
                          style={{
                            backgroundColor: "#fff",
                            color: "#333B6D",
                            marginTop: "-18px",
                            width: "50%",
                            marginLeft: "110px",
                            textAlign: "center",
                            border: "1px solid blue",
                            color: "#23439E",
                            borderColor: "#23439E",
                          }}
                        >
                          LEDGER DEVICE
                        </Button> */}
                        <br />
                        <br />
                        <Link
                          style={{
                            width: "50%",
                            textAlign: "center",
                            marginLeft: "110px",
                            backgroundColor: "#fff",
                            border: "1px solid blue",
                            color: "#23439E",
                            borderColor: "#23439E",
                            marginTop: "-15px",
                          }}
                          to={"./Mnemonic"}
                          className="site-button"
                        >
                          MNEMONIC
                        </Link>
                        {/* <Button
                          className="site-button"
                          // disable
                          style={{
                            backgroundColor: "#fff",
                            color: "#333B6D",
                            marginTop: "-18px",
                            width: "50%",
                            marginLeft: "110px",
                            textAlign: "center",
                            border: "1px solid blue",
                            color: "#23439E",
                            borderColor: "#23439E",
                          }}
                        >
                          MNEMONIC
                        </Button> */}
                        <br />
                        <br />
                        <Link
                          style={{
                            width: "50%",
                            textAlign: "center",
                            marginLeft: "110px",
                            backgroundColor: "#fff",
                            border: "1px solid blue",
                            color: "#23439E",
                            borderColor: "#23439E",
                            marginTop: "-15px",
                          }}
                          to={"./PrivateKey"}
                          className="site-button"
                        >
                          PRIVATE KEY
                        </Link>
                        {/* <Button
                          className="site-button"
                          // disable
                          style={{
                            backgroundColor: "#fff",
                            color: "#333B6D",
                            marginTop: "-18px",
                            width: "50%",
                            marginLeft: "110px",
                            textAlign: "center",
                            border: "1px solid blue",
                            color: "#23439E",
                            borderColor: "#23439E",
                          }}
                        >
                          PRIVATE KEY
                        </Button> */}
                        <br />
                        <br />
                        <br />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </TabPane>
        </TabContent>
      </>
    );
  }
}
export default AuthMethod;
