import React, { Component } from "react";
import { Link } from "react-router-dom";
import Header from "../../Layout/Header1";
import Footer from "../../Layout/Footer1";
import TransactionInfo from "../../Pages/NodeInfo/TransactionInfo";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import moment from "moment";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { Backdrop } from "@material-ui/core";
import NodeInfoService from "../../../Service/NodeInfoService";
import reward from "../../../images/reward.png";

class AvalancheNodeInfo extends Component {
  constructor(props) {
    super(props);

    console.log("props props props props");
    console.log(props);
    const url = props.location.search;
    let nodeId = "";
    console.log(url);
    if (url) {
      const splitURL = url.split("=");
      console.log(splitURL[0]);
      console.log(splitURL[1]);
      const validator = splitURL[2];
      nodeId = splitURL[1].split("&");
      console.log("nodeId");
      console.log(nodeId[0]);
      console.log("Validator");
      console.log(validator);
    }

    this.state = {
      nodeInfoService: new NodeInfoService(),
      value: {},
      json: {},
      data: [],
      validator: [],
      loading: false,
      validatorName: nodeId && nodeId[0] ? nodeId[0] : "AVAX",
    };
  }

  componentDidMount() {
    if (this.state.validatorName === "AVAX") {
      this.state.nodeInfoService.getNodeInfo().then((res) => {
        console.log("json value of getNodeInfo");
        console.log(res);
        this.setState({ data: res });

        if (res) {
          console.log("json", res.res_object.delegators.rewardOwner);
          const row = [];
          this.setState({
            validator: res.res_object,
            load: false,
          });
        } else {
          this.setState({ load: false });
        }
      }, []);
    } else {
      this.state.nodeInfoService.ethNodeInfo().then((res) => {
        console.log("json value of ethNodeInfo");
        console.log(res);
        this.setState({ data: res });

        if (res) {
          console.log("json", res.body.result.validators[0]);
          const row = [];
          this.setState({
            validator: res.body.result.validators[0],
            load: false,
          });
        } else {
          this.setState({ load: false });
        }
      }, []);
    }
  }

  render() {
    const { data, validator } = this.state;
    console.log(data?.body?.result?.validators[0].stakeAmount);
    return (
      <>
        <Header />

        <div className="page-content bg-white">
          <div className="section-full content-inner shop-account">
            <div className="container">
              <Backdrop
                open={this.state.loading}
                style={{ zIndex: 999, color: "#fff" }}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
              <div className="row">
                <div className="col-md-12 text-center">
                  <h2 className="font-weight-700 m-t0 m-b30">Node info</h2>
                </div>
                <Grid container spacing={2} marginLeft="45px">
                  <Grid item xs={2} fontWeight="bold">
                    StakeAmount:
                  </Grid>
                  <Grid item xs={2} fontWeight="bold">
                    {data?.res_object?.stakeAmount}
                    {/* {console.log("dataaaa",data.res_object.stakeAmount)} */}
                    {/* {data.body.result.validators[0].stakeAmount} */}
                  </Grid>
                </Grid>
                <Grid container spacing={2} marginLeft="45px">
                  <Grid item xs={2} fontWeight="bold">
                    NodeID:
                  </Grid>
                  <Grid item xs={6} fontWeight="bold">
                  {data?.res_object?.nodeId}
                  </Grid>
                </Grid>
                <Grid container spacing={2} marginLeft="45px">
                  <Grid item xs={2} fontWeight="bold">
                    PotentialReward:
                  </Grid>
                  <Grid item xs={2} fontWeight="bold">
                    {data?.res_object?.potentialReward}
                  </Grid>
                </Grid>
                <Grid container spacing={2} marginLeft="45px">
                  <Grid item xs={2} fontWeight="bold">
                    DelegationFee:
                  </Grid>
                  <Grid item xs={2} fontWeight="bold">
                    {data?.res_object?.delegationFee}
                  </Grid>
                </Grid>

                <TransactionInfo validator={validator} />
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </>
    );
  }
}

export default AvalancheNodeInfo;
