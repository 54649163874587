import React, { Component } from "react";
import { ToastContainer, toast } from 'react-toastify';
import { Link } from "react-router-dom";
import Header2 from "./../Layout/header2";
import Header from "../Layout/Header1";
import Footer2 from "./../Layout/footer2";
import Footer1 from "../Layout/Footer1";
import HomeOwlSlider from "./../Element/HomeOwlSlider";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
// import NodeInfoService from "../../Service/NodeInfoService";
import NodeInfoService from "../../Service/NodeInfoService";
import TextareaAutosize from "@mui/material/TextareaAutosize";
//Images
import bg15 from "./../../images/background/bg15.jpg";
import bg11 from "./../../images/background/bg11.jpg";
import StakeAvax from "../../images/blockchain_logos/StakeAvax.jpg";
import Staking from "../../images/blockchain_logos/Staking-HomePage.png";
import Group from "../../images/blockchain_logos/Group.png";
import Earn from "../../images/blockchain_logos/Earn.png";
import key from "../../images/blockchain_logos/key.png";
import Participation from "../../images/blockchain_logos/Participation.png";
import Slashing from "../../images/blockchain_logos/Slashing.png";

import grid1 from "./../../images/blog/grid/pic1.jpg";
import grid2 from "./../../images/blog/grid/pic2.jpg";
import grid3 from "./../../images/blog/grid/pic3.jpg";

import Vector from "../../images/Vector.png";
import Excellence from "../../images/Excellence.png";
import Vector1 from "../../images/Vector-1.png";
import highly from "../../images/highly.png";
import Coverage from "../../images/coverage.png";
import optimal from "../../images/optimal.png";
import api from "../../images/api.png";
import SLA from "../../images/SLA.png";
import whiteLabel from "../../images/whiteLabel.png";

import Avalanche_logo from "./../../images/blockchain_logos/avalancheMain.png";
import Ethereum_logo from "./../../images/blockchain_logos/ethereum.png";
import Polkadot_logo from "./../../images/blockchain_logos/polkadot.png";
import Solana_logo from "./../../images/blockchain_logos/solana.png";
import Cardano_logo from "./../../images/blockchain_logos/cardano.png";
import Polygon_logo from "./../../images/blockchain_logos/polygon.png";
import Chainlink_logo from "./../../images/blockchain_logos/chainlink.png";
import Cosmos_logo from "./../../images/blockchain_logos/cosmos.png";
import { Button } from "react-bootstrap";
import config from "../../config";
import AWSService from "../../Service/AWSService";
import { borderRadius } from "@mui/system";

import InfiniteCarousel from 'react-leaf-carousel';
import 'react-toastify/dist/ReactToastify.css';
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const gridBlog = [
  {
    isIcon: true,
    icon: highly,
    title: "Highly Available Infrastructure",
    details:
      "We use kubernetes for deployment of our nodes with automated recovery, no staked customers has suffered losses due to extended downtime or slashing.",
  },
  // {
  //   icon: "https://storage.googleapis.com/static-webapp-220401-prod/static/i/svg/Tech_3.4eb9a23ce26c.svg",
  //   title: "Diligenced by The Best",
  //   details:
  //     "Our technical infrastructure has been audited by security and devops teams at the leading protocols, exchanges and custodians.",
  // },
  {
    isIcon: true,
    icon: Coverage,
    title: "Broadest Asset Coverage",
    details:
      "With support for 6+ assets and 10+ more coming soon, Coindelta delivers rewards across the broadest range of crypto assets.",
  },
  {
    isIcon: true,
    icon: optimal,
    title: "Optimal Rewards",
    details:
      "We optimize rewards based on the unique dynamics of each chain or protocol we support.",
  },
  {
    isIcon: true,
    icon: api,
    title: "API for Everything",
    details:
      "Asset agnostic APIs for accessing different blockchains data, providing visibilty to different data points.",
  },
  // {
  //   icon: "https://storage.googleapis.com/static-webapp-220401-prod/static/i/svg/block_level_reporting.ad04bb532875.svg",
  //   title: "Multi-Asset Reporting",
  //   details:
  //     "Designed for fund admins and accountants, the block-level reporting is exportable, and includes all staking and delegation transactions.",
  // },
  {
    isIcon: true,
    icon: SLA,
    title: "SLA Agreements & Reseller Staking",
    details:
      "Fee reduction schedule if SLAs are not met and Reward kickback through your customers.",
  },
  // {
  //   isIcon: false,
  //   icon: <i className="flaticon-notebook" />,
  //   title: "Reseller Staking",
  //   details: "Reward kickback through your customers. ",
  // },
  // {
  //   isIcon: false,
  //   icon: <i className="flaticon-notebook" />,
  //   title: "Reward Reportings ",
  //   details: "Tax relevant documentation of your staking rewards.",
  // },
  // {
  //   isIcon: false,
  //   icon: <i className="flaticon-notebook" />,
  //   title: "Institutional-grade Infrastrucutre",
  //   details: "Optimized for high security, redundancy, & availability.",
  // },
  {
    isIcon: true,
    icon: whiteLabel,
    title: "Whitelabel Validator Solutions",
    details: "Branded Infrastructure & Validation Services.",
  },
];

const Stake = [
  {
    icon: "https://staking.staked.us/hubfs/StakedTheme/svg/Why_1.svg",
    title: "Delegation",
    details:
      "Stakers sign a transaction with their private key that bonds, or delegates their tokens to a validator node.",
  },
  {
    icon: "https://staking.staked.us/hubfs/StakedTheme/svg/Why_2.svg",
    title: "Participation",
    details:
      "Validators perform network operations such as participating in consensus or signing in distributed signing groups.",
  },
  {
    icon: "https://staking.staked.us/hubfs/StakedTheme/svg/Why_3.svg",
    title: "Earning Rewards",
    details:
      "Inflation-funded block rewards and network-based transaction fees are earned in return for good validator performance.",
  },
  {
    icon: "	https://staking.staked.us/hubfs/StakedTheme/svg/Why_4.svg",
    title: "Slashing",
    details:
      "Staked funds are placed at risk and can be penalized, or slashed if a validator node is unavailable or acts maliciously.",
  },
];

const whyStakedWithUs = [
  // {
  //   icon: <i className="flaticon-money" />,
  //   title: "Custody Solutions",
  //   details: "Partnerships with Professional Custody Providers.",
  // },
  // {
  //   icon: <i className="flaticon-bar-chart" />,
  //   title: "SLA Agreements",
  //   details: "Fee reduction schedule if SLAs are not met.",
  // },
  // {
  //   icon: <i className="flaticon-notebook" />,
  //   title: "Reseller Staking",
  //   details: "Reward kickback through your customers. ",
  // },
  // {
  //   icon: <i className="flaticon-notebook" />,
  //   title: "Reward Reportings ",
  //   details: "Tax relevant documentation of your staking rewards.",
  // },
  // {
  //   icon: <i className="flaticon-notebook" />,
  //   title: "Institutional-grade Infrastrucutre",
  //   details: "Optimized for high security, redundancy, & availability.",
  // },
  // {
  //   icon: <i className="flaticon-notebook" />,
  //   title: "Whitelabel Validator Solutions",
  //   details: "Branded Infrastructure & Validation Services.",
  // },
  //   {
  //     icon: <i className="flaticon-notebook" />,
  //     title: "State-of-the-Art Legal Framework",
  //     details: "Individually adjustable and catered to your needs.",
  //   },
  //   {
  //     icon: <i className="flaticon-notebook" />,
  //     title: "Stable & Safe Jurisdiction",
  //     details: "We are registered and based in Munich, Germany.",
  //   },
];

// const css = [
//   {
//     avax: "background: linear-gradient(180deg, rgba(232, 65, 66, 0.2) 0%, rgba(255, 255, 255, 0) 100%);border-radius: 10px;",
//   },
// ];

const activeSymbols = [
  {
    icon: Avalanche_logo,
    title: "Avalanche",
    path: "/Avalanche",
    annualReward: "7% – 10%",
    className: "avax",
  },
  {
    icon: Ethereum_logo,
    title: "Ethereum",
    path: "/Ethereum",
    annualReward: "3% – 5%",
    className: "eth",
  },
  {
    icon: Polkadot_logo,
    title: "Polkadot",
    path: "/Polkadot",
    annualReward: "10% – 14%",
    className: "pol",
  },
];

const coomingSoonSymbols = [
  { icon: Solana_logo, title: "Solana", path: "/Solana" },
  { icon: Cardano_logo, title: "Cardano", path: "/Cardano" },
  { icon: Polygon_logo, title: "Polygon", path: "/Polygon" },
  { icon: Chainlink_logo, title: "Chainlink", path: "/Chainlink" },
  { icon: Cosmos_logo, title: "Cosmos", path: "/Cosmos" },
];
class Index3 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      awsService: new AWSService(),
      state: "",
      setState: "",
      fields: {},
      errors: {},
      Firstname: "",
      Lastname: "",
      Companyname: "",
      Email: "",
      // PreferredMethodpfCommunication:" ",
      PhoneNumber: "",
      WhatsApp: "",
      Telegram: "",
      WeChat: "",
      // Phone Number / Username
      Username: "",
      // Whichbestdescribesyou:" ",
      IndividualInvestor: "",
      CryptoorVentureFund: "",
      Exchange: "",
      SomethingElse: "",
      // Whichassetsdoyouhold:" ",
      Bitcoin: "",
      Ethereum: "",
      Polkadot: "",
      Tezos: "",
      Celo: "",
      Tron: "",
      Cosmos: "",
      Cardano: "",
      Keep: "",
      Kyber: "",
      DAI: "",
      USDC: "",
      BUSD: "",
      ICON: "",
      Algorand: "",
      Decred: "",
      Terra: "",
      Kusama: "",
      Horizen: "",
      Iris: "",
      VSystems: "",
      Edgeware: "",
      Kava: "",
      Livepeer: "",
      Orbs: "",
      // Total$ValueofAssets:"",
      $10Million: "",
      $1Million$10Million: "",
      $250k$1Million: "",
      $10k250k: "",
      $10k: "",
      //Text area
      Message: "",
      nodeInfoService: new NodeInfoService(),
      data: [],
    };
    this.submitData = this.submitData.bind(this);
  }

  notify = () => toast("Successful Send Email");

  handleReset = (e) => {
    this.setState({
      Firstname: "",
      Lastname: "",
      Companyname: "",
      Email: "",
      PhoneNumber: "",
      Username: "",
      IndividualInvestor: "",
      Bitcoin: "",
      $10Million: "",
      Message: "",
    });
  };

  SubmitBody = (e) => {
    // debugger;
    const body =
      "<html><body><p> FirstName: " +
      this.state.Firstname +
      " </p><p> LastName: " +
      this.state.Lastname +
      " </p><p> CompanyName: " +
      this.state.Companyname +
      " </p><p> Email:  " +
      this.state.Email +
      " </p><p> Preferred Method of Communication:  " +
      this.state.PhoneNumber +
      " </p><p> PhoneNumber / Username:  " +
      this.state.Username +
      " </p><p> Which best describes you?:  " +
      this.state.IndividualInvestor +
      " </p><p> Message: " +
      this.state.Message +
      "  </p></body></html>";
    this.state.nodeInfoService.SendgridEmail(body).then((res) => {
      // debugger;
      console.log("Json value of SendgridEmail");
      console.log(res);
      this.setState({ data: res });
    });
  };



  // Email Integration part
  handleChanges = (fieldName, e) => {
    console.log("abc", e, fieldName);
    this.setState({
      ...this.state,
      [fieldName]: e.target.value,
      // console.log([e.target.name]);
    });
  };

  submitData = (e) => {
    // alert("Form submitted");
    // debugger;
    console.log("SubmitData", e);
    e.preventDefault();
    if (this.validation()) {
      // debugger;
      let fields = {};
      fields["Firstname"] = "";
      fields["Lastname"] = "";
      fields["Companyname"] = "";
      fields["Email"] = "";
      fields["PhoneNumber"] = "";
      fields["Username"] = "";
      fields["IndividualInvestor"] = "";
      fields["Bitcoin"] = "";
      fields["$10Million"] = "";
      fields["Message"] = "";
      this.setState({ fields: fields });
      // alert("Form submitted");
      this.SubmitBody();
      this.handleReset();
      this.notify();
    }
    console.log("firstname:", this.state.Firstname);
    console.log("lastname:", this.state.Lastname);
    console.log("Companyname:", this.state.Companyname);
    console.log("Email:", this.state.Email);
    console.log("Preferred Method of Communication:", this.state.PhoneNumber);
    console.log("Phone Number / Username:", this.state.Username);
    console.log("Which best describes you?:", this.state.IndividualInvestor);
    console.log("Message:", this.state.Message);
    // console.log("Which assets do you hold?:", this.state.Bitcoin);
    // console.log("Total $ Value of Assets:", this.state.$10Million);
  };

  alerts = () => {
    alert("ffffffffff");
  };
  // Validation
  validation = () => {
    // debugger;
    let fields = this.state.fields;
    console.log("filelds", fields);
    let errors = {};
    let formIsValid = true;

    if (!this.state.Firstname) {
      formIsValid = false;
      errors["Firstname"] = "*Please enter your firstname.";
    }

    // if (typeof this.state.Firstname !== "undefined") {
    //   if (!fields["Firstname"].match(/^[a-zA-Z]*$/)) {
    //     formIsValid = false;
    //     errors["Firstname"] = "*Please enter alphabet characters only.";
    //   }
    // }

    if (!this.state.Lastname) {
      formIsValid = false;
      errors["Lastname"] = "*Please enter your lastname.";
    }

    if (!this.state.Companyname) {
      formIsValid = false;
      errors["Companyname"] = "*Please enter your companyname.";
    }

    if (!this.state.Email) {
      formIsValid = false;
      errors["Email"] = "*Please enter your email-ID.";
    }

    // if (typeof this.state.Email !== "undefined") {
    //   //regular expression for email validation
    //   var pattern = new RegExp(
    //     /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    //   );
    //   if (!pattern.test(fields["Email"])) {
    //     formIsValid = false;
    //     errors["Email"] = "*Please enter valid email-ID.";
    //   }
    // }

    if (!this.state.PhoneNumber) {
      formIsValid = false;
      errors["PhoneNumber"] = "*Please select any one.";
    }

    if (!this.state.Username) {
      formIsValid = false;
      errors["Username"] = "*Please enter Phonenumber or Username.";
    }

    if (!this.state.IndividualInvestor) {
      formIsValid = false;
      errors["IndividualInvestor"] = "*Please select any one.";
    }
    if (!this.state.Message) {
      formIsValid = false;
      errors["Message"] = "*Please enter your message.";
    }

    // if (!this.state.Bitcoin) {
    //   formIsValid = false;
    //   errors["Bitcoin"] = "*Please select any one.";
    // }

    // if (!this.state.$10Million) {
    //   formIsValid = false;
    //   errors["$10Million"] = "*Please select any one.";
    // }

    this.setState({
      errors: errors,
    });
    return formIsValid;
  };

  // dropdown part
  handleChange = (event, newValue) => {
    this.setState({ value: newValue });
  };

  componentDidMount() {
    const url = window.location.href;
    console.log(url);
    if (url.includes("access_token")) {
      console.log("access token available");
      const urlJson = window.location.href.split("&");
      console.log(urlJson);
      sessionStorage.setItem(
        "access_token",
        urlJson[1].replace("access_token=", "")
      );

      // window.location.href(config.site.BASE_URL);
      // this.state.awsService.getUserInfo().then((json) => {
      //   console.log("json of get User Info");
      //   console.log(json);
      //   sessionStorage.setItem("email", json.email);
      //   sessionStorage.setItem("sub", json.sub);
      //   window.open("https://coindelta.io/", "_self");
      // });

      // window.open(config.site.BASE_URL);
      this.state.awsService.getUserInfo().then((json) => {
        console.log("json of get User Info");
        console.log(json);
        sessionStorage.setItem("email", json.email);
        sessionStorage.setItem("sub", json.sub);
        window.location.href = config.site.BASE_URL;
        window.open(config.site.BASE_URL);
        // window.location.reload();
      });
      //

      // this.state.awsService.getUserInfo().then((json) => {
      //   console.log("json of get User Info");
      //   console.log(json);
      //   sessionStorage.setItem("email", json.email);
      //   sessionStorage.setItem("sub", json.sub);
      //   window.open("http://localhost:3001/", "_self");
      // });
    }

    var GalleryCategory = document.querySelectorAll(".box-hover");
    var fch = [].slice.call(GalleryCategory);

    for (var y = 0; y < fch.length; y++) {
      fch[y].addEventListener("mouseenter", function () {
        galleryActive(this);
      });
    }

    function galleryActive(current) {
      fch.forEach((el) => el.classList.remove("active"));

      setTimeout(() => {
        current.classList.add("active");
      }, 100);
    }
  }

  render() {
    return (
      <>
        <Header />

        <div className="page-content bg-white">
          <div className="owl-slider-banner main-slider dz-style-3">
            <HomeOwlSlider />
          </div>
          {/* <!-- contact area --> */}
          <div className="content-block">
            {/* <!-- Current Networks --> */}
            <div
              className="section-full bg-blue-light content-inner about-us-box"
              style={{
                // backgroundImage: "url(" + bg15 + ")",
                backgroundColor: "white",
                backgroundSize: "cover",
              }}
            >
              {/* {" "}
              <div className="col-md-12 col-lg-12 text-center section-head"></div> */}
              <div className="container">
                <div className="row">
                  <div className="col-md-12 col-lg-12 text-center section-head">
                    <h1
                      className="font-weight-700 max-w700 m-auto"
                      style={{
                        fontFamily: "Gilroy-Bold",
                        fontSize: "50px",
                        lineHeight: "50px",
                        color: "#202020",
                        textAlign: "center",
                      }}
                    >
                      Stake With Us
                    </h1>
                    <div
                      className="bg-primary dlab-separator"
                      style={{
                        backgroundColor: "#11F0B6",
                        marginTop: "25px",
                        height: "3px",
                      }}
                    ></div>
                    {/* <img style={{ marginTop: "25px" }} src={Vector} /> */}
                    <p
                      style={{
                        fontFamily: "Gilroy-Regular",
                        fontSize: "21px",
                        lineHeight: "32px",
                        color: "#363636",
                        marginTop: "15px",
                      }}
                      className="stake"
                    >
                      We operate validators on these projects. Token holders can
                      stake with us <br />
                      to help secure these networks and earn staking rewards.
                      Join the staking <br />
                      ecosystem today with Coindelta.
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-1" style={{ marginLeft: "50px" }}></div>
                  {activeSymbols &&
                    activeSymbols.map((symbol) => {
                      return (
                        <div
                          className="col-lg-3 col-md-3 col-sm-3"
                          key="Avalanche"
                        >
                          {/* <div className="dlab-box-bg m-b30 box-hover fly-box" style={"backgroundImage:url(" + work1 + ")"}> */}
                          <div className="dlab-box-bg m-b30  fly-box avax">
                            <div
                              className="icon-bx-wraper center p-tb15"
                              style={{
                                background:
                                  "linear-gradient(180deg, rgba(232, 65, 66, 0.2) 0%, rgba(255, 255, 255, 0) 100%)",
                                borderRadius: "10px",
                              }}
                            >
                              <div className="text-primary">
                                {/* <span className="icon-cell icon-lg">{<i className="flaticon-diamond" />}</span> */}
                                <img
                                  style={{ marginTop: "15px" }}
                                  src={symbol.icon}
                                  alt="Logo"
                                  height={"70px"}
                                  width={"70px"}
                                />
                              </div>
                              <br />
                              <div className="icon-content m-b5">
                                <h5
                                  className="dlab-tilte"
                                  style={{
                                    fontFamily: "Gilroy-Medium",
                                    fontSize: "29px",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {symbol.title}
                                </h5>
                                <p
                                  style={{
                                    marginBottom: "0px",
                                    fontFamily: "Gilroy-Regular",
                                    fontSize: "22px",
                                    lineHeight: "32px",
                                    color: "#363636",
                                  }}
                                >
                                  Annual Reward Rate
                                </p>
                                <p
                                  style={{
                                    marginBottom: "10px",
                                    fontFamily: "Gilroy-Regular",
                                    fontSize: "22px",
                                    lineHeight: "32px",
                                    color: "#363636",
                                  }}
                                >
                                  {symbol.annualReward}
                                </p>
                              </div>
                              <Link
                                style={{
                                  backgroundColor: "#0CF0B4",
                                  borderRadius: "5px",
                                  marginBottom: "15px",
                                  color: "black",
                                  fontFamily: "Gilroy-Medium",
                                }}
                                to={symbol.path}
                                className="site-button"
                              >
                                Stake Now
                              </Link>
                            </div>
                          </div>
                        </div>
                      );
                    })}

                  {/* End of Symbols */}
                </div>
              </div>
            </div>
            {/* <!-- Current Networks End --> */}

            {/* <!-- Future Networks --> */}
            <div
              className="section-full bg-blue-light content-inner about-us-box"
              style={{
                // backgroundImage: "url(" + bg15 + ")",
                backgroundSize: "cover",
              }}
            >
              <div className="container">
                <div className="row">
                  <div className="col-md-12 col-lg-12 text-center section-head">
                    <h1
                      className="font-weight-700 max-w700 m-auto"
                      style={{
                        fontFamily: "Gilroy-Bold",
                        fontSize: "50px",
                        lineHeight: "50px",
                        color: "#202020",
                        textAlign: "center",
                      }}
                    >
                      Future Networks
                    </h1>
                    <div
                      className="bg-primary dlab-separator"
                      style={{
                        backgroundColor: "#11F0B6",
                        marginTop: "25px",
                        height: "3px",
                      }}
                    ></div>
                    {/* <img style={{ marginTop: "25px" }} src={Vector} /> */}
                    <p
                      style={{
                        fontFamily: "Gilroy-Regular",
                        fontSize: "21px",
                        lineHeight: "32px",
                        color: "#363636",
                        marginTop: "15px",
                      }}
                      className="stake"
                    >
                      We will be operating nodes for the projects listed below.
                      We have only <br />
                      included the projects we are fully committed to, so please
                      expect an <br />
                      announcement from us in the near future.
                    </p>
                  </div>
                </div>
                <div>
                  <InfiniteCarousel
                    // breakpoints={[
                    //   {
                    //     breakpoint: 500,
                    //     settings: {
                    //       slidesToShow: 2,
                    //       slidesToScroll: 2,
                    //     },
                    //   },
                    //   {
                    //     breakpoint: 768,
                    //     settings: {
                    //       slidesToShow: 3,
                    //       slidesToScroll: 3,
                    //     },
                    //   },
                    // ]}
                    // dots={true}
                    showSides={true}
                    sidesOpacity={.5}
                    sideSize={.1}
                    slidesToScroll={3}
                    slidesToShow={3}
                    scrollOnDevice={true}
                  >
                    {coomingSoonSymbols &&
                      coomingSoonSymbols.map((symbol) => {
                        return (
                          // <div
                          //   className="col-lg-3 col-md-3 col-sm-3"
                          //   key="Avalanche"
                          // >

                          <div className="dlab-box-bg m-b30  fly-box">
                            <div className="icon-bx-wraper center p-tb30">
                              <div className="text-primary">
                                {/* <span className="icon-cell icon-lg">{<i className="flaticon-diamond" />}</span> */}
                                <img style={{ height: "70px", width: "20%" }}
                                  src={symbol.icon}
                                  alt="Logo"
                                // height={"70px"}
                                // width={"70px"}
                                />
                              </div>
                              <br />
                              <div className="icon-content m-b20">
                                <h5
                                  style={{
                                    fontFamily: "Gilroy-Bold",
                                  }}
                                  className="dlab-tilte"
                                >
                                  {symbol.title}
                                </h5>
                              </div>
                              <Button
                                className="site-button"
                                disable
                                style={{
                                  backgroundColor: "rgba(40, 17, 138, 0.08)",
                                  color: "black",
                                  fontFamily: "Gilroy-SemiBold",
                                }}
                              >
                                Coming Soon
                              </Button>
                            </div>
                          </div>
                        );
                      })}
                  </InfiniteCarousel>
                </div>
              </div>
            </div>
            {/* <div
              className="section-full bg-blue-light content-inner about-us-box"
              style={{
                backgroundColor: "white",
                backgroundSize: "cover",
              }}
            >
              <div className="container">
                <div className="row">
                  <div className="col-md-12 col-lg-12 text-center section-head">
                    <h1
                      className="font-weight-700 max-w700 m-auto"
                      style={{
                        fontFamily: "Gilroy-Bold",
                        fontSize: "50px",
                        lineHeight: "50px",
                        color: "#202020",
                        textAlign: "center",
                      }}
                    >
                      Why Choose Coindelta?
                    </h1>
                    <div
                      className="bg-primary dlab-separator"
                      style={{
                        backgroundColor: "#11F0B6",
                        marginTop: "25px",
                        height: "3px",
                      }}
                    ></div>
                    <p
                      style={{
                        fontFamily: "Gilroy-Regular",
                        fontSize: "22px",
                        lineHeight: "32px",
                        color: "#363636",
                        marginTop: "15px",
                      }}
                    >
                      Coindelta has been the trusted staking and defi lending
                      partner of
                      <br />
                      choice to the leading projects, investment funds,
                      exchanges, <br />
                      custodians and wallet providers in crypto.
                    </p>
                  </div>
                </div>
                <div className="row">
                  {gridBlog.map((item, index) => {
                    return (
                      <div className="col-lg-4 col-md-4 col-sm-4" key="index">
                        <div className="dlab-box-bg m-b30  fly-box avax">
                          <div
                            className="icon-bx-wraper center p-tb15"
                            style={{
                              background:
                                "linear-gradient(180deg, rgba(232, 65, 66, 0.2) 0%, rgba(255, 255, 255, 0) 100%)",
                              borderRadius: "10px",
                            }}
                          >
                            <div className="icon-md text-primary m-b20">
                              {" "}
                              <Link
                                to={"#"}
                                className="icon-cell text-primary"
                                style={{ color: "#f29f05" }}
                              >
                                {item.isIcon === false ? (
                                  item.icon
                                ) : (
                                  <img src={item.icon} />
                                )}
                              </Link>{" "}
                              <div></div>
                            </div>
                            <div className="icon-content m-b5">
                              <h5
                                className="dlab-tilte m-t0"
                                style={{
                                  fontFamily: "Gilroy-Bold",
                                  fontSize: "30px",
                                  textTransform: "capitalize",
                                }}
                              >
                                <p>{item.title}</p>
                              </h5>
                            </div>
                            <p className="m-b15">{item.details}</p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div> */}

            <div
              className="section-full bg-blue-light content-inner about-us-box"
              style={{
                backgroundColor: "white",
                backgroundSize: "cover",
              }}
            >
              <div className="container">
                <div className="row">
                  <div className="col-md-12 col-lg-12 text-center section-head">
                    <h1
                      className="font-weight-700 max-w700 m-auto"
                      style={{
                        fontFamily: "Gilroy-Bold",
                        fontSize: "50px",
                        lineHeight: "50px",
                        color: "#202020",
                        textAlign: "center",
                      }}
                    >
                      Why Choose Coindelta?
                    </h1>
                    <div
                      className="bg-primary dlab-separator"
                      style={{
                        backgroundColor: "#11F0B6",
                        marginTop: "25px",
                        height: "3px",
                      }}
                    ></div>
                    <p
                      style={{
                        fontFamily: "Gilroy-Regular",
                        fontSize: "22px",
                        lineHeight: "32px",
                        color: "#363636",
                        marginTop: "15px",
                      }}
                      // className="why-coindelta"
                    >
                      Coindelta has been the trusted staking and defi lending
                      partner of
                      <br />
                      choice to the leading projects, investment funds,
                      exchanges, <br />
                      custodians and wallet providers in crypto.
                    </p>
                  </div>
                </div>
                <div className="row" style={{ justifyContent: "center" }}>
                  {gridBlog.map((item, index) => (
                    <div
                      className="col-md-6 col-lg-4 col-sm-6 m-b30"
                      key={index}
                    >
                      
                      <div className="dlab-box-bg m-b30  fly-box">
                        <div style={{ position: 'relative', top: '25px',zIndex: '2',textAlign:"center" }}>
                       
                              {" "}
                              <Link
                                to={"#"}
                                className="icon-md icon-cell text-primary"
                              style={{ position: 'relative', top: '25px',zIndex: '2' }}
                              >
                                {item.isIcon === false ? (
                                  item.icon
                                ) : (
                                  <img src={item.icon} />
                                )}
                              </Link>{" "}
                              <div></div>
                            
                          <div
                            className="dlab-info p-a30 border-1 bg-white"
                            style={{
                              textAlign: "center",
                              height: "285px",
                              background:
                                "linear-gradient(180deg, rgba(244, 134, 70, 0.15) 0%, rgba(255, 255, 255, 0) 100%)",
                              borderRadius: "10px",
                            }}
                          >
                            
                            <h5 className="dlab-title m-t0">
                              <p style={{ fontFamily: "Gilroy-Bold" }}>
                                {item.title}
                              </p>
                            </h5>
                            <p className="m-b15">{item.details}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div
              className="section-full content-inner bg-img-fix"
              style={{
                // backgroundImage: "url(" + bg11 + ")",
                backgroundColor: "#f5f5fa",
                marginTop: "30px",
              }}
            >
              <div className="container">
                <div className="row">
                  <div
                    className="col-md-12 col-lg-12 text-center section-head"
                    style={{ marginBottom: "70px" }}
                  >
                    <h1
                      className="font-weight-700 max-w700 m-auto"
                      style={{
                        fontFamily: "Gilroy-Bold",
                        fontSize: "50px",
                        lineHeight: "50px",
                        color: "#202020",
                        textAlign: "center",
                        // borderBottom: "3px solid #11F0B6",
                      }}
                    >
                      How it Works
                    </h1>
                    <div
                      className="bg-primary dlab-separator"
                      style={{
                        backgroundColor: "#11F0B6",
                        marginTop: "25px",
                        height: "3px",
                      }}
                    ></div>
                    {/* <img style={{ marginTop: "25px" }} src={Vector} /> */}
                  </div>

                  <div className="row" style={{ marginTop: "-10px" }}>
                    <div className="col-lg-6">
                      <img style={{}} src={Group} />
                    </div>
                    <div className="col-lg-5">
                      <div
                        style={{
                          padding: "1rem",
                          backgroundColor: "white",
                          display: "flex",
                          borderRadius: "10px",
                        }}
                      >
                        <img
                          style={{ height: "40px", marginTop: "7px" }}
                          src={key}
                        />
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Typography>
                              <b
                                style={{
                                  marginBottom: "2px",
                                  fontFamily: "Gilroy-Bold",
                                  fontWeight: "bolder",
                                }}
                              >
                                Delegation
                              </b>
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Typography>
                              <p
                                style={{
                                  marginBottom: "2px",
                                  fontFamily: "Gilroy-Medium",
                                }}
                              >
                                Stakers sign a transaction with their private
                                key that bonds, or delegates their tokens to a
                                validator node.
                              </p>
                            </Typography>
                          </AccordionDetails>
                        </Accordion>
                      </div>
                      <br />
                      <div
                        style={{
                          padding: "1rem",
                          backgroundColor: "white",
                          borderRadius: "10px",
                          display: "flex",
                          marginTop: "-10px",
                        }}
                      >
                        <img
                          style={{ height: "40px", marginTop: "4px" }}
                          src={Participation}
                        />
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Typography>
                              <p
                                style={{
                                  marginBottom: "2px",
                                  fontFamily: "Gilroy-Bold",
                                  fontWeight: "bolder",
                                }}
                              >
                                Participation
                              </p>
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Typography>
                              <p
                                style={{
                                  marginBottom: "2px",
                                  fontFamily: "Gilroy-Medium",
                                }}
                              >
                                Validators perform network operations such as
                                participating in consensus or signing in
                                distributed signing groups.
                              </p>
                            </Typography>
                          </AccordionDetails>
                        </Accordion>
                      </div>
                      <br />
                      <div
                        style={{
                          padding: "1rem",
                          backgroundColor: "white",
                          borderRadius: "10px",
                          display: "flex",
                          marginTop: "-10px",
                        }}
                      >
                        <img
                          style={{ height: "40px", marginTop: "7px" }}
                          src={Earn}
                        />
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Typography>
                              <p
                                style={{
                                  marginBottom: "2px",
                                  fontFamily: "Gilroy-Bold",
                                  fontWeight: "bolder",
                                }}
                              >
                                Earn Rewards
                              </p>
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Typography>
                              <p
                                style={{
                                  marginBottom: "2px",
                                  fontFamily: "Gilroy-Medium",
                                }}
                              >
                                Inflation-funded block rewards and network-based
                                transaction fees are earned in return for good
                                validator performance.
                              </p>
                            </Typography>
                          </AccordionDetails>
                        </Accordion>
                      </div>
                      <br />
                      <div
                        style={{
                          padding: "1rem",
                          backgroundColor: "white",
                          borderRadius: "10px",
                          display: "flex",
                          marginTop: "-10px",
                        }}
                      >
                        <img
                          style={{ height: "40px", marginTop: "7px" }}
                          src={Slashing}
                        />
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Typography>
                              <p
                                style={{
                                  marginBottom: "2px",
                                  fontFamily: "Gilroy-Bold",
                                  fontWeight: "bolder",
                                }}
                              >
                                Slashing
                              </p>
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Typography>
                              <p
                                style={{
                                  marginBottom: "2px",
                                  fontFamily: "Gilroy-Medium",
                                }}
                              >
                                Staked funds are placed at risk and can be
                                penalized, or slashed if a validator node is
                                unavailable or acts maliciously.
                              </p>
                            </Typography>
                          </AccordionDetails>
                        </Accordion>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div id="contact" className="section-full content-inner bg-img-fix">
              <div className="container">
                <div className="row">
                  <div
                    className="col-md-12 col-lg-12 text-center section-head"
                    style={{ marginBottom: "70px" }}
                  >
                    <h1
                      className="font-weight-700 max-w700 m-auto"
                      style={{
                        fontFamily: "Gilroy-Bold",
                        fontSize: "50px",
                        lineHeight: "50px",
                        color: "#202020",
                        textAlign: "center",
                      }}
                    >
                      Contact
                    </h1>
                    <div
                      className="bg-primary dlab-separator"
                      style={{
                        backgroundColor: "#11F0B6",
                        marginTop: "25px",
                        height: "3px",
                      }}
                    ></div>
                    {/* <img style={{ marginTop: "25px" }} src={Vector} /> */}
                  </div>

                  <div className="col-lg-4" style={{ marginTop: "15px" }}>
                    <h4
                      style={{ fontFamily: "Gilroy-Bold" }}
                      id="Contact-details"
                    >
                      {" "}
                      Company Address
                    </h4>
                    <p
                      style={{
                        marginTop: "-15px",
                        color: "black",
                        fontFamily: "Gilroy-Regular",
                      }}
                    >
                      30 North Gould Street, Sheridan, WY, 82801
                    </p>
                    <h4 style={{ fontFamily: "Gilroy-Bold" }}> Careers</h4>
                    <p
                      style={{
                        marginTop: "-15px",
                        color: "black",
                        fontFamily: "Gilroy-Regular",
                      }}
                    >
                      Join our team by sending your CV and cover letter to
                      <a
                        href="mailto:careers@coindelta.io"
                        className="bottomurl"
                      >
                        {" "}
                        careers@coindelta.io
                      </a>
                    </p>
                    <h4 style={{ fontFamily: "Gilroy-Bold" }}>
                      {" "}
                      General Inquiries:
                    </h4>
                    <p
                      style={{
                        marginTop: "-15px",
                        color: "black",
                        // fontFamily: "Gilroy-Regular",
                      }}
                    >
                      <a
                        style={{ fontFamily: "Gilroy-Regular" }}
                        href="mailto:info@coindelta.io"
                        className="bottomurl"
                      >
                        {" "}
                        info@coindelta.io
                      </a>{" "}
                    </p>
                    <h4 style={{ fontFamily: "Gilroy-Bold" }}>
                      {" "}
                      Media Inquiries:
                    </h4>
                    <p style={{ marginTop: "-15px", color: "black" }}>
                      <a
                        style={{ fontFamily: "Gilroy-Regular" }}
                        href="mailto:press@coindelta.io"
                        className="bottomurl"
                      >
                        {" "}
                        press@coindelta.io
                      </a>
                    </p>
                  </div>

                  <div className="col-lg-8" style={{ marginTop: "15px" }}>
                    <form
                      className="inquiry"
                      action="script/contact.php"
                    // style={{ height: "650px" }}
                    >
                      <div className="dzFormMsg"></div>
                      {/* <h4 className="box-title m-t0 m-b30">
                        Contact Form<span className="bg-primary"></span>
                      </h4> */}
                      {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the</p> */}
                      <div className="row">
                        <div className="col-lg-6">
                          <TextField
                            style={{
                              width: "100%",
                            }}
                            id="outlined-basic"
                            label="First name"
                            variant="outlined"
                            type="text"
                            value={this.state.Firstname}
                            onChange={(e) => this.handleChanges("Firstname", e)}
                          />
                          <div className="errorMsg" style={{ color: "red" }}>
                            {this.state.errors.Firstname}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <TextField
                            style={{ width: "100%" }}
                            id="outlined-basic"
                            label="Last name"
                            variant="outlined"
                            type="text"
                            value={this.state.Lastname}
                            onChange={(e) => this.handleChanges("Lastname", e)}
                          />
                          <div className="errorMsg" style={{ color: "red" }}>
                            {this.state.errors.Lastname}
                          </div>
                        </div>
                      </div>
                      <br />
                      <div className="row" style={{ marginTop: "-10px" }}>
                        <div className="col-lg-12">
                          <TextField
                            style={{
                              width: "100%",
                            }}
                            id="outlined-basic"
                            label="Company name"
                            variant="outlined"
                            type="text"
                            value={this.state.Companyname}
                            onChange={(e) =>
                              this.handleChanges("Companyname", e)
                            }
                          />
                          <div className="errorMsg" style={{ color: "red" }}>
                            {this.state.errors.Companyname}
                          </div>
                        </div>
                      </div>
                      <br />
                      <div className="row" style={{ marginTop: "-10px" }}>
                        <div className="col-lg-12">
                          <TextField
                            style={{
                              width: "100%",
                            }}
                            id="outlined-basic"
                            label="Email"
                            variant="outlined"
                            type="text"
                            value={this.state.Email}
                            onChange={(e) => this.handleChanges("Email", e)}
                          />
                          <div className="errorMsg" style={{ color: "red" }}>
                            {this.state.errors.Email}
                          </div>
                        </div>
                      </div>
                      <br />
                      <div className="row" style={{ marginTop: "-10px" }}>
                        <div className="col-lg-12">
                          <div>
                            <FormControl
                              sx={{
                                width: "100%",
                              }}
                            >
                              <InputLabel id="demo-simple-select-label">
                                Preferred Method of Communication
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select-label"
                                // autoWidth
                                label="Preferred Method of Communication"
                                type="text"
                                MenuProps={MenuProps}
                                value={this.state.PhoneNumber}
                                onChange={(e) =>
                                  this.handleChanges("PhoneNumber", e)
                                }
                              >
                                <MenuItem value={"Phonenumber"}>
                                  Phone Number
                                </MenuItem>
                                <MenuItem value={"WhatsApp"}>WhatsApp</MenuItem>
                                <MenuItem value={"Telegram"}>Telegram</MenuItem>
                                <MenuItem value={"WeChat"}>WeChat</MenuItem>
                              </Select>
                            </FormControl>
                            <div className="errorMsg" style={{ color: "red" }}>
                              {this.state.errors.PhoneNumber}
                            </div>
                          </div>
                        </div>
                      </div>
                      <br />
                      <div className="row" style={{ marginTop: "-10px" }}>
                        <div className="col-lg-12">
                          <TextField
                            style={{
                              width: "100%",
                            }}
                            id="outlined-basic"
                            label="Phone Number / Username"
                            variant="outlined"
                            type="text"
                            value={this.state.Username}
                            onChange={(e) => this.handleChanges("Username", e)}
                          />
                          <div className="errorMsg" style={{ color: "red" }}>
                            {this.state.errors.Username}
                          </div>
                        </div>
                      </div>
                      <br />
                      <div className="row" style={{ marginTop: "-10px" }}>
                        <div className="col-lg-12">
                          <div>
                            <FormControl
                              sx={{
                                width: "100%",
                              }}
                            >
                              <InputLabel id="demo-simple-select-label">
                                Which best describes you?
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select-label"
                                // autoWidth
                                label="Which best describes you?"
                                type="text"
                                MenuProps={MenuProps}
                                value={this.state.IndividualInvestor}
                                onChange={(e) =>
                                  this.handleChanges("IndividualInvestor", e)
                                }
                              >
                                <MenuItem value={"IndividualInvestor"}>
                                  Individual Investor
                                </MenuItem>
                                <MenuItem value={"CryptoorVentureFund"}>
                                  Crypto or Venture Fund
                                </MenuItem>
                                <MenuItem value={"Exchange"}>Exchange</MenuItem>
                                <MenuItem value={"Something Else"}>
                                  Something Else
                                </MenuItem>
                              </Select>
                            </FormControl>
                            <div className="errorMsg" style={{ color: "red" }}>
                              {this.state.errors.IndividualInvestor}
                            </div>
                          </div>
                        </div>
                      </div>
                      <br />
                      <div className="row" style={{ marginTop: "-10px" }}>
                        <div className="col-lg-12">
                          <TextField
                            style={{
                              width: "100%",
                            }}
                            id="outlined-basic"
                            label="Message"
                            variant="outlined"
                            type="text"
                            minRows={4}
                            multiline
                            value={this.state.Message}
                            onChange={(e) => this.handleChanges("Message", e)}
                          />
                          <div className="errorMsg" style={{ color: "red" }}>
                            {this.state.errors.Message}
                          </div>
                        </div>
                      </div>
                      <br />
                      <div className="row" style={{ marginTop: "-10px" }}>
                        <div className="col-lg-12">
                          <form ref="form" onClick={this.submitData}>
                            <Button
                              className="site-button"
                              type="submit"
                              // disable
                              style={{
                                backgroundColor: "#218FCE",
                                color: "#fff",
                                width: "100%",
                                height: "60px",
                                textAlign: "center",
                              }}
                            >
                              SUBMIT
                            </Button>
                          </form>
                        </div>
                        <div>
        {/* <button type="button" onClick={() => {this.notify()}}>Notify!</button>
        <ToastContainer /> */}
      </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            {/* <div
              className="section-full content-inner bg-img-fix"
              style={{ backgroundImage: "url(" + bg11 + ")" }}
            > */}
            {/* <div className="container">
                      <div className="row">
                        <div className="col-md-12 col-lg-12 text-center section-head">
                          <h1 className="font-weight-700 max-w700 m-auto">
                            Why stake with us?
                          </h1>
                        </div>
                      </div>
                      <div className="row">
                        {whyStakedWithUs.map((item, index) => (
                          <div
                            className="col-md-3 col-lg-4 col-sm-6 m-b30"
                            key={index}
                            style={{ height: "300px" }}
                          >
                            <div
                              className="dlab-box"
                              style={{ height: "300px" }}
                            >
                              <div
                                className="dlab-info p-a30 border-1 bg-white"
                                style={{ height: "300px", textAlign: "center" }}
                              >
                                <div className="icon-md text-primary m-b20">
                                  {" "}
                                  <Link
                                    to={"#"}
                                    className="icon-cell text-primary"
                                    style={{ color: "#f29f05" }}
                                  >
                                    {item.icon}
                                  </Link>{" "}
                                  <div></div>
                                </div>
                                <h5 className="dlab-title m-t0">
                                  <Link to={"#"}>{item.title}</Link>
                                </h5>
                                <p className="m-b15">{item.details}</p>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div> */}
            {/* </div> */}

            {/* <!-- About Quotes End --> */}

            {/* <!-- contact area END --> */}
          </div>
          <ToastContainer position="bottom-center"/>
        </div>
        <Footer1 />
      </>
    );
  }
}

export default Index3;
