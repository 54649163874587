import React, { Fragment, Component } from "react";
import Header1 from "../../../Layout/Header1";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { TabContent, TabPane } from "reactstrap";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";

class StakeNowCLI extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "1",
      open: false,
    };
  }

  render() {
    return (
      <>
        <Header1 />
        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId="1">
            <div className="content-block">
              <div className="page-content bg-white">
                <div className="section-full content-inner">
                  <div className="container">
                    <div className="row">
                      <div
                        className="dlab-box-bg m-b30"
                        style={{
                          width: "92%",
                          marginTop: "130px",
                          marginLeft: "40px",
                          textAlign: "center",
                          boxShadow: "1px 1px 1px 1px #968c8c",
                          borderRadius: "3px",
                        }}
                      >
                        <h2
                          style={{
                            marginTop: "40px",
                            color: "#333B6D",
                            // textAlign: "center",
                            marginRight: "270px",
                          }}
                        >
                          CLI Method
                        </h2>
                        <p
                          className="mb-5"
                          style={{ marginTop: "-15px", marginLeft: "50px" }}
                        >
                          Awesome, everything that you need is below! Once
                          you're done, hit
                        </p>
                        <p style={{ marginTop: "-55px", marginRight: "180px" }}>
                          the button to go to your dashboard.
                        </p>
                        <div
                          className="dlab-box-bg m-b30"
                          style={{
                            width: "50%",
                            height: "45%",
                            marginTop: "40px",
                            marginLeft: "315px",
                            textAlign: "center",
                            boxShadow: "1px 1px 1px 1px #968c8c",
                            borderRadius: "3px",
                            backgroundColor: "#E9ECF5",
                          }}
                        >
                          <div
                            className="staking__cli"
                            style={{ color: "#349BF0", marginTop: "10px" }}
                          >
                            <p
                              style={{ textAlign: "left", marginLeft: "15px" }}
                            >
                              polkadot-js-api --seed "
                              <span style={{ color: "red" }}>
                                {"<YOUR_MNEMONIC>"}
                              </span>
                              "tx.staking.bond
                            </p>
                            <p
                              style={{
                                marginLeft: "-70px",
                                marginTop: "-30px",
                              }}
                            >
                              {" "}
                              <span style={{ color: "red" }}>
                                {"<CONTROLLER_ADDRESS>"}
                              </span>
                              <span style={{ color: "red" }}>
                                {"<NUMBER_OF_TOKENS>"}
                              </span>
                              Staked--
                            </p>
                            <p
                              style={{
                                marginTop: "-33px",
                                marginLeft: "-325px",
                              }}
                            >
                              {" "}
                              ws wss://rpc.polkadot.io
                            </p>
                            <p
                              style={{
                                marginLeft: "-175px",
                                marginTop: "-10px",
                              }}
                            >
                              {" "}
                              polkadot-js-api --seed "
                              <span style={{ color: "red" }}>
                                {"<YOUR_MNEMONIC>"}"
                              </span>
                            </p>
                            <p
                              style={{
                                marginTop: "-30px",
                                marginLeft: "-360px",
                              }}
                            >
                              tx.staking.nominate
                            </p>
                            <p style={{ marginTop: "-30px" }}>
                              {" "}
                              '["1WG3jyNqniQMRZGQUc7QD2kVLT8hkRPGMSqAb5XYQM1UDxN"]'
                            </p>
                            <p
                              style={{
                                marginTop: "-30px",
                                marginLeft: "-310px",
                              }}
                            >
                              --ws wss://rpc.polkadot.io
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </TabPane>
        </TabContent>
      </>
    );
  }
}
export default StakeNowCLI;
