import React, { Component } from "react";
import { Link } from "react-router-dom";
import Header from "./../../Layout/Header1";
import Footer from "./../../Layout/Footer1";

class PrivacyPolicy extends Component {
  render() {
    return (
      <>
        <Header />

        <div className="page-content bg-white">
          {/* <!-- contact area --> */}
          <div className="content-block">
            {/* <!-- Current Networks --> */}
            <div
              className="section-full bg-blue-light content-inner about-us-box"
              style={{ backgroundSize: "cover" }}
            >
              <div
                className="container"
                style={{
                  marginBottom: "0px",
                  color: "black",
                  fontFamily: "Cardillac-Bold,serif",
                  fontSize: "16px",
                }}
              >
                <h1 style={{ textAlign: "center" }}>PRIVACY POLICY</h1>
                <p>
                  This policy discloses the privacy practices for Coindelta, LLC
                  and the ways we collect, store, use, and protect your personal
                  and non-personal information and how you can correct or change
                  it. It does not apply to the practices of third parties that
                  we do not own or control, or to individuals that we do not
                  employ or manage. It is our intention to give you as much
                  control over your personal data as possible to preserve your
                  privacy, while still allowing us to utilize that information
                  in the course of our business to provide you a valuable
                  service. Coindelta, LLC recognizes the importance of
                  protecting the privacy of your personal information. We have
                  adopted a privacy policy to help ensure that your information
                  is kept secure. We follow all federal and state laws regarding
                  the protection of your personal information.
                </p>

                <p style={{ fontSize: "18px" }}>
                  <b>What information do we collect?</b>
                </p>
                <p style={{ marginBottom: "0px" }}>
                  You will be asked to furnish some of your personal information
                  at the time you register for an account with Coindelta.
                </p>
                <p>
                  If you contact us by telephone, email, or letter to provide
                  feedback, comments, or inputs, or for any reason, we may keep
                  a record of that correspondence and collect your personal
                  information to process your enquiries, respond to your
                  requests, and improve our services.
                </p>

                <p style={{ fontSize: "18px" }}>
                  <b>How do I access and update my information?</b>
                </p>
                <p>
                  You may correct any information we have about you through your
                  profile page.
                </p>

                <p style={{ fontSize: "18px" }}>
                  <b>What information do we share?</b>
                </p>
                <p>
                  We reserve the right to disclose your personally identifiable
                  information as required by law and when we believe that
                  disclosure is necessary to protect our rights and/or to comply
                  with a judicial proceeding, court order, or legal process
                  served on us. Otherwise, we do not disclose your information
                  to nonaffiliated third parties except as required by law.
                </p>

                <p style={{ fontSize: "18px" }}>
                  <b>Information Security</b>
                </p>
                <p>
                  We restrict access to electronic versions of the information
                  to authorized persons only.
                </p>
              </div>
            </div>
            {/* <!-- About Quotes End --> */}
          </div>
          {/* <!-- contact area END --> */}
        </div>

        <Footer />
      </>
    );
  }
}
export default PrivacyPolicy;
