import React, { Fragment, Component } from "react";
import Header1 from "../../../Layout/Header1";
import DialogTitle from "@mui/material/DialogTitle";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import { TabContent, TabPane } from "reactstrap";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";

class ConnectWallet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "1",
      open: false,
    };
  }

  render() {
    return (
      <>
        <Header1 />
        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId="1">
            <div className="content-block">
              <div className="page-content bg-white">
                <div className="section-full content-inner">
                  <div className="container">
                    <div className="row">
                      <div
                        className="dlab-box-bg m-b30"
                        style={{
                          width: "92%",
                          marginTop: "150px",
                          marginLeft: "40px",
                          textAlign: "center",
                          boxShadow: "1px 1px 1px 1px #968c8c",
                          borderRadius: "3px",
                        }}
                      >
                        <h2
                          style={{
                            marginTop: "35px",
                            color: "#333B6D",
                            marginLeft: "-170px",
                          }}
                        >
                          Connect your wallet
                        </h2>
                        <p
                          className="mb-5"
                          style={{ marginTop: "-15px", marginLeft: "12px" }}
                        >
                          Connect and unlock your Ledger device, then open the
                          Polkadot app.
                        </p>
                        <div className="d-flex align-items-center mt-5 mb-4">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="19%"
                            height="37"
                            viewBox="0 0 19 37"
                            style={{ marginLeft: "200px", marginTop: "-10px" }}
                          >
                            <g fill="#333B6D">
                              <path d="M7.05919958,36.5279978 L11.3403993,36.5279978 C13.1435192,36.5279978 14.8731991,35.8107979 16.148399,34.5357979 C17.423599,33.260798 18.1405989,31.5311181 18.1405989,29.7277982 L18.1405989,11.3917993 C18.1405989,10.7293194 17.603079,10.1917994 16.940599,10.1917994 L15.0765191,10.1917994 L15.0765191,1.47139991 C15.0765191,0.808919952 14.5389991,0.271399984 13.8765192,0.271399984 L4.52331973,0.271399984 C3.86083977,0.271399984 3.3233198,0.808919952 3.3233198,1.47139991 L3.3233198,10.1917994 L1.45923991,10.1917994 C0.796759953,10.1917994 0.259239985,10.7293194 0.259239985,11.3917993 L0.259239985,29.7277982 C0.259239985,31.5309181 0.976439942,33.260598 2.25143987,34.5357979 C3.52643979,35.8109979 5.25611969,36.5279978 7.05943958,36.5279978 L7.05919958,36.5279978 Z M5.72327966,2.67159984 L12.6764792,2.67159984 L12.6764792,10.1919994 L5.72327966,10.1919994 L5.72327966,2.67159984 Z M7.93575953,25.9279985 L10.4685594,25.9279985 C12.1794793,25.9279985 13.5685592,27.3155184 13.5685592,29.0279983 C13.5685592,30.7404782 12.1794793,32.1279981 10.4685594,32.1279981 L7.93103953,32.1279981 C6.22011963,32.1279981 4.83103971,30.7404782 4.83103971,29.0279983 C4.83103971,27.3155184 6.22011963,25.9279985 7.93103953,25.9279985 L7.93575953,25.9279985 Z"></path>
                              <path d="M7.70479954 2.88759983C7.04071958 2.88759983 6.50479961 3.4251198 6.50479961 4.08759976L6.50479961 7.97979952C6.50479961 8.64227948 7.04071958 9.17979945 7.70479954 9.17979945 8.3672795 9.17979945 8.90479947 8.64227948 8.90479947 7.97979952L8.90479947 4.08759976C8.90479947 3.4251198 8.3672795 2.88759983 7.70479954 2.88759983zM10.6951994 2.88759983C10.0327194 2.88759983 9.49519943 3.4251198 9.49519943 4.08759976L9.49519943 7.97979952C9.49519943 8.64227948 10.0327194 9.17979945 10.6951994 9.17979945 11.3592793 9.17979945 11.8951993 8.64227948 11.8951993 7.97979952L11.8951993 4.08759976C11.8951993 3.4251198 11.3592793 2.88759983 10.6951994 2.88759983z"></path>
                            </g>
                          </svg>
                          {/* <div className="mr-4"></div> */}
                          <div className="staking__loading">
                            <CircularProgress
                              style={{ color: "#333B6D", marginLeft: "-90px" }}
                            />
                          </div>
                        </div>
                        <div className="mt-4 mb-2">
                          <div
                            className="alert alert-danger text-center"
                            role="alert"
                            style={{
                              marginLeft: "291px",
                              width: "52%",
                              marginTop: "-10px",
                            }}
                          >
                            <p>No device selected.</p>
                          </div>
                        </div>
                        <div className="mt-4 mb-2">
                          <div
                            className="alert alert-danger text-center"
                            role="alert"
                            style={{
                              marginLeft: "291px",
                              width: "52%",
                              marginTop: "-10px",
                            }}
                          >
                            <p>
                              "Failed to execute 'requestDevice' on 'USB': Must
                              be handling a user gesture to show a permission
                              request."
                            </p>
                          </div>
                        </div>
                        <br />

                        {/* <Link
                              style={{
                                width: "48%",
                                textAlign: "center",
                                backgroundColor: "#fff",
                                marginTop: "-18px",
                                border: "1px solid blue",
                                color: "#23439E",
                                borderColor: "#23439E",
                              }}
                              to={"./StakeNowCLI"}
                              className="site-button"
                            >
                              NO THANKS, I PREFER CLI
                            </Link> */}
                        <Button
                          className="site-button"
                          disabled={true}
                          style={{
                            // backgroundColor: "transparent",
                            color: "#fff",
                            marginTop: "-18px",
                            width: "13%",
                            textAlign: "center",
                            border: "1px solid blue",
                            borderColor: "#23439E",
                            backgroundColor: "#23439E",
                            marginLeft: "425px",
                          }}
                        >
                          NEXT
                        </Button>
                        <br />
                        <br />
                        <br />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </TabPane>
        </TabContent>
      </>
    );
  }
}
export default ConnectWallet;
