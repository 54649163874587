import React, { Component } from "react";
import HeaderCompany from "../../Layout/HeaderCompany";
import Footer2 from "../../Layout/footer2";

class HowItWorks extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeTab: "1",
        };
    }

    render() {
        return (
            <>
                <HeaderCompany />
                <div
                    className="page-content"
                    style={{

                        borderBottom: "1px solid white",
                    }}
                >
                    <div className="content-block">
                        <div className="section-full content-inner">
                            <p style={{ color: "black" }}>1. 26/5/2022 4:50 PM</p>
                            <p style={{ color: "black" }}>2. 27/5/2022 3:00 PM</p>
                            <p style={{ color: "black" }}>3. 27/5/2022 3:05 PM</p>
                            <p style={{ color: "black" }}>4. 30/5/2022 10:52 AM</p>
                            <p style={{ color: "black" }}>5. 30/5/2022 11:10 AM</p>
                            <p style={{ color: "black" }}>6. 30/5/2022 11:20 AM</p>
                            <p style={{ color: "black" }}>7. 07/11/2022 3:20 PM</p>
                            <p style={{ color: "black" }}>8. 07/11/2022 3:35 PM</p>
                            <p style={{ color: "black" }}>9. 07/11/2022 3:35 PM</p>
                            <p style={{ color: "black" }}>10. 07/11/2022 3:55 PM</p>
                            <p style={{ color: "black" }}>11. 07/11/2022 4:00 PM</p>
                            <p style={{ color: "black" }}>12. 07/13/2022 3:50 PM</p>
                        </div>
                    </div>
                </div>
                <Footer2 />
            </>
        );
    }
}
export default HowItWorks;
