import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from './../Layout/Header1';
import Footer from './../Layout/Footer1';
import { CognitoUserPool, CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import { Backdrop } from '@material-ui/core';
import config from '../../config';

const iconBlog = [
    {
        icon: <i className="fa fa-gift" />, title: 'Free shipping on orders $60+',
        para: 'Order more than 60$ and you will get free shippining Worldwide. More info.',
    },
    {
        icon: <i className="fa fa-plane" />, title: 'Worldwide delivery',
        para: 'We deliver to the following countries: USA, Canada, Europe, Australia',
    },
    {
        icon: <i className="fa fa-history" />, title: '60 days money back guranty!',
        para: 'Not happy with our product, feel free to return it, we will refund 100% your money!',
    },
];
const poolData = {
    UserPoolId: "ap-south-1_E9TPrtUiX",
    ClientId: "2fpeomh529ep92edbfuqa5jokj"
};

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userPool: new CognitoUserPool(poolData),
            email: "",
            password: "",
            errorMessage: false,
            error: false,
            emailRequired: false,
            passwordRequired: false,
            loading: false,

            passwordError: false,
            mailError: false,
            alert: false,
        }
    }

    componentDidMount() {
        // console.log("componentDidMount in login js")
        // console.log(config.awsConfig.LoginURL)
        // console.log(config.awsConfig.ClientId)
        // console.log(config.site.BASE_URL)
        const url = config.awsConfig.LoginURL + "/login?client_id=" + config.awsConfig.ClientId + "&response_type=token&scope=aws.cognito.signin.user.admin+phone+email+openid+profile&redirect_uri=" + config.site.BASE_URL;

        // console.log(url);
        window.open(url, "_self")
    }

    loginHandle() {
        this.setState({
            loading: true
        })
        // setLoading(true);
        let validationArr = [];

        if (this.state.email) {
            validationArr.push(false);
            this.setState({
                emailRequired: false
            })
            // setEmailRequired(false);
        } else {
            validationArr.push(true);
            this.setState({
                emailRequired: true
            })
            // setEmailRequired(true);
        }

        if (this.state.password) {
            validationArr.push(false);
            this.setState({
                passwordRequired: false
            })
            // setPasswordRequired(false);
        } else {
            validationArr.push(true);
            this.setState({
                passwordRequired: true
            })
            // setPasswordRequired(true);
        }

        if (!validationArr.includes(true)) {
            const user = new CognitoUser({
                Username: this.state.email,
                Pool: this.state.userPool,
            })

            const authDetails = new AuthenticationDetails({
                Username: this.state.email,
                Password: this.state.password,
                // ValidationData:
            })

            user.authenticateUser(authDetails, {
                onSuccess: data => {
                    console.log("On Success: ", data);
                    sessionStorage.setItem("access_token", data.accessToken.jwtToken);
                    sessionStorage.setItem("refresh_token", data.refreshToken.token);
                    sessionStorage.setItem("email", data.idToken.payload.email);
                    sessionStorage.setItem("subId", data.idToken.payload.sub);
                    this.props.history.push("/");
                    // setLoading(false);
                },
                onFailure: err => {
                    console.log("On Error : ", err)
                    console.log("On Error : ", err.message)
                    if (err) {
                        if (err.message.includes("Incorrect username or password.")) {
                            this.setState({
                                error: true,
                                errorMessage: "Incorrect username or password."
                            })
                            // setError(true);
                            // setErrorMessage("Incorrect username or password.");
                        } else if (err.message.includes("User is not confirmed.")) {
                            this.setState({
                                error: true,
                                errorMessage: "User is not confirmed."
                            })
                            // setError(true);
                            // setErrorMessage("User is not confirmed.");
                        } else {
                            this.setState({
                                error: false,
                                errorMessage: ""
                            })
                            // setError(false);
                            // setErrorMessage();
                        }
                    }
                    this.setState({
                        loading: false
                    })
                },
                newPasswordRequired: data => {
                    console.log("New Password Required: ", data)
                    this.setState({
                        loading: false
                    })
                }
            })
        } else {
            this.setState({
                loading: false
            })
        }
    };

    render() {
        return (
            <>
                <Header />
                <Backdrop open={true} style={{ zIndex: 999, color: "#fff" }}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                {/* <div className="page-content bg-white">
                    {/* <!-- inner page banner --> */}
                {/* <div className="dlab-bnr-inr overlay-primary bg-pt" style={{ backgroundImage: "url(" + bnr + ")" }}>
                        <PageTitle motherMenu='Login' activeMenu='Login' />
                    </div> */}
                {/* <!-- inner page banner END --> */}
                {/* <!-- contact area -->  
                    <div className="section-full content-inner shop-account">
                        {/* <!-- Product -->  
                        <div className="container">
                            <Backdrop open={this.state.loading} style={{ zIndex: 999, color: '#fff' }} >
                                <CircularProgress color="inherit" />
                            </Backdrop>
                            <div className="row">
                                <div className="col-md-12 text-center">
                                    <h2 className="font-weight-700 m-t0 m-b30">Login Your Account</h2>
                                </div>
                            </div>
                            <div>
                                <div className="max-w500 m-auto m-b30">
                                    <div className="p-a30 border-1 seth">
                                        <div className="tab-content nav">
                                            <div id="login" className="tab-pane active col-12 p-a0 ">
                                                {/* <h4 className="font-weight-700">LOGIN</h4>  
                                                {/* <p className="font-weight-600">If you have an account with us, please log in.</p>  
                                                <div className="form-group">
                                                    {this.state.error ?
                                                        <Stack sx={{ width: '100%' }} spacing={2}>
                                                            <Alert severity="error" style={{ fontSize: "13px" }}><b>{this.state.errorMessage}</b></Alert>
                                                        </Stack>
                                                        : ""}
                                                    <label className="font-weight-700">E-MAIL *</label>
                                                    <input name="dzName" required="" className="form-control" placeholder="Your Email Id" type="email" value={this.state.email} onChange={(event) => {
                                                        // console.log(event.target.value)
                                                        this.setState({
                                                            email: event.target.value,
                                                        })
                                                    }} />
                                                    {this.state.passwordRequired ?
                                                        <p style={{ fontSize: "12px", color: "red" }}> Email Required </p>
                                                        : ""}
                                                </div>
                                                <div className="form-group">
                                                    <label className="font-weight-700">PASSWORD *</label>
                                                    <input name="dzName" required="" className="form-control " placeholder="Type Password" type="password" value={this.state.password} onChange={(event) => {
                                                        // console.log(event.target.value)
                                                        this.setState({
                                                            password: event.target.value,
                                                        })
                                                    }} />
                                                    {this.state.passwordRequired ?
                                                        <p style={{ fontSize: "12px", color: "red" }}> Password Required </p>
                                                        : ""}
                                                </div>
                                                <div className="text-left">
                                                    <button className="site-button m-r5 button-lg radius-no" style={{ width: "100%", backgroundColor: "#f29f05", borderRadius: "5px" }} onClick={() => {
                                                        console.log("clicked")
                                                        this.loginHandle();
                                                    }}>login</button>
                                                    {/* <a data-toggle="tab" href="#forgot-password" className="m-l5" style={{marginTop: "20px"}}><i className="fa fa-unlock-alt"></i> Forgot Password</a>  
                                                </div>
                                            </div>
                                            <form id="forgot-password" className="tab-pane fade  col-12 p-a0">
                                                <h4 className="font-weight-700">FORGET PASSWORD ?</h4>
                                                <p className="font-weight-600">We will send you an email to reset your password. </p>
                                                <div className="form-group">
                                                    <label className="font-weight-700">E-MAIL *</label>
                                                    <input name="dzName" required="" className="form-control" placeholder="Your Email Id" type="email" />
                                                </div>
                                                <div className="text-left">
                                                    <a className="site-button outline gray button-lg radius-no" data-toggle="tab" href="#login">Back</a>
                                                    <button className="site-button pull-right button-lg radius-no">Submit</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- Product END -->  
                    </div>
                    { /* <-- Icon Blog and Order detail-->  
                    {/* <OrderDetail />  
                    {/* <-- Icon Blog and Order detail End-->  

                    {/* <!-- contact area  END -->  
                </div> */}

                {/* <Footer /> */}
            </>
        )
    }
}

function OrderDetail() {
    return (
        <>
            <div className="section-full p-t50 p-b20 bg-primary-dark text-white shop-action">
                <div className="container">
                    <div className="row">
                        {iconBlog.map((data, index) => (
                            <div className="col-lg-4 col-md-4 col-sm-4" key={index}>
                                <div className="icon-bx-wraper left m-b30">
                                    <div className="icon-md text-black radius">
                                        <Link to={"#"} className="icon-cell text-white">{data.icon}</Link>
                                    </div>
                                    <div className="icon-content">
                                        <h5 className="dlab-tilte">{data.title}</h5>
                                        <p>{data.para}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    )
}
export { OrderDetail };
export default Login;