import React, { Fragment, Component } from "react";
import Header1 from "../../../Layout/Header1";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { TabContent, TabPane } from "reactstrap";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";

class StakingPair extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "1",
      open: false,
    };
  }

  render() {
    return (
      <>
        <Header1 />
        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId="1">
            <div className="content-block">
              <div className="page-content bg-white">
                <div className="section-full content-inner">
                  <div className="container">
                    <div className="row">
                      <div
                        className="dlab-box-bg m-b30"
                        style={{
                          width: "92%",
                          marginTop: "150px",
                          marginLeft: "40px",
                          textAlign: "center",
                          boxShadow: "1px 1px 1px 1px #968c8c",
                          borderRadius: "3px",
                        }}
                      >
                        <h2
                          style={{
                            marginTop: "40px",
                            color: "#333B6D",
                            marginLeft: "-140px",
                          }}
                        >
                          Choose the staking pair
                        </h2>
                        <p
                          className="mb-5"
                          style={{ marginTop: "-15px", marginLeft: "-40px" }}
                        >
                          Please note that the stash and the controller account
                          can't be the
                        </p>
                        <br />
                        <p
                          style={{
                            marginTop: "-80px",
                            marginRight: "480px",
                          }}
                        >
                          same.
                        </p>
                        <p
                          style={{
                            marginTop: "-15px",
                            marginLeft: "-40px",
                          }}
                        >
                          No addresses detected on the Extension/Ledger. Please
                          try again!
                        </p>
                        <br />
                        <br />
                        <br />
                        <br />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </TabPane>
        </TabContent>
      </>
    );
  }
}
export default StakingPair;
