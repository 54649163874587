import React from "react";
import config from "../config";

export default class NodeInfoService extends React.Component {
  getNodeInfo = async () => {
    return fetch("https://api.coindelta.io/api/getValidatorRewards/NodeID-9mFVn1jN8bWqyuzshCJVeoutydK5XHpiN", {
      method: "GET",
    })
      .then((res) => (res.ok ? res : Promise.reject(res)))
      .then((res) => res.json());
  };

  ethNodeInfo = async () => {
    return fetch(
      "https://o86yhgu7q6.execute-api.us-east-2.amazonaws.com/Eth/eth",
      {
        method: "POST",
      }
    )
      .then((res) => (res.ok ? res : Promise.reject(res)))
      .then((res) => res.json());
  };
  // https://5yofxbxde2.execute-api.ap-south-1.amazonaws.com/milind/apicheck
  // https://2ppobo2obi.execute-api.ap-south-1.amazonaws.com/api/api1
  // https://2ppobo2obi.execute-api.ap-south-1.amazonaws.com/api/api2
  // https://2ppobo2obi.execute-api.ap-south-1.amazonaws.com/test/api1
  // https://2ppobo2obi.execute-api.ap-south-1.amazonaws.com/test/api2
  // https://njdou3paf5.execute-api.ap-south-1.amazonaws.com/sedapi/sendgrid
  // Access-Control-Allow-Origin:  *
  SendgridEmail = async (body) => {
    console.log("body", body);
    return fetch(
      "https://tgbpk3vjv8.execute-api.us-west-2.amazonaws.com/ses/coind",
      {
        method: "POST",
        body: JSON.stringify({
          content: body,
        }),
        headers: {
          "Content-type": "application/json ",
          // "Access-Control-Allow-Origin": "*",
        },
      }
    )
      .then((res) => (res.ok ? res : Promise.reject(res)))
      .then((res) => res.json());
  };
}
