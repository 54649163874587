import React, { Component } from "react";
import Header from "./../Layout/Header1";
import Footer from "./../Layout/Footer1";
import PageTitle from "./../Layout/PageTitle";
import { OrderDetail } from "./Login";
import { CognitoUserPool } from "amazon-cognito-identity-js";
import CircularProgress from "@mui/material/CircularProgress";
import { Backdrop } from "@material-ui/core";
import bnr from "./../../images/banner/bnr6.jpg";

const poolData = {
  UserPoolId: "ap-south-1_E9TPrtUiX",
  ClientId: "2fpeomh529ep92edbfuqa5jokj"
};

class Register extends Component {
  constructor(props) {
    super(props);
    console.log(this.props);
    this.state = {
      userPool: new CognitoUserPool(poolData),
      email: "",
      password: "",
      passwordError: false,
      mailError: false,
      errorPasswordMessage: false,
      errorEmailMessage: false,
      loading: false,
      alert: false,
    };
  }

  onSubmit() {
    this.setState({
      loading: true,
    });

    let validationArr = [];

    if (this.state.email) {
      validationArr.push(false);
      this.setState({
        mailError: false,
      });
      // setMailError(false);
    } else {
      validationArr.push(true);
      this.setState({
        mailError: true,
        errorEmailMessage: "Email is Required",
      });
      // setMailError(true);
      // setErrorPasswordMessage("Email is Required");
    }

    if (this.state.password) {
      validationArr.push(false);
      this.setState({
        passwordError: false,
      });
      // setPasswordError(false);
    } else {
      validationArr.push(true);
      this.setState({
        passwordError: true,
        errorPasswordMessage: "password is Required",
      });
      // setPasswordError(true);
      // setErrorPasswordMessage("Email is Required");
    }

    if (!validationArr.includes(true)) {
      this.state.userPool.signUp(
        this.state.email,
        this.state.password,
        [],
        null,
        (err, data) => {
          console.log(data);
          if (data && data.user && data.userSub) {
            console.log("user is Registered...");
            // setAlert(true);
            setTimeout(() => {
              this.props.history.push("/confirm");
            }, 1000);
          }

          if (err) {
            console.log(err);
            console.log(err.message);
            console.log(
              err.message.includes("Password must have numeric characters")
            );
            console.log(
              err.message.includes("Password must have uppercase characters")
            );
            console.log(
              err.message.includes("Password must have lowercase characters")
            );
            console.log(
              err.message.includes("Password must have symbol characters")
            );
            if (err.message.includes("Password must have numeric characters")) {
              this.setState({
                passwordError: true,
                errorPasswordMessage: "Password must have numeric characters",
              });
              // setPasswordError(true);
              // setErrorPasswordMessage("Password must have numeric characters");
            } else if (
              err.message.includes("Password must have uppercase characters")
            ) {
              this.setState({
                passwordError: true,
                errorPasswordMessage: "Password must have uppercase characters",
              });
              // setPasswordError(true);
              // setErrorPasswordMessage("Password must have uppercase characters");
            } else if (
              err.message.includes("Password must have lowercase characters")
            ) {
              this.setState({
                passwordError: true,
                errorPasswordMessage: "Password must have lowercase characters",
              });
              // setPasswordError(true);
              // setErrorPasswordMessage("Password must have lowercase characters");
            } else if (
              err.message.includes("Password must have symbol characters")
            ) {
              this.setState({
                passwordError: true,
                errorPasswordMessage:
                  "Password must have symbol/special characters",
              });
              // setPasswordError(true);
              // setErrorPasswordMessage("Password must have symbol/special characters");
            } else {
              this.setState({
                passwordError: false,
              });
              // setPasswordError(false);
            }

            if (err.message.includes("Provide valid Email ID")) {
              this.setState({
                mailError: true,
                errorPasswordMessage: "Provide valid Email ID",
              });
              // setMailError(true);
              // setErrorPasswordMessage("Username should be an email");
            } else if (
              err.message.includes(
                "An account with the given email already exists"
              )
            ) {
              this.setState({
                mailError: true,
                errorPasswordMessage: "Email already exists",
              });
              // setMailError(true);
              // setErrorPasswordMessage("Email already exists");
            } else {
              this.setState({
                mailError: false,
              });
              // setMailError(false);
            }
          }
          this.setState({
            loading: false,
          });
        }
      );
    } else {
      this.setState({
        loading: false,
      });
    }
  }

  render() {
    return (
      <>
        <Header />

        <div className="page-content bg-white">
          <Backdrop
            open={this.state.loading}
            style={{ zIndex: 999, color: "#fff" }}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          {/* <!-- inner page banner --> */}
          {/* <div className="dlab-bnr-inr overlay-primary bg-pt" style={{ backgroundImage: "url(" + bnr + ")" }}>
        <PageTitle motherMenu='Login' activeMenu='Login' />
    </div> */}
          {/* <!-- inner page banner END --> */}
          {/* <!-- contact area --> */}
          <div className="section-full content-inner shop-account">
            {/* <!-- Product --> */}
            <div className="container">
              <div className="row">
                <div className="col-md-12 text-center">
                  <h2 className="font-weight-700 m-t0 m-b30">
                    Create An Account
                  </h2>
                </div>
              </div>
              <div>
                <div className="max-w500 m-auto m-b30">
                  <div className="p-a30 border-1 seth">
                    <div className="tab-content nav">
                      <div id="login" className="tab-pane active col-12 p-a0 ">
                        {/* <h4 className="font-weight-700">LOGIN</h4> */}
                        {/* <p className="font-weight-600">If you have an account with us, please log in.</p> */}
                        <div className="form-group">
                          <label className="font-weight-700">E-MAIL *</label>
                          <input
                            name="dzName"
                            required=""
                            className="form-control"
                            placeholder="Your Email Id"
                            type="email"
                            value={this.state.email}
                            onChange={(event) => {
                              // console.log(event.target.value)
                              this.setState({
                                email: event.target.value,
                              });
                            }}
                          />
                          {this.state.mailError ? (
                            <p style={{ fontSize: "12px", color: "red" }}>
                              {this.state.errorEmailMessage}
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="form-group">
                          <label className="font-weight-700">PASSWORD *</label>
                          <input
                            name="dzName"
                            required=""
                            className="form-control "
                            placeholder="Type Password"
                            type="password"
                            value={this.state.password}
                            onChange={(event) => {
                              // console.log(event.target.value)
                              this.setState({
                                password: event.target.value,
                              });
                            }}
                          />
                          {console.log(
                            "password error value is.." +
                            this.state.passwordError
                          )}
                          {this.state.passwordError ? (
                            <p style={{ fontSize: "12px", color: "red" }}>
                              {this.state.errorPasswordMessage}
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="text-left">
                          <button
                            className="site-button m-r5 button-lg radius-no"
                            style={{
                              width: "100%",
                              backgroundColor: "#f29f05",
                              borderRadius: "5px",
                            }}
                            onClick={() => {
                              console.log("clicked");
                              this.onSubmit();
                            }}
                          >
                            SIGN UP
                          </button>
                          {/* <a data-toggle="tab" href="#forgot-password" className="m-l5" style={{marginTop: "20px"}}><i className="fa fa-unlock-alt"></i> Forgot Password</a> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- Product END --> */}
          </div>
          {/* <-- Icon Blog and Order detail--> */}
          {/* <OrderDetail /> */}
          {/* <-- Icon Blog and Order detail End--> */}

          {/* <!-- contact area  END --> */}
        </div>

        <Footer />
      </>
    );
  }
}
export default Register;
