import React, { Component } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";

import Banner from "../../images/Banner.png";
import Sider1 from "./../../images/World_Map.svg";
import Slider2 from "./../../images/main-slider/slide2.jpg";
import Slider3 from "./../../images/main-slider/slide3.jpg";

function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <div className="owl-nav">
      <div className="owl-next la la-angle-right" onClick={onClick} />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <div className="owl-nav">
      <div
        className=" owl-prev la la-angle-left"
        onClick={onClick}
        style={{ zIndex: 1 }}
      />
    </div>
  );
}

class HomeOwlSlider extends Component {
  render() {
    return (
      <div>
        <div className="dlab-bnr-inr1 home-baner" style={{ backgroundImage: "url(" + Banner + ")", }}></div>
        {/* <img
          src={Banner}
          className="w-100 home-baner"
          alt=""
          style={{
            color: "blue",
            // height: "772px",
            // width: "772px",
            // maxHeight: "1500px",
            // height: "750px",
            backgroundColor: "#f29f05",
            color: "white",
          }}
        /> */}
        <div
          style={{
            position: "absolute",
            top: "20%",
            left: "15%",
            fontFamily: "Gilroy-Medium",
            color: "white",
          }}
        >
          <p
            style={{
              marginBottom: "0px",
              fontSize: "20px",
              lineHeight: "30px",
              fontFamily: "Gilroy-SemiBold"
            }}
            className="title1"
          >
            Trusted Partner for Web3 Infrastructure.</p>
          {/* <br />
            <b>& SERVICES</b> */}

          <p
            style={{
              fontSize: "60px",
              lineHeight: "82px",
              fontFamily: "Gilroy-Bold",
              textTransform: "uppercase",
              marginTop: "20px",
            }}
            className="title2"
          >
            EARN REWARDS
            <p className="digital-asset" style={{ marginTop: "5px" }}> ON DIGITAL ASSETS </p>
          </p>
          <p
            style={{
              fontSize: "20px",
              lineHeight: "34px",
              marginTop: "50px",
              fontFamily: "Gilroy-Medium",
            }}
            className="title3"
          >
            Our non-custodial solution helps you to earn rewards from <br />
            staking/delegating without taking custody of your crypto assets.
          </p>

          <div className="Total-Asset"
            style={{
              float: "left",
              backgroundColor: "#0CF0B4",
              marginRight: "10px",
              padding: "3px 20px 15px",
              borderRadius: "8px",
              marginTop: "45px",
            }}
          >
            <p className="Total-Asset1"
              style={{
                marginBottom: "0px",
                marginTop: "5px",
                fontSize: "18px",
                color: "black",
                fontFamily: "Gilroy-Medium",
              }}
            >
              Total Assets Staked
            </p>
            <p className="Total-Asset2"
              style={{
                marginBottom: "0px",
                marginTop: "5px",
                fontSize: "34px",
                color: "black",
                fontFamily: "Gilroy-Medium",
              }}
            >
              $ <span>47,703.25</span>
            </p>
          </div>
          <div className="Total-Asset"
            style={{
              float: "left",
              marginLeft: "15px",
              backgroundColor: "#0CF0B4",
              // marginRight: "10px",
              padding: "3px 20px 15px",
              borderRadius: "8px",
              marginTop: "45px",
            }}
          >
            <p className="Total-Asset1"
              style={{
                marginBottom: "0px",
                marginTop: "5px",
                fontFamily: "Gilroy-Medium",
                color: "black",
                fontSize: "18px",
              }}
            >
              Total Rewards Paid
            </p>
            <p className="Total-Asset2"
              style={{
                marginBottom: "0px",
                marginTop: "5px",
                fontSize: "34px",
                fontFamily: "Gilroy-Medium",
                color: "black",
              }}
            >
              $ <span>294.91</span>
            </p>
          </div>
        </div>
      </div>

      // 			<Slider className="owl-slider owl-carousel owl-theme owl-none" {...settings}>
      // 				<div className="item slide-item">
      // 					<div className="slide-item-img"><img src={Slider1} className="w-100" alt="" style={{color: "blue"}}/></div>
      // 					<div className="slide-content overlay-primary">
      // 						<div className="slide-content-box container" style={{float:"left",color:"white"}}>
      // 							<div className="max-w600 text-white">
      // 								<h2 className="text-white font-weight-400">Earn Rewards <br/> on Digital Assets <br/></h2>
      // 								<p>Secure blockchain ecosystems by staking tokens and participate in the next evolution of the internet!</p>
      // 								{/* <Link to ={"#"} className="site-button m-r10 white button-lg">Get Started</Link>
      // 								<Link to ={"#"} className="site-button outline outline-2 button-lg">How It Work</Link> */}
      // 							</div>
      // 						</div>
      // 					</div>
      // 				</div>
      // {/* 				<div className="item slide-item">
      // 					<div className="slide-item-img"><img src={Slider3} className="w-100" alt=""/></div>
      // 					<div className="slide-content overlay-primary">
      // 						<div className="slide-content-box container">
      // 							<div className="max-w600 text-white">
      // 								<h2 className="text-white font-weight-400">We would great ideas into<br/> prodigious and innovative products. <br/></h2>
      // 								<p>We are passionate of deep understanding of management and communication and how to interact with the digital world is the true value we provide. We help you in all the stages of realization of your digital products.</p>
      // 								<Link to ={"#"} className="site-button m-r10 white button-lg">Get Started</Link>
      // 								<Link to ={"#"} className="site-button outline outline-2 button-lg">How It Work</Link>
      // 							</div>
      // 						</div>
      // 					</div>
      // 				</div>
      // 				<div className="item slide-item">
      // 					<div className="slide-item-img"><img src={Slider2} className="w-100" alt=""/></div>
      // 					<div className="slide-content overlay-primary">
      // 						<div className="slide-content-box container">
      // 							<div className="max-w600 text-white">
      // 								<h2 className="text-white font-weight-400">Move with passion, perform best work<br/> and be pride, whatever you do. </h2>
      // 								<p>A forward thinking representation for a research production and the ability to do it dynamically with broad stability and drive your business up to a higher level.</p>
      // 								<Link to ={"#"} className="site-button m-r10 white button-lg">Get Started</Link>
      // 								<Link to ={"#"} className="site-button outline outline-2 button-lg">How It Work</Link>
      // 							</div>
      // 						</div>
      // 					</div>
      // 				</div> */}
      // 			</Slider>
    );
  }
}

export default HomeOwlSlider;
