import React, { Component } from "react";
// import { Link } from 'react-router-dom';
import Header from "./../Layout/Header1";
import Footer from "./../Layout/Footer1";
import Slider1 from "../../images/World_Map.svg";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { Button } from "react-bootstrap";
import FormControl from "@mui/material/FormControl";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import bgimg from "./../../images/background/bg2.jpg";
import NodeInfoService from "../../Service/NodeInfoService";
import {
  Email,
  Rotate90DegreesCcw,
  SportsRugbySharp,
} from "@material-ui/icons";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      state: "",
      setState: "",
      fields: {},
      errors: {},
      Firstname: "",
      Lastname: "",
      Companyname: "",
      Email: "",
      // PreferredMethodpfCommunication:" ",
      PhoneNumber: "",
      WhatsApp: "",
      Telegram: "",
      WeChat: "",
      // Phone Number / Username
      Username: "",
      // Whichbestdescribesyou:" ",
      IndividualInvestor: "",
      CryptoorVentureFund: "",
      Exchange: "",
      SomethingElse: "",
      // Whichassetsdoyouhold:" ",
      Bitcoin: "",
      Ethereum: "",
      Polkadot: "",
      Tezos: "",
      Celo: "",
      Tron: "",
      Cosmos: "",
      Cardano: "",
      Keep: "",
      Kyber: "",
      DAI: "",
      USDC: "",
      BUSD: "",
      ICON: "",
      Algorand: "",
      Decred: "",
      Terra: "",
      Kusama: "",
      Horizen: "",
      Iris: "",
      VSystems: "",
      Edgeware: "",
      Kava: "",
      Livepeer: "",
      Orbs: "",
      // Total$ValueofAssets:"",
      $10Million: "",
      $1Million$10Million: "",
      $250k$1Million: "",
      $10k250k: "",
      $10k: "",
      nodeInfoService: new NodeInfoService(),
      data: [],
    };
    this.submitData = this.submitData.bind(this);
  }

  handleReset = (e) => {
    this.setState({
      Firstname: "",
      Lastname: "",
      Companyname: "",
      Email: "",
      PhoneNumber: "",
      Username: "",
      IndividualInvestor: "",
      Bitcoin: "",
      $10Million: "",
    });
  };

  SubmitBody = (e) => {
    const body =
      "<html><body><p> FirstName: " +
      this.state.Firstname +
      " </p><p> LastName: " +
      this.state.Lastname +
      " </p><p> CompanyName: " +
      this.state.Companyname +
      " </p><p> Email:  " +
      this.state.Email +
      " </p><p> Preferred Method of Communication:  " +
      this.state.PhoneNumber +
      " </p><p> PhoneNumber / Username:  " +
      this.state.Username +
      " </p><p> Which best describes you?:  " +
      this.state.IndividualInvestor +
      " </p><p> Which assets do you hold?: " +
      this.state.Bitcoin +
      " </p><p> Total$ Value of Assets: " +
      this.state.$10Million +
      " + </p></body></html>";
    this.state.nodeInfoService.SendgridEmail(body).then((res) => {
      console.log("Json value of SendgridEmail");
      console.log(res);
      this.setState({ data: res });
    });
  };

  // Email Integration part
  handleChanges = (fieldName, e) => {
    console.log("abc", e, fieldName);
    this.setState({
      ...this.state,
      [fieldName]: e.target.value,
      // console.log([e.target.name]);
    });
  };

  submitData = (e) => {
    // alert("Form submitted");
    // debugger;
    console.log("SubmitData", e);
    e.preventDefault();
    if (this.validation()) {
      let fields = {};
      fields["Firstname"] = "";
      fields["Lastname"] = "";
      fields["Companyname"] = "";
      fields["Email"] = "";
      fields["PhoneNumber"] = "";
      fields["Username"] = "";
      fields["IndividualInvestor"] = "";
      fields["Bitcoin"] = "";
      fields[" $10Million"] = "";
      this.setState({ fields: fields });
      // alert("Form submitted");
      this.SubmitBody();
      this.handleReset();
    }
    console.log("firstname:", this.state.Firstname);
    console.log("lastname:", this.state.Lastname);
    console.log("Companyname:", this.state.Companyname);
    console.log("Email:", this.state.Email);
    console.log("Preferred Method of Communication:", this.state.PhoneNumber);
    console.log("Phone Number / Username:", this.state.Username);
    console.log("Which best describes you?:", this.state.IndividualInvestor);
    console.log("Which assets do you hold?:", this.state.Bitcoin);
    console.log("Total $ Value of Assets:", this.state.$10Million);
  };

  alerts = () => {
    alert("ffffffffff");
  };

  // Validation
  validation = () => {
    // debugger;
    let fields = this.state.fields;
    console.log("filelds", fields);
    let errors = {};
    let formIsValid = true;

    if (!this.state.Firstname) {
      formIsValid = false;
      errors["Firstname"] = "*Please enter your firstname.";
    }

    // if (typeof this.state.Firstname !== "undefined") {
    //   if (!fields["Firstname"].match(/^[a-zA-Z]*$/)) {
    //     formIsValid = false;
    //     errors["Firstname"] = "*Please enter alphabet characters only.";
    //   }
    // }

    if (!this.state.Lastname) {
      formIsValid = false;
      errors["Lastname"] = "*Please enter your lastname.";
    }

    if (!this.state.Companyname) {
      formIsValid = false;
      errors["Companyname"] = "*Please enter your companyname.";
    }

    if (!this.state.Email) {
      formIsValid = false;
      errors["Email"] = "*Please enter your email-ID.";
    }

    // if (typeof this.state.Email !== "undefined") {
    //   //regular expression for email validation
    //   var pattern = new RegExp(
    //     /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    //   );
    //   if (!pattern.test(fields["Email"])) {
    //     formIsValid = false;
    //     errors["Email"] = "*Please enter valid email-ID.";
    //   }
    // }

    if (!this.state.PhoneNumber) {
      formIsValid = false;
      errors["PhoneNumber"] = "*Please select any one.";
    }

    if (!this.state.Username) {
      formIsValid = false;
      errors["Username"] = "*Please enter Phonenumber or Username.";
    }

    if (!this.state.IndividualInvestor) {
      formIsValid = false;
      errors["IndividualInvestor"] = "*Please select any one.";
    }

    if (!this.state.Bitcoin) {
      formIsValid = false;
      errors["Bitcoin"] = "*Please select any one.";
    }

    if (!this.state.$10Million) {
      formIsValid = false;
      errors["$10Million"] = "*Please select any one.";
    }

    this.setState({
      errors: errors,
    });
    return formIsValid;
  };

  // dropdown part
  handleChange = (event, newValue) => {
    this.setState({ value: newValue });
  };

  render() {
    return (
      <>
        <Header />
        {/* <img src={Slider1} className="w-100" alt="" style={{ color: "blue", height: "550px", backgroundColor: "#f29f05", color: "white" }} /> */}
        <div className="full-section">
          {/* <!-- inner page banner --> */}
          <div
            className="dlab-bnr-inr contact-page"
            style={{
              backgroundImage: "url(" + Slider1 + ")",
              color: "blue",
              height: "550px",
              backgroundColor: "#01093A",
              color: "white",
            }}
          >
            <div className="container">
              <div className="row">
                <div className="col-lg-4 col-xs-4">
                  <div className="row text-white">
                    <div className="col-lg-12 col-md-6 m-b30">
                      <div className="icon-bx-wraper bx-style-1 p-a20 radius-sm">
                        <div className="icon-content">
                          <h5 className="dlab-tilte">
                            <span className="icon-sm text-primary text-left">
                              <i
                                className="ti-location-pin"
                                style={{ color: "#f29f05" }}
                              ></i>
                            </span>
                            Company Address
                          </h5>
                          <p>30 North Gould Street, Sheridan, WY, 82801 </p>
                          <h6 className="m-b15 font-weight-400">
                            <i className="ti-alarm-clock"></i> Office Hours
                          </h6>
                          <p className="m-b0">Mon To Sat - 08.00-18.00</p>
                          <p>Sunday - Close</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-6 m-b30 ">
                      <div className="icon-bx-wraper bx-style-1 p-a20 radius-sm">
                        <div className="icon-content">
                          <h5 className="dlab-tilte">
                            <span className="icon-sm text-primary text-left">
                              <i
                                className="ti-email"
                                style={{ color: "#f29f05" }}
                              ></i>
                            </span>
                            E-mail
                          </h5>
                          <p className="m-b0">info@coindelta.io</p>
                          <p className="m-b0">sales@coindelta.io</p>
                          <p className="m-b0">hr@coindelta.io</p>
                          <p>support@coindelta.io</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-6 m-b30 ">
                      <div className="icon-bx-wraper bx-style-1 p-a20 radius-sm">
                        <div className="icon-content">
                          <h5 className="dlab-tilte">
                            <span className="icon-sm text-primary text-left">
                              <i
                                className="ti-mobile"
                                style={{ color: "#f29f05" }}
                              ></i>
                            </span>
                            Phone Numbers
                          </h5>
                          <p>+00 0111-123456</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-8 col-xs-8">
                  <form
                    className="inquiry-form dzForm"
                    action="script/contact.php"
                    // style={{ height: "650px" }}
                  >
                    <div className="dzFormMsg"></div>
                    <h3 className="box-title m-t0 m-b30">
                      Quick Contact<span className="bg-primary"></span>
                    </h3>
                    {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the</p> */}
                    <div className="row">
                      <div className="col-lg-6">
                        <TextField
                          style={{
                            width: "100%",
                          }}
                          id="outlined-basic"
                          label="First name"
                          variant="outlined"
                          type="text"
                          value={this.state.Firstname}
                          onChange={(e) => this.handleChanges("Firstname", e)}
                        />
                        <div className="errorMsg" style={{ color: "red" }}>
                          {this.state.errors.Firstname}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <TextField
                          style={{ width: "100%" }}
                          id="outlined-basic"
                          label="Last name"
                          variant="outlined"
                          type="text"
                          value={this.state.Lastname}
                          onChange={(e) => this.handleChanges("Lastname", e)}
                        />
                        <div className="errorMsg" style={{ color: "red" }}>
                          {this.state.errors.Lastname}
                        </div>
                      </div>
                    </div>
                    <br />
                    <div className="row" style={{ marginTop: "-10px" }}>
                      <div className="col-lg-12">
                        <TextField
                          style={{
                            width: "100%",
                          }}
                          id="outlined-basic"
                          label="Company name"
                          variant="outlined"
                          type="text"
                          value={this.state.Companyname}
                          onChange={(e) => this.handleChanges("Companyname", e)}
                        />
                        <div className="errorMsg" style={{ color: "red" }}>
                          {this.state.errors.Companyname}
                        </div>
                      </div>
                    </div>
                    <br />
                    <div className="row" style={{ marginTop: "-10px" }}>
                      <div className="col-lg-12">
                        <TextField
                          style={{
                            width: "100%",
                          }}
                          id="outlined-basic"
                          label="Email"
                          variant="outlined"
                          type="text"
                          value={this.state.Email}
                          onChange={(e) => this.handleChanges("Email", e)}
                        />
                        <div className="errorMsg" style={{ color: "red" }}>
                          {this.state.errors.Email}
                        </div>
                      </div>
                    </div>
                    <br />
                    <div className="row" style={{ marginTop: "-10px" }}>
                      <div className="col-lg-12">
                        <div>
                          <FormControl
                            sx={{
                              width: "100%",
                            }}
                          >
                            <InputLabel id="demo-simple-select-label">
                              Preferred Method of Communication
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select-label"
                              // autoWidth
                              label="Preferred Method of Communication"
                              type="text"
                              MenuProps={MenuProps}
                              value={this.state.PhoneNumber}
                              onChange={(e) =>
                                this.handleChanges("PhoneNumber", e)
                              }
                            >
                              <MenuItem value={"Phonenumber"}>
                                Phone Number
                              </MenuItem>
                              <MenuItem value={"WhatsApp"}>WhatsApp</MenuItem>
                              <MenuItem value={"Telegram"}>Telegram</MenuItem>
                              <MenuItem value={"WeChat"}>WeChat</MenuItem>
                            </Select>
                          </FormControl>
                          <div className="errorMsg" style={{ color: "red" }}>
                            {this.state.errors.PhoneNumber}
                          </div>
                        </div>
                      </div>
                    </div>
                    <br />
                    <div className="row" style={{ marginTop: "-10px" }}>
                      <div className="col-lg-12">
                        <TextField
                          style={{
                            width: "100%",
                          }}
                          id="outlined-basic"
                          label="Phone Number / Username"
                          variant="outlined"
                          type="text"
                          value={this.state.Username}
                          onChange={(e) => this.handleChanges("Username", e)}
                        />
                        <div className="errorMsg" style={{ color: "red" }}>
                          {this.state.errors.Username}
                        </div>
                      </div>
                    </div>
                    <br />
                    <div className="row" style={{ marginTop: "-10px" }}>
                      <div className="col-lg-12">
                        <div>
                          <FormControl
                            sx={{
                              width: "100%",
                            }}
                          >
                            <InputLabel id="demo-simple-select-label">
                              Which best describes you?
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select-label"
                              // autoWidth
                              label="Which best describes you?"
                              type="text"
                              MenuProps={MenuProps}
                              value={this.state.IndividualInvestor}
                              onChange={(e) =>
                                this.handleChanges("IndividualInvestor", e)
                              }
                            >
                              <MenuItem value={"IndividualInvestor"}>
                                Individual Investor
                              </MenuItem>
                              <MenuItem value={"CryptoorVentureFund"}>
                                Crypto or Venture Fund
                              </MenuItem>
                              <MenuItem value={"Exchange"}>Exchange</MenuItem>
                              <MenuItem value={"Something Else"}>
                                Something Else
                              </MenuItem>
                            </Select>
                          </FormControl>
                          <div className="errorMsg" style={{ color: "red" }}>
                            {this.state.errors.IndividualInvestor}
                          </div>
                        </div>
                      </div>
                    </div>
                    <br />

                    <div className="row" style={{ marginTop: "-10px" }}>
                      <div className="col-lg-12">
                        <div>
                          <FormControl
                            sx={{
                              width: "100%",
                            }}
                          >
                            <InputLabel id="demo-simple-select-label">
                              Which assets do you hold?
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select-label"
                              // autoWidth
                              label="Which assets do you hold?"
                              type="text"
                              MenuProps={MenuProps}
                              value={this.state.Bitcoin}
                              onChange={(e) => this.handleChanges("Bitcoin", e)}
                            >
                              <MenuItem value={"Bitcoin"}>Bitcoin</MenuItem>
                              <MenuItem value={"Ethereum"}>Ethereum</MenuItem>
                              <MenuItem value={"Polkadot"}>Polkadot</MenuItem>
                              <MenuItem value={"Tezos"}>Tezos</MenuItem>
                              <MenuItem value={"Celo"}>Celo</MenuItem>
                              <MenuItem value={"Tron"}>Tron</MenuItem>
                              <MenuItem value={"Cosmos"}>Cosmos</MenuItem>
                              <MenuItem value={"Cardano"}>Cardano</MenuItem>
                              <MenuItem value={"Keep"}>Keep</MenuItem>
                              <MenuItem value={"Kyber"}>Kyber</MenuItem>
                              <MenuItem value={"DAI"}>DAI</MenuItem>
                              <MenuItem value={"USDC"}>USDC</MenuItem>
                              <MenuItem value={"BUSD"}>BUSD</MenuItem>
                              <MenuItem value={"ICON"}>ICON</MenuItem>
                              <MenuItem value={"Algorand"}>Algorand</MenuItem>
                              <MenuItem value={"Decred"}>Decred</MenuItem>
                              <MenuItem value={"Terra"}>Terra</MenuItem>
                              <MenuItem value={"Kusama"}>Kusama</MenuItem>
                              <MenuItem value={"Horizen"}>Horizen</MenuItem>
                              <MenuItem value={"Iris"}>Iris</MenuItem>
                              <MenuItem value={"VSystems"}>V.Systems</MenuItem>
                              <MenuItem value={"Edgeware"}>Edgeware</MenuItem>
                              <MenuItem value={"Kava"}>Kava</MenuItem>
                              <MenuItem value={"Livepeer"}>Livepeer</MenuItem>
                              <MenuItem value={"Orbs"}>Orbs</MenuItem>
                            </Select>
                          </FormControl>
                          <div className="errorMsg" style={{ color: "red" }}>
                            {this.state.errors.Bitcoin}
                          </div>
                        </div>
                      </div>
                    </div>
                    <br />
                    <div className="row" style={{ marginTop: "-10px" }}>
                      <div className="col-lg-12">
                        <div>
                          <FormControl
                            sx={{
                              width: "100%",
                            }}
                          >
                            <InputLabel id="demo-simple-select-label">
                              Total $ Value of Assets
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select-label"
                              // autoWidth
                              label="Total $ Value of Assets"
                              type="text"
                              MenuProps={MenuProps}
                              value={this.state.$10Million}
                              onChange={(e) =>
                                this.handleChanges("$10Million", e)
                              }
                            >
                              <MenuItem value={"$10Million+"}>
                                $10 Million +
                              </MenuItem>
                              <MenuItem value={"$1Million-$10Million"}>
                                $1 Million - $10 Million
                              </MenuItem>
                              <MenuItem value={"$250k-$1Million"}>
                                $250k - $1 Million
                              </MenuItem>
                              <MenuItem value={"$10k-250k"}>
                                $10k - 250k
                              </MenuItem>
                              <MenuItem value={"<$10k"}>$10k </MenuItem>
                            </Select>
                          </FormControl>
                          <div className="errorMsg" style={{ color: "red" }}>
                            {this.state.errors.$10Million}
                          </div>
                        </div>
                      </div>
                    </div>
                    <br />
                    <div className="row" style={{ marginTop: "-10px" }}>
                      <div className="col-lg-12">
                        <form ref="form" onClick={this.submitData}>
                          <Button
                            className="site-button"
                            type="submit"
                            // disable
                            style={{
                              backgroundColor: "#218FCE",
                              color: "#fff",
                              width: "100%",
                              height: "60px",
                              textAlign: "center",
                            }}
                          >
                            SUBMIT
                          </Button>
                        </form>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- inner page banner END --> */}
        </div>

        <Footer />
      </>
    );
  }
}
export default Contact;
