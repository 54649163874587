import React, { Fragment, useState, Component, useEffect } from "react";
import { Link } from "react-router-dom";
// import Header from "./../../Layout/Header1";
import Header1 from "../../Layout/Header1"
import Footer from "./../../Layout/Footer1";

// AboutNode Array Images
import NominalReward from "../../../images/blockchain_logos/NominalReward.svg";
import StakingComission from "../../../images/blockchain_logos/StakingComission.svg";
import ProtocolCategory from "../../../images/blockchain_logos/ProtocolCategory.svg";
import StakingType from "../../../images/blockchain_logos/StakingType.svg";
import Governance from "../../../images/blockchain_logos/Governance.svg";

import NodePageBanner from "../../../images/blockchain_logos/NodePageBanner.png";
import Avalanche_logo from "../../../images/blockchain_logos/avaxLogo.svg";
import StakingLogo from "../../../images/blockchain_logos/StakingLogo.svg";
import AvaxStaking from "../../../images/blockchain_logos/AvaxStaking.jpg";
import NodeDetails from "../../../images/blockchain_logos/Key.svg";
import StakeAvax from "../../../images/blockchain_logos/StakeAvax1.svg";
import AddressCopied from "../../../images/blockchain_logos/AddressCopied.png";

import Typography from "@mui/material/Typography";
import copy from "copy-to-clipboard";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@material-ui/core/Slide";
import NodeInfoService from "../../../Service/NodeInfoService";
import StakingDetails from "../../Component/StakingDetails";
const label = { inputProps: { "aria-label": "Switch demo" } };

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  // console.log(onClose);

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {/* {onClose ? ( */}
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      {/* ) : null} */}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const CustomizedDialogs = (props) => {
  const { isDialogOpen } = props;
  console.log("CustomizedDialogs value is..." + isDialogOpen);
  const [open, setOpen] = React.useState(isDialogOpen);

  React.useEffect(() => {
    const { isDialogOpen } = props;
    setOpen(isDialogOpen);
    console.log("CustomizedDialogs value is..." + open);
  }, [props]);

  const handleClose = () => {
    setOpen(false);
    props.onCloseModel(false);
  };

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
          style={{ textAlign: "center" }}
        >
          <b>Address copied to clipboard</b>
        </BootstrapDialogTitle>
        <DialogContent dividers style={{ textAlign: "center" }}>
          <Typography gutterBottom>Welcome, to the future of money</Typography>
          <Typography gutterBottom>
            <img src={AddressCopied} width={"40%"} />
          </Typography>
          <Typography gutterBottom>
            <p>You can now paste the address in your staking wallet</p>
            <p>Need help? Check out our tutorials</p>
          </Typography>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
};

class Avalanche extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "1",
      amount: 0,
      currencyType: "AVAX",
      dailyEarningsDollar: 0.0,
      dailyEarningsDot: 0.0,
      monthlyEarningsDollar: 0.0,
      monthlyEarningsDot: 0.0,
      yearlyEarningsDollar: 0.0,
      yearlyEarningsDot: 0.0,
      compound: false,
      totalAmount: 0.0,
      configCompoundTrue: {
        dailyDoller: 0.25911,
        dailyDot: 0.00012,
        monthlyDoller: 7.88115,
        monthlyDot: 0.00367,
        yearlyDoller: 94.57384,
        yearlyDot: 0.04403,
      },
      configCompoundfalse: {
        dailyDoller: 0.23867,
        dailyDot: 0.00012,
        monthlyDoller: 7.69442,
        monthlyDot: 0.00358,
        yearlyDoller: 92.33309,
        yearlyDot: 0.04299,
      },
      dotPrice: 2147.78,
      configUsdCompTrue: {
        dailyDoller: 0.00012,
        dailyDot: 0.00000006,
        monthlyDoller: 0.0036694,
        monthlyDot: 0.0000018,
        yearlyDoller: 0.0440333,
        yearlyDot: 0.0000217,
      },
      configUsdCompFalse: {
        dailyDoller: 0.0001177,
        dailyDot: 0.000000058,
        monthlyDoller: 0.0035825,
        monthlyDot: 0.000001768,
        yearlyDoller: 0.04299,
        yearlyDot: 0.000021215,
      },
      nodeInfoService: new NodeInfoService(),
      modalOpen: false,
      validators: [],
      AboutNode: [
        { NodeImg: NominalReward, titles: "9.54", descriptions: "%", content: "Nominal Reward APY" },
        { NodeImg: StakingComission, titles: "2", descriptions: "%", content: "Staking Comission" },
        { NodeImg: ProtocolCategory, titles: "Layer 1", content: "Protocol Category" },
        { NodeImg: StakingType, titles: "NPoS", content: "Staking Type" },
        { NodeImg: Governance, titles: "off-Chain", content: "Governance" },
      ],
      Tutorial: [
        { Tuto_title: "Coindelta Staking Tutorial" },
        {
          Tuto_image: Avalanche_logo,
          Tuto_title: "How to Stake AVAX?",
          Tuto_description: "via Coindelta",
          Tuto_StakeAvax:
            "https://docs.avascan.info/guide-delegation-avalanche-network/guide-delegation-avax-web-wallet",
        },
        {
          Tuto_image: NodeDetails,
          Tuto_title: "NodeID-9mFVn1jN8bWqyuzshCJVeoutydK5XHpiN",
          Tuto_description: "Staking Validator",
          Tuto_CopyId: "NodeID-9mFVn1jN8bWqyuzshCJVeoutydK5XHpiN",
          Tuto_Details: "/node-info",
          link: "https://avascan.info/staking/validator/NodeID-9mFVn1jN8bWqyuzshCJVeoutydK5XHpiN",
        },
        {
          Tuto_image: StakeAvax,
          Tuto_title: "Stake AVAX via Coindelta",
          Tuto_description: "services via Coindelta",
          Tuto_StakeNow: "https://wallet.avax.network/",
        },
      ],
      NodeInfo: [
        {
          Node_Title: "Validations",NodeID:"TX ID",StartDate:"START DATE",EndDate:"END DATE",Duration:"DURATION",ValidatorStake:"VALIDATOR STAKE"
        }
      ],
    };
  }

  handleAmountChange(amountValue, currencyType, compound) {
    // const amountValue = event.target.value;
    console.log("event from handleAmountChange..." + amountValue);

    if (this.state.currencyType == "AVAX") {
      this.setState({
        currencyType: currencyType,
        compound: compound,
        amount: amountValue,
        totalAmount: amountValue * this.state.dotPrice,
      });
      if (this.state.compound) {
        const configCompound = this.state.configCompoundTrue;

        this.setState({
          dailyEarningsDollar: amountValue * configCompound.dailyDoller,
          dailyEarningsDot: amountValue * configCompound.dailyDot,
          monthlyEarningsDollar: amountValue * configCompound.monthlyDoller,
          monthlyEarningsDot: amountValue * configCompound.monthlyDot,
          yearlyEarningsDollar: amountValue * configCompound.yearlyDoller,
          yearlyEarningsDot: amountValue * configCompound.yearlyDot,
        });
      } else {
        const configCompound = this.state.configCompoundfalse;

        this.setState({
          dailyEarningsDollar: amountValue * configCompound.dailyDoller,
          dailyEarningsDot: amountValue * configCompound.dailyDot,
          monthlyEarningsDollar: amountValue * configCompound.monthlyDoller,
          monthlyEarningsDot: amountValue * configCompound.monthlyDot,
          yearlyEarningsDollar: amountValue * configCompound.yearlyDoller,
          yearlyEarningsDot: amountValue * configCompound.yearlyDot,
        });
      }
    } else if (this.state.currencyType == "USD") {
      this.setState({
        currencyType: currencyType,
        compound: compound,
        amount: amountValue,
        totalAmount: amountValue / this.state.dotPrice,
      });
      if (this.state.compound) {
        const configCompound = this.state.configUsdCompTrue;

        this.setState({
          dailyEarningsDollar: amountValue * configCompound.dailyDoller,
          dailyEarningsDot: amountValue * configCompound.dailyDot,
          monthlyEarningsDollar: amountValue * configCompound.monthlyDoller,
          monthlyEarningsDot: amountValue * configCompound.monthlyDot,
          yearlyEarningsDollar: amountValue * configCompound.yearlyDoller,
          yearlyEarningsDot: amountValue * configCompound.yearlyDot,
        });
      } else {
        const configCompound = this.state.configUsdCompFalse;

        this.setState({
          dailyEarningsDollar: amountValue * configCompound.dailyDoller,
          dailyEarningsDot: amountValue * configCompound.dailyDot,
          monthlyEarningsDollar: amountValue * configCompound.monthlyDoller,
          monthlyEarningsDot: amountValue * configCompound.monthlyDot,
          yearlyEarningsDollar: amountValue * configCompound.yearlyDoller,
          yearlyEarningsDot: amountValue * configCompound.yearlyDot,
        });
      }
    }
  }

  handleCopy(value) {
    copy(value);
  }

  handleClose() {
    this.setState({
      modalOpen: false,
    });
  }

  componentDidMount() {
    this.state.nodeInfoService.getNodeInfo().then((res) => {
      this.setState({ data: res });
      if (res) {
        console.log("json", res.res_object);
        const row = [];
        this.setState({ validators: res.res_object });
        res.res_object.delegators.map((del) => {
          console.log("ddddd", del);
          return null;
        });
        console.log("row", row);
        this.setState({ row });
        this.setState({ load: false });
      } else {
        this.setState({ load: false });
      }
    }, []);
  }

  render() {
    const { data, validators } = this.state;
    if (validators) {
      console.log("validators", validators[0]?.nodeID);
    }
    console.log(this.props);
    return (
      <>
        <Header1 />
        <StakingDetails
          title="A V A L A N C H E"
          image={Avalanche_logo}
          currency="AVAX"
          description="Our Total Stake: "
          description_val="2025 AVAX"
          description_Deli="Delegators: "
          description_Deli_val="NULL"
          AboutNode={this.state.AboutNode}
          Tutorial={this.state.Tutorial}
          NodeInfo={this.state.NodeInfo}
          AvalanchDetails="Avalanche Protocol Details"
          AvalancheProtoDescription="Avalanche is a blockchain that promises to combine scaling capabilities and quick confirmation times through its Avalanche Consensus Protocol. It can process 4,500 TPS (transactions per second). For Ethereum, that number is 14 TPS. Avalanche's native token, AVAX, is the 10th-largest with a market cap of $33 billion as of this writing in March 2022. Avalanche Consensus Protocol is Scalable, Robust, Highly Decentralized, Low Latency, High Throughput, Lightweight, Green, Sustainable, Resilient to 51% Attacks."
        />
        <Footer />
      </>
    );
  }
}
export default Avalanche;
