import React, { Fragment, Component } from "react";
import Header1 from "../../../Layout/Header1";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { TabContent, TabPane } from "reactstrap";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";

class Polkadot_Extension extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "1",
      open: false,
    };
  }

  render() {
    return (
      <>
        <Header1 />
        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId="1">
            <div className="content-block">
              <div className="page-content bg-white">
                <div className="section-full content-inner">
                  <div className="container">
                    <div className="row">
                      <div
                        className="dlab-box-bg m-b30"
                        style={{
                          width: "92%",
                          marginTop: "150px",
                          marginLeft: "40px",
                          textAlign: "center",
                          boxShadow: "1px 1px 1px 1px #968c8c",
                          borderRadius: "3px",
                        }}
                      >
                        <h2
                          style={{
                            marginTop: "40px",
                            color: "#333B6D",
                            marginLeft: "-140px",
                          }}
                        >
                          Connect your wallet
                        </h2>
                        <p
                          className="mb-5"
                          style={{ marginTop: "-15px", marginLeft: "-40px" }}
                        >
                          Open the Polkadot.js browser extension and allow
                          access.
                        </p>
                        <br />
                        <p
                          style={{
                            marginTop: "-60px",
                            marginRight: "390px",
                            color: "green",
                          }}
                        >
                          Connected
                        </p>
                        <Link
                          style={{
                            width: "15%",
                            textAlign: "center",
                            backgroundColor: "#333B6D",
                            marginLeft: "330px",
                          }}
                          to={"./StakingPair"}
                          className="site-button"
                        >
                          NEXT
                        </Link>
                        {/* <Button
                          className="site-button"
                          // disable
                          style={{
                            // backgroundColor: "transparent",
                            color: "#333B6D",
                            width: "15%",
                            textAlign: "center",
                            marginLeft: "330px",
                          }}
                        >
                          NEXT
                        </Button> */}
                        <br />
                        <br />
                        <br />
                        <br />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </TabPane>
        </TabContent>
      </>
    );
  }
}
export default Polkadot_Extension;
